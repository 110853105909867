<mat-accordion>
  <mat-expansion-panel
    class="row mat-elevation-z2 quick-actions-panel"
    [expanded]="true"
  >
    <mat-expansion-panel-header class="quick-actions-panel--header">
      <mat-panel-title class="quick-actions-panel__title">
        <strong>{{ 'pages.home.quick-actions' | translate }}</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-quick-actions></app-quick-actions>
  </mat-expansion-panel>
</mat-accordion>
<div class="page-content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <!-- <app-widgets></app-widgets> -->
        <app-diary></app-diary>
      </div>
      <div class="col-12 col-md-4">
        <app-notices
          *ngIf="isNoticesModuleEnabled && isNoticesModuleReadPermission"
          (noticeCount)="getNoticeCount($event)"
        ></app-notices>
        <app-post-it-notes></app-post-it-notes>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mt-3">
      <div class="col-12">
        <mat-card>
          <div class="row px-3">
            <div class="col-md-9 nopadding">
              <mat-card-title>
                <h6>{{ 'pages.home.modules' | translate }}</h6>
              </mat-card-title>
            </div>
            <div class="col-md-3 no-padding" style="font-size: 12px">
              <mat-form-field class="filter-form-field">
                <span matPrefix>
                  <mat-icon class="prefix-icon">filter_list</mat-icon>
                </span>
                <input
                  matInput
                  type="text"
                  [placeholder]="'pages.home.filter' | translate"
                  [(ngModel)]="moduleFilterValue"
                  (ngModelChange)="checkFilterEmpty()"
                />
                <button
                  mat-button
                  *ngIf="moduleFilterValue"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="moduleFilterValue = ''"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>

          <mat-divider></mat-divider>

          <!-- modules cards -->
          <mat-card-content class="grid-container">
            <div class="grid mt-2">
              <ng-container
                *ngIf="
                  !isEmptyModules && filteredModuleCards.length > 0;
                  else noModules
                "
              >
                <ng-container *ngFor="let item of filteredModuleCards">
                  <div class="grid-item" *ngIf="filter(item.searchKey)">
                    <a
                      class="grid-item--link"
                      [routerLink]="item.routerLink"
                      [queryParams]="{ is_search: true }"
                      [matTooltip]="item.tooltip"
                    >
                      <mat-card
                        class="d-flex flex-column justify-content-center align-items-center hover-primary"
                      >
                        <div class="icon_container primary-bg-400">
                          <img
                            fetchpriority="high"
                            mat-card-sm-image
                            class="grid-item--image"
                            [src]="item.image"
                            [alt]="item.alt"
                          />
                        </div>
                        <span class="grid-item--title">{{ item.title }} </span>
                      </mat-card>
                    </a>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #noModules>
                <app-no-results-found></app-no-results-found>
              </ng-template>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
