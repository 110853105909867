import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import {
  EXTERNAL_SHARE_SECURITY_TYPES,
  EXTERNAL_SHARE_VALIDATION_TYPE,
} from '@shared/constants';
import { IIdentityResponse } from '@shared/interfaces';

import {
  SnackbarService,
  SUCCESS_TYPES,
  // SUCCESS_TYPES,
} from '../../../../services/snackbar.service';
import { IPhoneNumberInputChange } from '../../../shared/components/phone-number-input/types';
import { ShareToService } from '../../../storage/services/share-to.service';
import { StorageEntityService } from '../../../storage/services/storage-entity.service';

export interface ChipList {
  name: string;
  identity: IIdentityResponseModified;
  avatar?: string;
}

interface IIdentityResponseModified extends IIdentityResponse {
  avatar?: string;
}

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss'],
})
export class ShareToPopupComponent implements OnInit, OnDestroy {
  title = '';
  subtitle = '';
  icon = '';
  email = '';
  phoneNumber = '';
  link = '';

  isPhoneNumberValid = false;
  isEmailSelected = false;
  isSmsSelected = false;

  private readonly onDestroy$ = new Subject();

  shareCustomerFormGroup: FormGroup = new FormGroup({
    email: new FormControl(null),
    phoneNumber: new FormControl(null),
    isEmailSelected: new FormControl(false),
    isSmsSelected: new FormControl(false),
  });

  constructor(
    private snackbar: SnackbarService,
    private storageEntityService: StorageEntityService,
    private shareToService: ShareToService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      subtitle: string;
      icon: string;
      email?: string;
      phoneNumber?: string;
      fileId?: string;
      storageId?: string;
      link?: string;
      fullName?: string;
    }
  ) {
    this.title = data?.title;
    this.subtitle = data?.subtitle;
    this.email = data?.email;
    this.phoneNumber = data?.phoneNumber;
    this.link = data?.link;
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    const mobileRegex = /\+\d{2}\s\d{2}\s\d{3}\s\d{4}\s\(Mobile\)/g;
    if (this.email && this.email !== 'undefined (undefined)') {
      this.shareCustomerFormGroup.controls['email'].setValue(this.email);
    } else {
      this.shareCustomerFormGroup.controls['email'].setValue(null);
    }
    this.shareCustomerFormGroup.controls['isEmailSelected'].setValue(true);
    this.isEmailSelected = true;

    if (
      this.phoneNumber &&
      this.phoneNumber !== 'undefined (undefined)' &&
      this.phoneNumber.match(mobileRegex)
    ) {
      this.shareCustomerFormGroup.controls['phoneNumber'].setValue(
        this.phoneNumber
      );
      this.isSmsSelected = true;
    } else {
      this.shareCustomerFormGroup.controls['phoneNumber'].setValue(null);
      this.isSmsSelected = false;
    }
    this.shareCustomerFormGroup.controls['isSmsSelected'].setValue(
      this.isSmsSelected
    );

    this.shareCustomerFormGroup.controls['email'].disable();
    this.shareCustomerFormGroup.controls['isEmailSelected'].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (
          this.shareCustomerFormGroup.controls['isEmailSelected'].value == false
        ) {
          this.isEmailSelected = false;
        } else {
          this.isEmailSelected = true;
        }
      });

    this.shareCustomerFormGroup.controls['isSmsSelected'].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (
          this.shareCustomerFormGroup.controls['isSmsSelected'].value == false
        ) {
          this.isSmsSelected = false;
        } else {
          this.isSmsSelected = true;
        }
      });

    this.onHandleValidation();
  }

  onDoneClicked() {
    return '';
  }

  shareHandler() {
    if (this.data.storageId && this.data.fileId) {
      this.onShareFileToCustomer();
    } else {
      this.onShareLinkToCustomer();
    }
  }

  onShareFileToCustomer() {
    if (
      this.shareCustomerFormGroup.controls['isEmailSelected'].value == true &&
      this.shareCustomerFormGroup.controls['email'].value
    ) {
      const shareFileEmail = {
        email: this.shareCustomerFormGroup.controls['email'].value,
        phone_number: null,
        type: EXTERNAL_SHARE_VALIDATION_TYPE.EMAIL,
        security_type: EXTERNAL_SHARE_SECURITY_TYPES.NONE,
      };

      this.storageEntityService
        .shareEntityExternal(
          this.data.storageId,
          this.data.fileId,
          shareFileEmail
        )
        .subscribe({
          next: () => {
            if (
              this.shareCustomerFormGroup.controls['isSmsSelected'].value ==
                true &&
              this.shareCustomerFormGroup.controls['phoneNumber'].value
            ) {
              const shareFileSMS = {
                phone_number:
                  this.shareCustomerFormGroup.controls['phoneNumber'].value,
                type: EXTERNAL_SHARE_VALIDATION_TYPE.PHONE,
                security_type: EXTERNAL_SHARE_SECURITY_TYPES.NONE,
              };

              this.storageEntityService
                .shareEntityExternal(
                  this.data.storageId,
                  this.data.fileId,
                  shareFileSMS
                )
                .subscribe({
                  next: () => {
                    this.snackbar.success(
                      SUCCESS_TYPES.SENT,
                      this.translate.instant('storage.drive.share.file')
                    );
                  },
                  error: () => {
                    this.snackbar.error(
                      this.translate.instant('storage.drive.share.share-failed')
                    );
                  },
                });
            } else {
              this.snackbar.success(
                SUCCESS_TYPES.SENT,
                this.translate.instant('storage.drive.share.file')
              );
            }
          },
          error: () => {
            this.snackbar.error(
              this.translate.instant('storage.drive.share.share-failed')
            );
          },
        });
    } else {
      const shareFileSMS = {
        email: null,
        phone_number: this.shareCustomerFormGroup.controls['phoneNumber'].value,
        type: EXTERNAL_SHARE_VALIDATION_TYPE.PHONE,
        security_type: EXTERNAL_SHARE_SECURITY_TYPES.NONE,
        password_string: null,
      };

      this.storageEntityService
        .shareEntityExternal(
          this.data.storageId,
          this.data.fileId,
          shareFileSMS
        )
        .subscribe({
          next: () => {
            this.snackbar.success(
              SUCCESS_TYPES.SENT,
              this.translate.instant('storage.drive.share.file')
            );
          },
          error: () => {
            this.snackbar.error(
              this.translate.instant('storage.drive.share.share-failed')
            );
          },
        });
    }
  }

  onShareLinkToCustomer() {
    const shareLinkEmail = {
      email:
        this.shareCustomerFormGroup.controls['isEmailSelected'].value == true &&
        this.shareCustomerFormGroup.controls['email'].value
          ? this.shareCustomerFormGroup.controls['email'].value
          : null,
      phone_number:
        this.shareCustomerFormGroup.controls['isSmsSelected'].value == true &&
        this.shareCustomerFormGroup.controls['phoneNumber'].value
          ? this.shareCustomerFormGroup.controls['phoneNumber'].value
          : null,
      fullName: this.data?.fullName,
      link: this.data?.link,
    };

    this.shareToService.shareToCustomer(shareLinkEmail).subscribe({
      next: () => {
        this.snackbar.success(
          SUCCESS_TYPES.SENT,
          this.translate.instant('common.share-to.link-shared-success')
        );
      },
      error: () => {
        this.snackbar.error(
          this.translate.instant('common.share-to.link-shared-fail')
        );
      },
    });
  }

  onHandleValidation() {
    if (
      (this.isEmailSelected &&
        this.shareCustomerFormGroup.controls['email'].value) ||
      this.isPhoneNumberValid
    ) {
      return false;
    }
    return true;
  }

  handleOnChangePhoneNumber(event: IPhoneNumberInputChange) {
    this.isPhoneNumberValid = event.valid;
    this.shareCustomerFormGroup.controls['phoneNumber'].setValue(event);
    this.shareCustomerFormGroup.controls['phoneNumber'].markAsDirty();
    this.shareCustomerFormGroup.controls['phoneNumber'].markAsTouched();
  }

  handleOnEmailChange(event) {
    this.shareCustomerFormGroup.controls['email'].setValue(event.target.value);
    this.shareCustomerFormGroup.controls['email'].markAsDirty();
    this.shareCustomerFormGroup.controls['email'].markAsTouched();
  }
}
