export enum ASSESSOR_STATUS {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum ASSESSOR_TYPES {
  ONSITE_ASSESSOR = 'Onsite Assessor',
  DESK_ASSESSOR = 'Desk Assessor',
  MOTOR_GARAGE_INSPECTOR = 'Motor Garage Inspector',
  MOTOR_ENGINEER_INSPECTOR = 'Motor Engineer Inspector',
}

export enum ASSESSOR_ADDITIONAL_DETAILS {
  CADRE = 'Cadre',
  NIC = 'NIC',
}
