export enum BANKS_FIELDS {
  BANK_CODE = 'CODE',
  BANK = 'BANK',
}

export enum BRANCHES_FIELDS {
  BANK_CODE = 'BANK CODE',
  BRANCH_CODE = 'CODE',
  BRANCH = 'BRANCH',
}
