import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep, isEqual } from 'lodash';

import { REFERENCE_MAPPINGS } from '@shared/constants';
import {
  IContactAddressRequest,
  IContactEmailRequest,
  IContactNumberPopupConfig,
  IContactNumberRequest,
  INameRequest,
  MultiFieldData,
} from '@shared/interfaces';
import { extractDefaultValue } from '@shared/utils';

import { noWhitespaceValidator } from '../../../core/helpers/form-field-white-space-validator';
import { AddressReferenceService } from '../../../references/services';
import { ReferenceMappingService } from '../../../setup/reference-mapping/services/reference-mapping.service';
import {
  SetAddressesPopupComponent,
  SetContactNumbersPopupComponent,
  SetNamePopupComponent,
} from '../../popups';
import { SetContactEmailsPopupComponent } from '../../popups/set-emails/set-emails.popup.component';
import { AddressService } from '../../services/address.service';

@Component({
  selector: 'el-multi-field',
  templateUrl: './el-multi-field.component.html',
  styleUrls: ['./el-multi-field.component.scss'],
})
export class ELMultiFieldComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() isConstant: boolean;
  @Input() data: MultiFieldData;
  @Input() popupConfig?: IContactNumberPopupConfig;
  @Input() errorMessage: string;
  @Input() inputFieldId?: string;
  @Input() btnId?: string;
  @Input() disabled = false;
  @Input() readonly = false;
  @Output() onSetValue = new EventEmitter<MultiFieldData>();

  displayValue = new FormControl();

  setBtnLoading = false;

  constructor(
    private dialog: MatDialog,
    private referenceMappingService: ReferenceMappingService,
    private addressReferenceService: AddressReferenceService,
    private addressService: AddressService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.disabled) {
      this.displayValue.disable();
    }
    await this.fetchAddressData();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    await this.fetchAddressData();

    const valueChanges = changes?.data;
    if (
      !!valueChanges &&
      (valueChanges.firstChange ||
        (!!valueChanges.currentValue &&
          !isEqual(valueChanges.currentValue, valueChanges.previousValue)))
    ) {
      this.displayValue.setValue(extractDefaultValue(this.data));
    }

    const requiredBehaviourChange = changes?.required;

    if (
      requiredBehaviourChange &&
      (requiredBehaviourChange.firstChange ||
        (!!requiredBehaviourChange.currentValue.toString() &&
          !isEqual(
            requiredBehaviourChange.currentValue,
            requiredBehaviourChange.previousValue
          )))
    ) {
      if (this.required) this.displayValue.addValidators(noWhitespaceValidator);
    }
  }

  async fetchAddressData(): Promise<void> {
    if (this.data?.type === 'ADDRESS') {
      this.displayValue.setValue('Loading address text...');
      if (this.data?.value && this.data.value?.length > 0) {
        this.data = cloneDeep(this.data);
        this.setBtnLoading = true;
        const promises = this.data?.value?.map(async (item, index) => {
          this.data.value[index] = await this.addressService.bindAddressData(
            item
          );
        });

        await Promise.all(promises);
        this.displayValue.setValue(extractDefaultValue(this.data));
        this.setBtnLoading = false;
      } else {
        this.displayValue.setValue(null);
        this.setBtnLoading = false;
      }
    }
  }

  async onSetClicked() {
    this.setBtnLoading = true;
    if (this.readonly) {
      return;
    }

    if (this.data.type === 'PHONE') {
      const dialogConfig = {
        data: {
          contactData: this.data.value,
          fieldConfig: this.data.fieldConfig,
          isRequired: this.required,
          popupConfig: this.popupConfig,
        },
        width: '80%',
        maxWidth: '770px',
        panelClass: 'my-custom-dialog-class',
        disableClose: true,
      };
      this.setBtnLoading = false;
      this.dialog
        .open(SetContactNumbersPopupComponent, dialogConfig)
        .afterClosed()
        .subscribe((formValue: IContactNumberRequest[]) => {
          if (formValue) {
            this.onSetValue.emit({
              type: 'PHONE',
              value: formValue,
              fieldConfig: this.data.fieldConfig,
            });
          }
        });
    } else if (this.data.type === 'ADDRESS') {
      const referenceMappings = await this.getReferenceMapping();
      const dialogConfig = {
        data: {
          contactData: this.data.value,
          fieldConfig: this.data.fieldConfig,
          isRequired: this.required,
          referenceMappingData: referenceMappings,
        },
        width: '80%',
        maxWidth: '770px',
      };
      this.setBtnLoading = false;
      this.dialog
        .open(SetAddressesPopupComponent, dialogConfig)
        .afterClosed()
        .subscribe((formValue: IContactAddressRequest[]) => {
          if (formValue) {
            this.onSetValue.emit({
              type: 'ADDRESS',
              value: formValue,
              fieldConfig: this.data.fieldConfig,
            });
          }
        });
    } else if (this.data.type === 'NAME') {
      const dialogConfig = {
        data: {
          value: this.data.value,
          fieldConfig: this.data.fieldConfig,
          isRequired: this.required,
        },
        width: '80%',
        maxWidth: '570px',
      };
      this.setBtnLoading = false;
      this.dialog
        .open(SetNamePopupComponent, dialogConfig)
        .afterClosed()
        .subscribe((formValue: INameRequest) => {
          if (formValue) {
            this.onSetValue.emit({
              type: 'NAME',
              value: formValue,
              fieldConfig: this.data.fieldConfig,
            });
          }
        });
    } else if (this.data.type === 'EMAIL') {
      const dialogConfig = {
        data: {
          contactData: this.data.value,
          fieldConfig: this.data.fieldConfig,
          isRequired: this.required,
        },
        width: '80%',
        maxWidth: '570px',
      };
      this.setBtnLoading = false;
      this.dialog
        .open(SetContactEmailsPopupComponent, dialogConfig)
        .afterClosed()
        .subscribe((formValue: IContactEmailRequest[]) => {
          if (formValue) {
            this.onSetValue.emit({
              type: 'EMAIL',
              value: formValue,
              fieldConfig: this.data.fieldConfig,
            });
          }
        });
    }
  }

  async getReferenceMapping() {
    const countryListMapping =
      await this.referenceMappingService.getMappingByKey(
        REFERENCE_MAPPINGS.ADDRESS_COUNTRY
      );
    const stateListMapping = await this.referenceMappingService.getMappingByKey(
      REFERENCE_MAPPINGS.ADDRESS_PROVINCE
    );
    const districtListMapping =
      await this.referenceMappingService.getMappingByKey(
        REFERENCE_MAPPINGS.ADDRESS_DISTRICT
      );
    const cityListMapping = await this.referenceMappingService.getMappingByKey(
      REFERENCE_MAPPINGS.ADDRESS_CITY
    );
    const postalCodeListMapping =
      await this.referenceMappingService.getMappingByKey(
        REFERENCE_MAPPINGS.ADDRESS_POSTAL_CODE
      );

    const countryListForDropDown =
      await this.addressReferenceService.getCountriesToAddressPopup();
    return {
      countryListMappingData: countryListMapping,
      stateListMappingData: stateListMapping,
      districtListMappingData: districtListMapping,
      cityListMappingData: cityListMapping,
      postalCodeListMappingData: postalCodeListMapping,
      countryListForDropDownData: countryListForDropDown,
    };
  }
}
