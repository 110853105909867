import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, toArray } from 'rxjs';

import { ENDPOINTS } from '@shared/constants';
import {
  ApproveCancellationData,
  CommonResponseDTO,
  IAddReceipt,
  IReceipt,
  IReceiptShare,
  IRequestCancellationData,
  RequestCancellationData,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';
@Injectable({
  providedIn: 'root',
})
export class ReceiptService {
  public $isDeposit = new BehaviorSubject<boolean>(false);
  public $isCollapsed = new BehaviorSubject<boolean>(false);
  public $selectedCurrency = new BehaviorSubject<string>('LKR');
  public $isCheck = new BehaviorSubject<boolean>(true);
  public $deleteData = new BehaviorSubject<{
    delete_index: number;
    delete_type: string;
  }>({
    delete_index: 0,
    delete_type: '',
  });
  constructor(private http: HttpClient) {}

  public getAllReceipts(
    params: HttpParams
  ): Observable<CommonResponseDTO<IReceipt[]>> {
    const url = generateURL({ endpoint: ENDPOINTS.RECEIPTS_GET_ALL });
    return this.http.get<CommonResponseDTO<IReceipt[]>>(url, { params });
  }

  createReceiptHandler(
    data: IAddReceipt
  ): Observable<CommonResponseDTO<IReceipt>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_ADD_NEW,
    });
    return this.http.post<CommonResponseDTO<IReceipt>>(url, data);
  }

  createReceipt(data: IAddReceipt[]): Observable<any[]> {
    const observables: Observable<any>[] = data.map((receipt) => {
      return this.createReceiptHandler(receipt);
    });

    // Use concat to execute observables sequentially, and toArray to collect responses in an array
    return concat(...observables).pipe(toArray());
  }
  createReceiptAggregator(
    separateReceipts: boolean,
    data: IAddReceipt[]
  ): Observable<CommonResponseDTO<IReceipt>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_AGGREGATOR_NEW,
      params: { separateReceipts },
    });
    return this.http.post<CommonResponseDTO<IReceipt>>(url, data);
  }

  getReceipt(id: string): Observable<CommonResponseDTO<IReceipt>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_GET_ONE,
      params: { id },
    });
    return this.http.get<CommonResponseDTO<IReceipt>>(url);
  }

  updateReceipt(
    data: IAddReceipt,
    id: string
  ): Observable<CommonResponseDTO<IReceipt>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_UPDATE_ONE,
      params: { id },
    });
    return this.http.patch<CommonResponseDTO<IReceipt>>(url, data);
  }

  deleteReceipt(id: string): Observable<CommonResponseDTO<IReceipt>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_DELETE_ONE,
      params: { id },
    });
    return this.http.delete<CommonResponseDTO<IReceipt>>(url);
  }

  addReceiptCancellationRequest(
    data: RequestCancellationData,
    id: string
  ): Observable<CommonResponseDTO<IReceipt>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_ADD_CANCEL_REQUEST,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IReceipt>>(url, data);
  }

  addReceiptCancellationApproval(
    data: ApproveCancellationData,
    id: string
  ): Observable<CommonResponseDTO<IReceipt>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_ADD_CANCEL_APPROVAL,
      params: { id },
    });

    return this.http.patch<CommonResponseDTO<IReceipt>>(url, data);
  }
  public shareReceipt(
    data: IReceiptShare
  ): Observable<CommonResponseDTO<IReceipt[]>> {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPT_SHARE_RECEIPT,
    });
    return this.http.post<CommonResponseDTO<IReceipt[]>>(url, data);
  }

  getAllRequestCancellationData(): Observable<
    CommonResponseDTO<IRequestCancellationData[]>
  > {
    const url = generateURL({
      endpoint: ENDPOINTS.RECEIPTS_GET_REQUEST_CANCELLATION_DATA,
    });
    return this.http.get<CommonResponseDTO<IRequestCancellationData[]>>(url);
  }

  public listenToDepositChange(value: boolean) {
    this.$isDeposit.next(value);
  }

  public listenToCurrencyChange(value: string) {
    this.$selectedCurrency.next(value);
  }

  public listenToCollapseChange(value: boolean) {
    this.$isCollapsed.next(value);
  }

  public listenToDeleteDataChange(value: {
    delete_index: number;
    delete_type: string;
  }) {
    this.$deleteData.next(value);
  }
  public listenToISCheckChange(value: boolean) {
    this.$isCheck.next(value);
  }
}
