export enum POLICY_CLASSES {
  MOTOR = 'MOTOR',
  MEDICAL = 'MEDICAL',
  TRAVEL = 'TRAVEL',
  FIRE = 'FIRE',
  MARINE = 'MARINE',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum ENGAGEMENT_TYPES {
  DIGITAL = 'DIGITAL',
  TRADITIONAL = 'TRADITIONAL',
}

export enum CUSTOMER_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  FLEET = 'FLEET',
  ORGANIZATION = 'ORGANIZATION',
  GROUP = 'GROUP',
}

export enum POLICY_TYPES {
  GENERAL = 'GENERAL',
  LIFE = 'LIFE',
}

export enum PAYMENT_METHODS {
  CREDIT = 'CREDIT',
  NON_CREDIT = 'NON CREDIT',

  // Non Credit types
  CASH = 'CASH',
  BANK_TRANSFERS = 'BANK TRANSFERS',
  CHEQUE = 'CHEQUE',
}

export enum DOCUMENT_TYPES {
  ADDITIONAL = 'ADDITIONAL',
  LUXURY_TAX = 'LUXURY_TAX',
}

export enum POLICY_STATUS {
  AUTHORIZATION_PENDING = 'AUTHORIZATION PENDING',
  AUTHORIZATION_PENDING_POSTPONED = 'AUTHORIZATION PENDING POSTPONED',
  QUOTATION_ISSUED = 'QUOTATION ISSUED',
  POLICY_ISSUED = 'POLICY ISSUED',
  POLICY_CANCELLED = 'POLICY CANCELLED',
  POLICY_ISSUED_ACTIVATION_PENDING = 'POLICY ISSUED (ACTIVATION PENDING)',
  POLICY_CANCELATION_PENDING = 'POLICY CANCELATION PENDING',
  QUOTATION_PROCESSING = 'QUOTATION PROCESSING',
  PROPOSAL_DRAFTED = 'PROPOSAL DRAFTED',
  PROPOSAL_SUBMITTED = 'PROPOSAL SUBMITTED',
  PROPOSAL_RETURNED = 'PROPOSAL RETURNED',
  INVOICE_GENERATED = 'INVOICE GENERATED',
  PROPOSAL_AUTHORIZED = 'PROPOSAL AUTHORIZED',
  PROPOSAL_AUTHORIZATION_PENDING = 'PROPOSAL AUTHORIZATION PENDING',
  PROPOSAL_AUTHORIZATION_PENDING_POSTPONED = 'PROPOSAL AUTHORIZATION PENDING POSTPONED',
  PENDING_PAYMENT = 'PENDING PAYMENT',
  QUICK_QUOTE = 'QUICK_QUOTE',
  RETURNED_TO_AGENT = 'RETURNED TO AGENT',
  RETURNED_TO_UNDERWRITER = 'RETURNED TO UNDERWRITER',
  REJECTED = 'REJECTED',
  PENDING_SUPERVISOR_APPROVAL = 'PENDING SUPERVISOR APPROVAL',
  PROPOSAL_PENDING_SUPERVISOR_APPROVAL = 'PROPOSAL PENDING SUPERVISOR APPROVAL',
  APPROVED = 'APPROVED',
  PROPOSAL_CANCELED = 'PROPOSAL CANCELED',
  PROPOSAL_RESUBMITTED = 'PROPOSAL RESUBMITTED',
  AMENDMENT_REQUESTED = 'AMENDMENT REQUESTED',
  AMENDMENT_CANCELED = 'AMENDMENT CANCELED',
  CANCELATION_REQUESTED = 'CANCELATION REQUESTED',
  POLICY_CANCELATION_PENDING_SUPERVISOR_APPROVAL = 'POLICY CANCELATION PENDING SUPERVISOR APPROVAL',
  POLICY_CANCELATION_REQUEST_APPROVED = 'POLICY CANCELATION REQUEST APPROVED',
  POLICY_CANCELATION_RETURNED_TO_UNDERWRITER = 'POLICY CANCELATION RETURNED TO UNDERWRITER',
  EXPIRED = 'EXPIRED',
  RENEWAL_REQUESTED = 'RENEWAL REQUESTED',
  RENEWAL_PROCESSING = 'RENEWAL PROCESSING',
  RENEWED = 'RENEWED',

  //This is only for mobile app
  PROPOSAL_PENDING_VKYC = 'PROPOSAL PENDING VKYC',
}

export enum POLICY_SUB_STATUS {
  RAISED_REQUIREMENTS = 'RAISED REQUIREMENTS',
  COVER_NOTE_REQUESTED = 'COVER NOTE REQUESTED',
  COVER_NOTE_ISSUED = 'COVER NOTE ISSUED',
  COVER_NOTE_CANCELLED = 'COVER NOTE CANCELLED',
  COVER_NOTE_EXPIRED = 'COVER NOTE EXPIRED',
}

export enum POLICY_QUOTATION_FLOW {
  STANDARD = 'STANDARD',
  NON_STANDARD = 'NON-STANDARD',
}

export enum COMMENT_TYPES {
  INTERNAL = 'INTERNAL',
  RAISE_REQUIREMENTS = 'RAISE_REQUIREMENTS',
  RETURN_TO_AGENT = 'RETURN_TO_AGENT',
  REFER_TO_REINSURER = 'REFER_TO_REINSURER',
  RETURN_TO_UNDERWRITER = 'RETURN_TO_UNDERWRITER',
  APPROVE = 'APPROVE',
  APPROVE_AND_ASSIGN_ANOTHER = 'APPROVE_AND_ASSIGN_ANOTHER',
  POSTPONED = 'POSTPONED',
  REJECT = 'REJECT',
}

export enum AMENDMENT_TYPES {
  REQUEST_CHANGES = 'REQUEST CHANGES',
  AMEND_AS_NEW = 'AMEND AS NEW',
}

export enum POLICY_REFERENCE_CATEGORIES {
  POLICY = 'POLICY DOCUMENTS',
}

export enum CALCULATION_METHOD {
  PRO_RATA = 'PRO_RATA',
  SHORT_TERM = 'SHORT_TERM',
  ZERO_REFUND = 'ZERO_REFUND',
  INCEPTION = 'INCEPTION',
}

export enum REFUND_TYPE {
  CUSTOMER = 'CUSTOMER',
  EXCESS = 'EXCESS',
}

export enum MEDICAL_MEMBER_STATUS {
  AGE_ABOVE_65 = 'AGE ABOVE 65',
  VERIFIED = 'VERIFIED',
}

export enum TRAVEL_TRIP_TYPES {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

//only for customer mobile app
export enum POLICY_FILTER_TYPES {
  ALL = 'ALL',
  OPEN = 'OPEN',
  LAPSED = 'LAPSED',
  INPROGRESS = 'INPROGRESS',
}

export enum SUM_ASSURED_SOURCE_TYPES {
  ITEM_SUM_ASSURED = 'Item Sum Assured',
  SUM_ASSURED = 'Sum Assured',
}

export enum NET_RATE_SOURCE_TYPES {
  COVER_NET_RATE = 'Cover Net Rate',
}

export enum RENEWAL_PROCESS_TYPE {
  WITHOUT_CHANGES = 'Without Changes',
  WITH_CHANGES = 'With Changes',
}
