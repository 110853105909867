import { Pipe, PipeTransform } from '@angular/core';

import { CLAIMS_STATUS } from '@shared/constants';

@Pipe({
  name: 'titleCaseEnum',
})
export class TitleCaseEnumPipe implements PipeTransform {
  transform(value: CLAIMS_STATUS): string {
    if (!value) return '';
    return value
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
