export enum POLICY_DOCUMENT_FIELD {
  URL = 'URL',
}
export enum LOADING_FIELDS_TYPES {
  CODE = 'CODE',
  LOADING = 'LOADING',
  DESCRIPTION = 'DESCRIPTION',
}

export enum EXCLUSIONS_FIELDS_TYPES {
  CODE = 'CODE',
  EXCLUSION = 'EXCLUSION',
  DESCRIPTION = 'DESCRIPTION',
}
