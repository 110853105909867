import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { IConfigToggleButton } from '@shared/constants';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements AfterViewInit {
  @Input() config: IConfigToggleButton = null;

  @Input() index = 0;

  @Output() onClickAction = new EventEmitter<IConfigToggleButton>();

  isToggled = false;

  ngAfterViewInit() {
    return;
  }

  onToggle() {
    this.config.selected = !this.config.selected;
    this.onClickAction.emit(this.config);
  }
}
