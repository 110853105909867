//This uses if JOHN DOE => John Doe
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeWords',
})
export class CapitalizeWordsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    let data = value
      .toLowerCase()
      .replace(/\b\w/g, (firstChar) => firstChar.toUpperCase());
    if (data.split(' ').includes('Of')) {
      data = data.replace(/\bOf\b/g, 'of');
    }
    return data;
  }
}
