import { ELAutocompleteElement } from '@el-autocomplete';

import { IReferenceCategory } from '@shared/interfaces';

export function referenceCategoriesToElAutocomplete(
  categories: IReferenceCategory[]
): ELAutocompleteElement<IReferenceCategory>[] {
  return categories.map((category) => ({
    value: (category._id ?? category.name).toString(),
    displayValue: category.name,
    originalData: category,
  }));
}
