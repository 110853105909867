export enum RECEIPTS_PAYMENT_METHODS {
  CASH = 'CASH',
  CHEQUE = 'CHEQUE',
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK TRANSFER',
  ADJUSTMENT = 'ADJUSTMENT',
}

export enum RECEIPTS_PAYMENT_OPTIONS {
  PREMIUM = 'PREMIUM',
  DEPOSIT = 'DEPOSIT',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export enum RECEIPTS_UTILIZED_OPTION {
  DEPOSIT = 'DEPOSIT',
  EXCESS = 'EXCESS',
  DEPOSIT_AND_EXCESS = 'DEPOSIT_AND_EXCESS',
}

export enum CARD_PAYMENT_TYPE {
  ONLINE = 'ONLINE',
  CARD_MACHINE = 'CARD MACHINE',
}

export enum RECEIPTS_REFERENCE_CATEGORIES {
  BANK_TRANSFER_TYPE = 'BANK TRANSFER TYPES',
}

export enum BANK_TRANSFER_FIELDS {
  CODE = 'CODE',
  TRANSFER_TYPE = 'TRANSFER TYPE',
}

export enum MISCELLANEOUS_REFERENCE_CATEGORIES {
  MISCELLANEOUS_TYPES = 'MISCELLANEOUS TYPES',
}

export enum MISCELLANEOUS_FIELDS {
  CODE = 'CODE',
  MISCELLANEOUS_TYPE = 'MISCELLANEOUS TYPE',
}

export enum THIRD_PARTY_PAYMENTS_REFERENCE_CATEGORIES {
  THIRD_PARTY_PAYMENT_TYPE = 'THIRD PARTY PAYMENT TYPES',
}

export enum THIRD_PARTY_PAYMENTS_FIELDS {
  NAME = 'NAME',
}

export enum APPROVE_STATUS {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export enum RECEIPT_REQUEST_CANCELLATION_REASONS {
  USER_MISTAKE = 'User Mistake',
  RETURNED_CHEQUE = 'Returned Cheque',
}
