export enum WORKFLOW_TYPES {
  NEW_BUSINESS = 'NEW_BUSINESS',
  CLAIMS = 'CLAIMS',
  ENDORSEMENTS = 'ENDORSEMENTS',
}

export enum WORKFLOW_FORM_TYPES {
  QUOTATION_REQUEST = 'quotation-request',
  QUOTATION_EVALUATION = 'quotation-evaluation',
  PROPOSAL_FORM = 'proposal-form',
  PROPOSAL_EVALUATION = 'proposal-evaluation',
  POLICY_ISSUANCE = 'policy-issuance',
  ENDORSEMENT_CRITERIA = 'endorsement-criteria',
  REQUEST_EVALUATION = 'request-evaluation',
  INTIMATION_FORM = 'intimation-form',
  CLAIM_HANDLER = 'claim-handler',
  PAYMENT_SETTLEMENT = 'payment-settlement',
}
