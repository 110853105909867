import { Pipe, PipeTransform } from '@angular/core';

import { thousandSeparatorAdd, thousandSeparatorRemove } from '@shared/utils';

@Pipe({
  name: 'commaMovePlace',
})
export class CommaMovePlacePipe implements PipeTransform {
  transform(value: any): any {
    // Remove commas and then add them with two decimals
    return thousandSeparatorAdd(thousandSeparatorRemove(value));
  }
}
