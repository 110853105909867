// If there is all capitals and joined words with underscore you can use this
// eg : JOHN_DOE ==> John Doe
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const words = value.split('_');

    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    return formattedWords.join(' ');
  }
}
