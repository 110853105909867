<div class="icon-container">
  <button
    *ngIf="config.selected"
    class="toggle-button"
    type="button"
    mat-raised-button
    style="width: 100%"
    (click)="onToggle()"
    color="primary"
  >
    <div class="button-wrapper">
      <div class="title-wrapper">{{ config.title }}</div>
      <div class="icon-wrapper">
        <mat-icon class="icon invertColor" [svgIcon]="config.icon"></mat-icon>
      </div>
    </div>
  </button>

  <button
    *ngIf="!config.selected"
    class="toggle-button"
    type="button"
    mat-stroked-button
    style="width: 100%"
    (click)="onToggle()"
  >
    <div class="button-wrapper">
      <div class="title-wrapper">{{ config.title }}</div>
      <div class="icon-wrapper">
        <mat-icon class="icon" [svgIcon]="config.icon"></mat-icon>
      </div>
    </div>
  </button>
</div>
