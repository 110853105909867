export enum BRANCH_PROVINCES_FIELDS {
  BRANCH_CODE = 'CODE',
  BRANCH_PROVINCE = 'PROVINCE',
  IS_ENABLE = 'ISENABLE',
}

export enum BRANCH_DISTRICTS_FIELDS {
  BRANCH_PROVINCE = 'PROVINCE',
  BRANCH_CODE = 'CODE',
  BRANCH_DISTRICT = 'DISTRICT',
  IS_ENABLE = 'ISENABLE',
}

export enum BRANCH_ZONES_FIELDS {
  BRANCH_CODE = 'CODE',
  BRANCH_ZONE = 'ZONE',
  IS_ENABLE = 'ISENABLE',
}

export enum BRANCH_REGIONS_FIELDS {
  BRANCH_ZONE = 'ZONE',
  BRANCH_CODE = 'CODE',
  BRANCH_REGION = 'REGION',
  IS_ENABLE = 'ISENABLE',
}
