export enum ENDPOINTS {
  // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //
  // ASSETS
  ASSETS_IMAGES_UPLOAD,
  ASSETS_IMAGES_DELETE,

  // AUTH
  AUTH_REFRESH_TOKEN,
  AUTH_LOGIN,
  AUTH_PROFILE_IMAGE_UPLOAD,
  AUTH_CODE,
  AUTH_LOGIN_IMPERSONATE,
  AUTH_CHECK_USER,
  AUTH_USERNAME,
  AUTH_LOGOUT,
  AUTH_FORGOT_PASSWORD,
  AUTH_VALIDATE_TOKEN,
  AUTH_RESET_PASSWORD,
  AUTH_REGISTER,
  AUTH_VERIFY,
  AUTH_PROFILE,
  AUTH_SEND_OTP,

  //AGREEMENTS
  AGREEMENTS_ADD_NEW,
  AGREEMENTS_GET_ALL,
  AGREEMENTS_GET_ONE,
  AGREEMENTS_DELETE,
  AGREEMENTS_RESTORE,
  AGREEMENTS_UPDATE,

  // ACCOUNTS
  ACCOUNTS_GET_ALL,
  ACCOUNTS_ADD_NEW,
  ACCOUNTS_GET_ALL_PARTIAL_DATA,
  ACCOUNTS_GET_ONE,
  ACCOUNTS_UPDATE_ONE,
  ACCOUNTS_DELETE_ONE,
  ACCOUNTS_RESTORE_ONE,
  ACCOUNTS_DISABLE,
  ACCOUNTS_ENABLE,

  // ASSESSORS
  ASSESSORS_GET_ALL,
  ASSESSORS_GET_ALL_MAP,
  ASSESSORS_ADD_NEW,
  ASSESSORS_GET_ONE,
  ASSESSORS_GET_ONE_IDENTITY,
  ASSESSORS_UPDATE_ONE,
  ASSESSORS_UPDATE_STATUS,

  // ASSESSMENTS
  ASSESSMENTS_GET_ALL,

  // BLACKLIST
  BLACKLIST_ADD_NEW,
  BLACKLIST_GET_ALL,
  BLACKLIST_GET_ONE,
  BLACKLIST_UPDATE_ONE,
  BLACKLIST_DELETE_ONE,
  BLACKLIST_RESTORE_ONE,
  BLACKLIST_GET_ALL_DOCUMENTATIONS,
  BLACKLIST_GENERATE_PUBLIC_API_KEY,
  BLACKLIST_SAVE_GENERATED_PUBLIC_KEY,
  BLACKLIST_GET_SPECIFIC_GENERATED_KEY,
  BLACKLIST_GET_ALL_GENERATED_KEYS,
  BLACKLIST_DELETE_KEY,
  BLACKLIST_RESTORE_KEY,
  BLACKLIST_GENERATE_SECRETS,

  // BRANCHES
  BRANCHES_GET_ALL,
  BRANCHES_GET_ALL_PARTIAL_DATA,
  BRANCHES_ADD_NEW,
  BRANCHES_GET_ONE,
  BRANCHES_UPDATE_ONE,
  BRANCHES_DELETE_ONE,
  BRANCHES_RESTORE_ONE,
  BRANCHES_DISABLE,
  BRANCHES_ENABLE,
  BRANCHES_VIEW_HIERARCHY,

  // CONTROLS
  CONTROLS_GET_ALL,
  CONTROLS_ADD_NEW,
  CONTROLS_GET_ALL_PARTIAL_DATA,
  CONTROLS_GET_ONE,
  CONTROLS_UPDATE_ONE,
  CONTROLS_DELETE_ONE,
  CONTROLS_RESTORE_ONE,
  CONTROLS_DISABLE,
  CONTROLS_ENABLE,

  //CHART OF ACCOUNTS
  CHART_OF_ACCOUNT_GET_ALL,
  CHART_OF_ACCOUNT_ADD_NEW,
  CHART_OF_ACCOUNT_GET_ALL_PARTIAL_DATA,
  CHART_OF_ACCOUNT_GET_ONE,
  CHART_OF_ACCOUNT_UPDATE_ONE,
  CHART_OF_ACCOUNT_DELETE_ONE,
  CHART_OF_ACCOUNT_RESTORE_ONE,
  CHART_OF_ACCOUNT_DISABLE,
  CHART_OF_ACCOUNT_ENABLE,

  // CLAIMS
  CLAIMS_ADD_NEW,
  CLAIMS_ADD_NEW_MEDICAL_BULK,
  CLAIMS_GET_ALL,
  CLAIMS_GET_ONE,
  CLAIMS_DELETE_ONE,
  CLAIMS_RESTORE_ONE,
  CLAIMS_UPDATE_ONE,
  CLAIMS_GET_PREVIOUS_CLAIMS,

  CLAIMS_GET_ALL_DECISIONS,
  CLAIMS_ADD_DECISION,

  CLAIMS_GET_SUPERVISOR_DECISION,
  CLAIMS_ADD_SUPERVISOR_DECISION,

  CLAIMS_RAISE_APPEAL,

  CLAIMS_GET_ALL_REPORTS,
  CLAIMS_ADD_REPORT,
  CLAIMS_UPDATE_REPORT,
  CLAIMS_DELETE_REPORT,

  CLAIMS_GET_ALL_DOCUMENTS,
  CLAIMS_ADD_DOCUMENT,
  CLAIMS_DELETE_DOCUMENT,

  CLAIMS_GET_ALL_SALVAGES,
  CLAIMS_ADD_SALVAGES_ITEM,
  CLAIMS_UPDATE_SALVAGE,
  CLAIMS_DELETE_SALVAGE,

  CLAIMS_GET_ALL_FEED_BACKS,
  CLAIMS_ADD_FEED_BACK,
  CLAIMS_UPDATE_FEED_BACK,
  CLAIMS_DELETE_FEED_BACK,

  CLAIMS_GET_ALL_ASSESS_FOR_SELECTED_CLAIM,
  CLAIMS_GET_ALL_ASSESS,
  CLAIMS_ADD_ASSESS,
  CLAIMS_UPDATE_ASSESS,
  CLAIMS_DELETE_ASSESS,

  CLAIMS_GET_ALL_ACTIVITY_HISTORY,

  CLAIMS_GET_PAYMENT_SUGGESTIONS,
  CLAIMS_ADD_PAYMENT_SUGGESTIONS,
  CLAIMS_GET_PAYMENT_OPTION,
  CLAIMS_ADD_PAYMENT_OPTION,
  CLAIMS_ADD_PAYMENT_APPROVAL,
  CLAIMS_GET_PAYMENT_APPROVAL,
  CLAIMS_GET_ALL_CONFIRMATIONS,
  CLAIMS_ADD_CONFIRMATION,
  CLAIMS_UPDATE_CONFIRMATION,
  CLAIMS_DELETE_CONFIRMATION,

  // COVER NOTES
  COVER_NOTES_GET_ALL,
  COVER_NOTES_ADD_NEW,
  COVER_NOTES_GET_ONE,
  COVER_NOTES_UPDATE_ONE,
  COVER_NOTES_CANCEL_ONE,
  COVER_NOTES_UPDATE_ONE_BY_POLICY_ID,

  // CUSTOMERS
  CUSTOMERS_GET_ALL,
  CUSTOMERS_ADD_NEW,
  CUSTOMERS_GET_ONE,
  CUSTOMERS_GET_ONE_BY_NIC,
  CUSTOMERS_UPDATE_ONE,

  // ENDORSEMENTS
  ENDORSEMENTS_ADD_NEW,
  ENDORSEMENTS_GET_ALL,
  ENDORSEMENT_CLAUSE_GET_RECENT,
  ENDORSEMENT_EXCLUSIONS_GET_RECENT,
  ENDORSEMENT_GET_ONE,
  ENDORSEMENT_UPDATE_ONE,
  ENDORSEMENT_SET_APPROVED_PREMIUM,
  ENDORSEMENT_DELETE_ONE,
  ENDORSEMENT_RESTORE_ONE,

  ENDORSEMENTS_ASSIGN_AUTHORIZER,
  ENDORSEMENTS_GET_ALL_AUTHORIZERS,
  ENDORSEMENTS_ADD_AUTHORIZATION_COMMENTS,
  ENDORSEMENTS_GET_AUTHORIZATION_COMMENTS,
  ENDORSEMENTS_ADD_INCLUSION_COMMENTS,
  ENDORSEMENTS_GET_INCLUSION_COMMENTS,
  ENDORSEMENT_GET_SCHEDULE_TEMPLATE,
  ENDORSEMENT_GET_INCLUSION_SCHEDULE_TEMPLATE,
  ENDORSEMENT_PUBLISH,

  //ENTRIES
  ENTRIES_GET_ALL,
  ENTRIES_GET_ONE,
  ENTRIES_ADD_NEW,

  //EXCHANGE-RATES
  EXCHANGE_RATES_GET_ALL,
  EXCHANGE_RATES_ADD_NEW,
  EXCHANGE_RATES_UPDATE,
  EXCHANGE_RATES_GET_ONE,
  EXCHANGE_RATES_ADD_NEW_BULK,
  EXCHANGE_RATES_BULK_UPDATE,
  EXCHANGE_RATES_DELETE_ONE,
  EXCHANGE_RATES_RESTORE_ONE,

  // CONNECTED-APPS
  CONNECTED_APPS_CONNECT_NEW_APPS,
  CONNECTED_APPS_GET_ALL_APPS,
  CONNECTED_APPS_GET_ONE_APP,
  CONNECTED_APPS_UPDATE_ONE_APP,
  CONNECTED_APPS_DELETE_ONE_APP,
  CONNECTED_APPS_CONNECT_OR_DISCONNECT_APP,
  CONNECTED_APPS_GET_ALL_FIELDS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_PERMISSIONS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_ROLES_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_DOCUMENTATIONS_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GET_ALL_REFERENCES_OF_A_CONNECTED_APP,
  CONNECTED_APPS_GENERATE_PUBLIC_API_KEY,
  CONNECTED_APPS_GENERATE_PUBLIC_CLIENT_CREDENTIALS,
  CONNECTED_APPS_GENERATE_SECRETS,
  CONNECTED_APPS_SAVE_GENERATED_PUBLIC_KEY,
  CONNECTED_APPS_SAVE_DELETED_PUBLIC_KEY,
  CONNECTED_APPS_SAVE_DELETED_CLIENT_SECRET,
  CONNECTED_APPS_UPDATE_GENERATED_PUBLIC_KEY,
  CONNECTED_APPS_GET_SPECIFIC_GENERATED_KEY,
  CONNECTED_APPS_GET_ALL_GENERATED_KEYS,
  CONNECTED_APPS_DELETE_KEY,
  CONNECTED_APPS_RESTORE_KEY,
  CONNECTED_APPS_HANDSHAKE,

  // CONNECTIONS
  CONNECTIONS_GET_ALL,
  CONNECTIONS_UPDATE,

  // DOUBLE-ENTRY
  ADD_DOUBLE_ENTRIES,
  GET_DOUBLE_ENTRIES,
  UPDATE_DOUBLE_ENTRIES,

  // IDENTITIES
  IDENTITIES_GET_ONE,
  IDENTITIES_GET_ALL,
  IDENTITIES_GET_INTERNAL_FIELD,
  IDENTITIES_SUBORDINATES,
  IDENTITIES_SUBORDINATE_IDS,
  IDENTITIES_HIERARCHICAL,

  // INVOICES
  INVOICES_GET_ALL,
  INVOICES_SHARE_INVOICE,
  INVOICES_SHARE_PAYABLE_LINK,
  INVOICE_GET_BY_PARAMS,
  INVOICES_HASH_PAYMENT_DETAILS,

  //REPORTS
  DEBTORS_OUTSTANDING_REPORTS_GET_ALL,
  CUSTOMER_REPORTS_GET_ALL,
  CUSTOMER_REPORTS_GET_ALL_BY_ACCOUNT,
  REPORT_STATEMENT,

  //INCIDENTS
  INCIDENTS_GET_ALL,
  INCIDENTS_ADD_NEW,
  INCIDENTS_DELETE_ONE,
  INCIDENTS_UPDATE_ONE,
  INCIDENTS_RESTORE_ONE,
  INCIDENTS_GET_ONE,
  //INTEGRATION - ENDPOINTS
  INTEGRATION_ENDPOINTS_GET_ALL,
  INTEGRATION_ENDPOINTS_ADD_ONE,
  INTEGRATION_ENDPOINTS_UPDATE_ONE,
  INTEGRATION_ENDPOINTS_DELETE_ONE,
  INTEGRATION_ENDPOINTS_RESTORE_ONE,
  INTEGRATION_ENDPOINTS_VERIFY_ONE,

  //INTEGRATION - MAPPING
  INTEGRATION_MAPPINGS_ADD_ONE,
  INTEGRATION_MAPPINGS_GET_ALL,
  INTEGRATION_MAPPINGS_UPDATE_ONE,
  INTEGRATION_MAPPINGS_DELETE_ONE,
  INTEGRATION_MAPPINGS_RESTORE_ONE,
  INTEGRATION_MAPPINGS_VERIFY_ONE,

  // FILES
  FILES_UPLOAD,
  FILES_DOWNLOAD,
  FILES_DELETE,

  // JOB_QUEUE
  JOB_QUEUE_CHECK_STATUS,

  // MODULES
  MODULES_GET_ALL,
  MODULES_GET_ONE_BY_ID,
  MODULES_UPDATE,
  MODULES_RESET,

  // PERSONALIZATION
  PERSONALIZATION_GET,
  PERSONALIZATION_UPDATE,

  // PRIVILEGES
  PRIVILEGES_CREATE_ONE,
  PRIVILEGES_GET_ALL,
  PRIVILEGES_GET_ONE_BY_ID,
  PRIVILEGES_GET_RELEVANT,
  PRIVILEGES_UPDATE,
  PRIVILEGES_DELETE,
  PRIVILEGES_RESTORE,

  // NOTIFICATION
  NOTIFICATION_CERATE_WITH_USER,
  NOTIFICATION_CERATE_WITHOUT_USER,
  NOTIFICATION_CREATE_MULTIPLE_NOTIFICATIONS_WITH_USERS,
  NOTIFICATION_GET,
  NOTIFICATION_GET_USER_NOTIFICATIONS,
  NOTIFICATION_FILTER,
  NOTIFICATIONS_MARK_AS_READ,
  NOTIFICATIONS_MARK_ALL_AS_READ,

  //PRODUCTS
  PRODUCTS_ADD_NEW,
  PRODUCTS_GET_ALL,
  PRODUCTS_GET_ALL_CODES,
  PRODUCTS_GET_ALL_MORE_DATA,
  PRODUCTS_GET_ONE,
  PRODUCTS_DELETE,
  PRODUCTS_RESTORE,
  PRODUCTS_UPDATE,

  //PAYMENTS
  PAYMENTS_GET_ALL,
  PAYMENTS_CREATE_PAYMENT,
  PAYMENTS_GET_ONE,
  PAYMENTS_UPDATE_PAYMENT,
  PAYMENTS_UPDATE_STATUS,
  PAYMENTS_GENERATE_VOUCHER_NUMBER_AND_BATCH_ID,

  // CONFIGURABLE_FIELDS - VALIDATIONS
  CONFIGURABLE_FIELDS_VALIDATIONS_NAME,
  CONFIGURABLE_FIELDS_VALIDATIONS_PHONE_NUMBER,
  CONFIGURABLE_FIELDS_VALIDATIONS_ADDRESS,
  CONFIGURABLE_FIELDS_VALIDATIONS_EMAIL,

  // REFERENCES - PREDEFINED_REFERENCES
  PREDEFINED_REFERENCES_GET_VALUE,

  // REFERENCES - REFERENCE_CATEGORIES
  REFERENCE_CATEGORIES_GET_ALL,
  REFERENCE_CATEGORIES_GET_SINGLE,
  REFERENCE_CATEGORIES_GET_EXTERNAL,
  REFERENCE_CATEGORIES_GET_EXTERNAL_DELETE,
  REFERENCE_CATEGORIES_ADD_NEW,
  REFERENCE_CATEGORIES_UPDATE,
  REFERENCE_CATEGORIES_DELETE,
  REFERENCE_CATEGORIES_RESTORE,
  REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  REFERENCE_CATEGORIES_REFERENCES_GET_ALL_V2,
  REFERENCE_CATEGORIES_REFERENCES_ADD_NEW,
  REFERENCE_CATEGORIES_REFERENCES_BULK_ADD,

  // REFERENCES - REFERENCES
  REFERENCE_EDIT,
  REFERENCE_DELETE,
  REFERENCE_RESTORE,
  REFERENCES_FIELDS_GET_VALUE,

  // REFERENCES - TEMPLATES
  TEMPLATES_ADD_NEW,
  TEMPLATES_UPDATE_ONE,
  TEMPLATES_GET_ONE,

  // REFERENCES - FORMULA
  FORMULA_ANALYZE,
  FORMULA_EVALUATE,

  //RECEIPTS
  RECEIPTS_GET_ALL,
  RECEIPTS_ADD_NEW,
  RECEIPTS_AGGREGATOR_NEW,
  RECEIPTS_GET_ONE,
  RECEIPTS_UPDATE_ONE,
  RECEIPTS_DELETE_ONE,
  RECEIPTS_ADD_CANCEL_REQUEST,
  RECEIPTS_ADD_CANCEL_APPROVAL,
  RECEIPTS_GET_REQUEST_CANCELLATION_DATA,
  RECEIPTS_GET_ALL_EXCESS_DATA,
  RECEIPT_SHARE_RECEIPT,

  // PARTNERS
  PARTNERS_ADD_NEW,
  PARTNERS_GET_ALL,
  PARTNERS_GET_ALL_PARTIAL_DATA,
  PARTNERS_GET_ONE,
  PARTNERS_DELETE_ONE,
  PARTNERS_UPDATE_ONE,
  PARTNERS_GET_AGENT_INFORMATION,
  PARTNERS_RESTORE_ONE,
  PARTNERS_VIEW_HIERARCHY,
  PARTNERS_GET_FILTERED_DATA,
  PARTNERS_CHECK_VALIDITY,

  // STORAGE - INTERNAL
  STORAGE_INTERNAL_CONTENT,
  STORAGE_INTERNAL_DELETE,
  STORAGE_INTERNAL_PREVIEW,
  STORAGE_INTERNAL_UPLOAD,
  STORAGE_INTERNAL_DOWNLOAD,
  STORAGE_INTERNAL_SHARE_INTERNALLY,

  // SETUP - IAM
  SETUP_IAM_AUTHORIZERS_AZURE,
  SETUP_IAM_HANDSHAKE,

  // SETUP - BACKUP_SCHEDULES
  SETUP_BACKUPS_SCHEDULES_ADD_NEW,
  SETUP_BACKUPS_SCHEDULES_GET_ALL,
  SETUP_BACKUPS_SCHEDULES_DELETE_ONE,
  SETUP_BACKUPS_SCHEDULES_ENABLE_DISABLE_ONE,

  // SETUP - BACKUPS
  SETUP_BACKUPS_BACKUP_NOW,
  SETUP_BACKUPS_GET_ALL,
  SETUP_BACKUPS_DOWNLOAD_ONE,
  SETUP_BACKUPS_DELETE_ONE,

  // SETUP - LANGUAGES
  SETUP_LANGUAGES_ADD_ONE,
  SETUP_LANGUAGES_GET_ALL,
  SETUP_LANGUAGES_GET_ONE,
  SETUP_LANGUAGES_UPDATE_ONE,
  SETUP_LANGUAGES_ENABLE_DISABLE_ONE,
  SETUP_LANGUAGES_DELETE_ONE,
  SETUP_LANGUAGES_REFRESH_ONE,
  SETUP_LANGUAGES_GET_CONTENT_OF_ONE,

  // REINSURERS
  REINSURERS_GET_ALL,
  REINSURERS_ADD_NEW,
  REINSURERS_CHECK_DATA,
  REINSURERS_GET_ONE,
  REINSURERS_UPDATE_ONE,
  REINSURERS_DELETE_ONE,
  REINSURERS_RESTORE_ONE,

  // PACKAGES
  PACKAGES_GET_ALL,
  PACKAGES_ADD_NEW,
  PACKAGES_GET_ONE,
  PACKAGES_UPDATE_ONE,
  PACKAGES_DELETE_ONE,
  PACKAGES_RESTORE_ONE,

  // SETUP - REFERENCE MAPPING
  SETUP_GET_ALL_REFERENCE_MAPPINGS,
  SETUP_GET_REFERENCE_MAPPING_BY_KEY,
  SETUP_UPDATE_REFERENCE_MAPPING,

  // SETUP - CURRENCIES
  SETUP_CURRENCIES_ADD_ONE,
  SETUP_CURRENCIES_GET_ALL,
  SETUP_CURRENCIES_GET_ONE,
  SETUP_CURRENCIES_UPDATE_ONE,
  SETUP_CURRENCIES_DELETE_ONE,
  SETUP_CURRENCIES_RESTORE_ONE,
  SETUP_CURRENCIES_TOGGLE_BASE_CURRENCY,

  //MAPS
  MAPS_GET_ALL,
  MAPS_ADD_NEW,
  MAPS_GET_ONE,
  MAPS_UPDATE_ONE,
  MAPS_DELETE_ONE,
  MAPS_RESTORE_ONE,

  // MAPS - DATA_POINTS
  MAPS_DATA_POINTS_UPDATE_ONE,
  MAPS_DATA_POINTS_DELETE_ONE,
  MAPS_DATA_POINTS_RESTORE_ONE,
  MAPS_DATA_POINTS_GET_ALL,
  MAPS_DATA_POINTS_ADD_NEW,
  MAPS_DATA_POINTS_GET_ONE,
  MAPS_DATA_POINTS_GENERATE_SECRETS,
  MAPS_DATA_POINTS_GET_ALL_DOCUMENTATIONS_OF_A_MAPS_DATA_POINT,
  MAPS_DATA_POINTS_GET_ALL_REFERENCES_OF_A_MAPS_DATA_POINT,
  MAPS_DATA_POINTS_GET_ALL_GENERATED_KEYS,
  MAPS_DATA_POINTS_RESTORE_KEY,
  MAPS_DATA_POINTS_DELETE_KEY,
  MAPS_DATA_POINTS_GET_SPECIFIC_GENERATED_KEY,
  MAPS_DATA_POINTS_GENERATE_PUBLIC_API_KEY,
  MAPS_DATA_POINTS_SAVE_GENERATED_PUBLIC_KEY,

  // SETUP - EXTERNAL SYSTEMS
  SETUP_EXTERNAL_SYSTEMS_GET_ALL,
  SETUP_EXTERNAL_SYSTEM_GET_EXTERNAL_REFERENCE_CATEGORIES,
  SETUP_EXTERNAL_SYSTEMS_UPDATE_ONE,

  // SETUP - EXTERNAL SYSTEMS - KEYS

  SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ALL,
  SETUP_EXTERNAL_SYSTEMS_KEYS_GET_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_DELETE_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_RESTORE_ONE,
  SETUP_EXTERNAL_SYSTEMS_KEYS_SAVE_KEY,
  SETUP_EXTERNAL_SYSTEMS_KEYS_GENERATE_NEW_KEY,

  // ------------------------------------------------------ GENERAL ENDPOINTS ------------------------------------------------------ //
  // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  CLIENT_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  CLIENT_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ CLIENT ENDPOINTS ------------------------------------------------------ //
  // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  EXTERNAL_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  EXTERNAL_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ EXTERNAL ENDPOINTS ------------------------------------------------------ //
  // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //
  // REFERENCES - REFERENCE_CATEGORIES
  IAM_REFERENCE_CATEGORIES_REFERENCES_GET_ALL,
  // REFERENCES - REFERENCES
  IAM_REFERENCES_FIELDS_GET_VALUE,
  // ------------------------------------------------------ IAM ENDPOINTS ------------------------------------------------------ //

  // GATEWAYS
  GATEWAYS_GET_ALL,
  GATEWAYS_GET_ONE,
  GATEWAYS_ENABLE,
  GATEWAYS_DISABLE,
  GATEWAYS_SANDBOX_ENABLE,
  GATEWAYS_SANDBOX_DISABLE,
  GATEWAY_UPDATE_CONFIG,

  // POLICIES
  POLICIES_GET_ALL,
  POLICIES_GET_ALL_FOR_CLAIMS,
  POLICIES_GET_ALL_CLAIM_POLICY_LIST,
  POLICIES_ADD_NEW,
  POLICIES_GET_ONE,
  POLICIES_UPDATE_ONE,
  POLICIES_DELETE_ONE,
  POLICIES_RESTORE_ONE,
  POLICIES_UPDATE_STATUS,
  POLICIES_GET_ALL_COMMENTS,
  POLICIES_ADD_COMMENT,
  POLICIES_GET_ALL_PROPOSAL_COMMENTS,
  POLICIES_ADD_PROPOSAL_COMMENT,
  POLICIES_ADD_PROPOSAL_CREDIT_REQUEST_UPDATES,
  POLICIES_ADD_PROPOSAL_CREDIT_REQUEST_COMMENT,
  POLICIES_ADD_PROPOSAL_CONFIGURATIONS,
  POLICIES_CHANGE_COMMISSIONS_AND_CHARGES,
  POLICIES_UPDATE_PREMIUM_WORKING_DETAILS,
  POLICIES_UPDATE_RENEWAL_PREMIUM_WORKING_DETAILS,
  POLICIES_UPDATE_PREMIUM_WORKING_DETAILS_MED_GROUP,
  POLICIES_UPDATE_SELECTED_SECTIONS_TRAVELS,
  POLICIES_UPDATE_PRODUCT_DETAILS,
  POLICIES_GET_ANNUAL_PREMIUM,
  POLICIES_ADD_PREMIUM_WORKING_INTERNAL_COMMENT,
  POLICIES_GET_ALL_PREMIUM_WORKING_INTERNAL_COMMENTS,
  POLICIES_ASSIGN_AUTHORIZER,
  POLICIES_ASSIGN_PROPOSAL_AUTHORIZER,
  POLICIES_ASSIGN_RENEWAL_AUTHORIZER,
  POLICIES_ASSIGN_CANCEL_POLICY_AUTHORIZER,
  POLICIES_GET_ALL_AUTHORIZERS,
  POLICIES_GET_ALL_PROPOSAL_AUTHORIZERS,
  POLICIES_GET_ALL_POLICY_CANCEL_AUTHORIZERS,
  POLICIES_ADD_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_ADD_PROPOSAL_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_ADD_RENEWAL_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_ADD_POLICY_CANCEL_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_GET_PROPOSAL_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_GET_RENEWAL_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_GET_POLICY_CANCEL_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_GET_ALL_AUTHORIZATION_STEP_COMMENTS,
  POLICIES_PUBLISH_QUOTATION,
  POLICIES_PUBLISH_PROPOSAL,
  POLICIES_RENEWAL_PUBLISH_PROPOSAL,
  POLICIES_GET_SCHEDULE_TEMPLATE,
  POLICIES_GET_AGENT_DATA,
  POLICIES_VERIFY_PROPOSAL_DOCUMENTS,
  POLICIES_ADD_PROPOSAL,
  POLICIES_VERIFY_PROPOSAL_DOCUMENTS_FINAL,
  POLICIES_PROPOSAL_RESUBMISSION,
  POLICIES_PROPOSAL_RAISED_DOCUMENTS_SUBMISSION,
  POLICIES_GET_REFERENCES,
  POLICIES_GET_MEDICAL_PREMIUMS,
  POLICIES_GET_MEDICAL_GROUP_PREMIUMS,
  POLICIES_CANCEL_POLICY,
  POLICIES_GET_RI_LIMITS,
  POLICIES_UPDATE_PROPOSAL_RENEWAL,

  // CHECKOUT
  CHECKOUT_ALL_ALLOWED_PAYMENT_METHODS,
  CHECKOUT_GENERATE_HASH,
  CHECKOUT_PAYHERE_DETAILS,

  // TRANSACTIONS
  TRANSACTIONS_GET_ALL,
  TRANSACTION_CREATE_TRANSACTION,
  TRANSACTION_UPDATE_TRANSACTION_STATUS,
  RECEIPTS_SEND_BODIES_TO_TRANSACTION,
  TRANSACTION_CREATE_RECEIPT,
  INITIALIZE_TRANSACTION_CREATE,
  GET_TRANSACTION,
  GET_TRANSACTION_BY_ORDER_ID,

  // WORKFLOWS
  WORKFLOWS_ADD_NEW,
  WORKFLOWS_GET_ALL,
  WORKFLOWS_GET_ALL_PARTIAL_DATA,
  WORKFLOWS_GET_ONE,
  WORKFLOWS_DELETE_ONE,
  WORKFLOWS_UPDATE_ONE,
  WORKFLOWS_RESTORE_ONE,
  WORKFLOWS_DISABLE,
  WORKFLOWS_ENABLE,
}
