import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { ENDPOINTS, REFERENCE_PERMISSION_ACTIONS } from '@shared/constants';
import {
  CommonResponseDTO,
  IReferenceCategoryRequest,
  IReferenceCategoryResponse,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { LoggedUserService, UserInfoResponseDTO } from '../../auth/services';
import { validateReferenceModulePermissions } from '../../core/helpers';
import { notDeleted } from '../helpers';
import { AddReferenceCategoryComponent } from '../popups/add-reference-category/add-reference-category-popup.component';

type AllReferenceCategoriesResponse = CommonResponseDTO<
  IReferenceCategoryResponse[]
>;
type OneReferenceCategoryResponse =
  CommonResponseDTO<IReferenceCategoryResponse>;
type GetReferenceCategoriesResponse = {
  allCategories: IReferenceCategoryResponse[];
  activeCategories: IReferenceCategoryResponse[];
};

@Injectable({ providedIn: 'root' })
export class ReferenceCategoryService {
  private readonly _allCategories = new BehaviorSubject<
    IReferenceCategoryResponse[]
  >([]);
  public allCategories = this._allCategories.asObservable();

  private readonly _activeCategories = new BehaviorSubject<
    IReferenceCategoryResponse[]
  >([]);
  public activeCategories = this._activeCategories.asObservable();

  private loggedUser: UserInfoResponseDTO;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    loggedUserService: LoggedUserService
  ) {
    loggedUserService.dataStore.subscribe((loggedUser) => {
      this.loggedUser = loggedUser;
    });
  }

  // Kept as Promise<void> on purpose. Don't change this to Observable<IReferenceCategoryResponse[]>
  getAllReferenceCategories(): Promise<GetReferenceCategoriesResponse> {
    return new Promise((resolve, reject) => {
      const url = generateURL({
        endpoint: ENDPOINTS.REFERENCE_CATEGORIES_GET_ALL,
      });

      this.http.get<AllReferenceCategoriesResponse>(url).subscribe({
        next: ({ data }) => {
          if (
            validateReferenceModulePermissions(
              this.loggedUser,
              data,
              REFERENCE_PERMISSION_ACTIONS.READ_CATEGORY
            ) !== 'none'
          ) {
            const allCategories = data;
            const activeCategories = data.filter(notDeleted);

            this._allCategories.next(allCategories);
            this._activeCategories.next(activeCategories);

            resolve({
              allCategories,
              activeCategories,
            });
          } else {
            // Get public reference categories' references
            let filteredPublicCategories: IReferenceCategoryResponse[] = [];

            filteredPublicCategories = data?.filter(
              (category) => category?.is_public
            );

            const allCategories = filteredPublicCategories;
            const activeCategories =
              filteredPublicCategories.filter(notDeleted);

            this._allCategories.next(allCategories);
            this._activeCategories.next(activeCategories.filter(notDeleted));
            resolve({
              allCategories,
              activeCategories,
            });
          }
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  addNewCategory(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [],
          REFERENCE_PERMISSION_ACTIONS.WRITE_CATEGORY
        ) !== 'none'
      ) {
        const dialogRef = this.dialog.open(AddReferenceCategoryComponent);

        dialogRef.afterClosed().subscribe({
          next: (category) => {
            if (category) {
              const url = generateURL({
                endpoint: ENDPOINTS.REFERENCE_CATEGORIES_ADD_NEW,
              });

              this.http
                .post<OneReferenceCategoryResponse>(url, category)
                .subscribe({
                  next: ({ data }) => {
                    this.getAllReferenceCategories()
                      .then(() => resolve(data._id.toString()))
                      .catch(reject);
                  },
                  error: reject,
                });
            } else {
              // cancel from dialog
              resolve(undefined);
            }
          },
          error: reject,
        });
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  addNewExternalCategory(
    category: IReferenceCategoryRequest
  ): Promise<IReferenceCategoryResponse> {
    return new Promise((resolve, reject) => {
      const url = generateURL({
        endpoint: ENDPOINTS.REFERENCE_CATEGORIES_ADD_NEW,
      });
      this.http.post<OneReferenceCategoryResponse>(url, category).subscribe({
        next: (data) => {
          resolve(data.data);
        },
        error: reject,
      });
    });
  }

  updateCategory(
    categoryId: string,
    category: IReferenceCategoryRequest
  ): Promise<GetReferenceCategoriesResponse> {
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [category],
          REFERENCE_PERMISSION_ACTIONS.EDIT_CATEGORY
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_CATEGORIES_UPDATE,
          params: { id: categoryId },
        });

        this.http.patch<OneReferenceCategoryResponse>(url, category).subscribe({
          next: () => {
            this.getAllReferenceCategories().then(resolve).catch(reject);
          },
          error: reject,
        });
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  async getCategoryById(
    categoryId: string,
    withDeleted = false
  ): Promise<IReferenceCategoryResponse | undefined> {
    let behaviorSubject = this._activeCategories;

    if (withDeleted) behaviorSubject = this._allCategories;

    if (behaviorSubject.value?.length === 0)
      await this.getAllReferenceCategories();

    return (behaviorSubject.value || []).find(
      (category) => category._id.toString() === categoryId
    );
  }

  async getCategoryByName(
    name: string,
    withDeleted = false
  ): Promise<IReferenceCategoryResponse | undefined> {
    let behaviorSubject = this._activeCategories;

    if (withDeleted) behaviorSubject = this._allCategories;

    if (behaviorSubject.value?.length === 0)
      await this.getAllReferenceCategories();

    return (behaviorSubject.value || []).find(
      (category) => category.name === name
    );
  }

  async getSingleCategoryFromBackend(
    categoryId: string
  ): Promise<IReferenceCategoryResponse | undefined> {
    return new Promise((resolve, reject) => {
      const url = generateURL({
        endpoint: ENDPOINTS.REFERENCE_CATEGORIES_GET_SINGLE,
        params: { id: categoryId },
      });

      this.http
        .get<CommonResponseDTO<IReferenceCategoryResponse>>(url)
        .subscribe({
          next: ({ data }) => {
            resolve(data);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  private deleteRestoreCategory(
    url: string,
    resolve: (value: GetReferenceCategoriesResponse) => void,
    reject: (err: Error) => void
  ) {
    this.http.delete<OneReferenceCategoryResponse>(url).subscribe({
      next: () => {
        this.getAllReferenceCategories().then(resolve).catch(reject);
      },
      error: reject,
    });
  }

  deleteCategory(
    categoryId: string,
    categoryName: string
  ): Promise<GetReferenceCategoriesResponse> {
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [{ name: categoryName }],
          REFERENCE_PERMISSION_ACTIONS.DELETE_CATEGORY
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_CATEGORIES_DELETE,
          params: { id: categoryId },
        });
        this.deleteRestoreCategory(url, resolve, reject);
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  restoreCategory(
    categoryId: string,
    categoryName: string
  ): Promise<GetReferenceCategoriesResponse> {
    return new Promise((resolve, reject) => {
      if (
        validateReferenceModulePermissions(
          this.loggedUser,
          [{ name: categoryName }],
          REFERENCE_PERMISSION_ACTIONS.RESTORE_CATEGORY
        ) !== 'none'
      ) {
        const url = generateURL({
          endpoint: ENDPOINTS.REFERENCE_CATEGORIES_RESTORE,
          params: { id: categoryId },
        });
        this.deleteRestoreCategory(url, resolve, reject);
      } else {
        reject(new Error('references.root.no-privileged'));
      }
    });
  }

  reset() {
    this._allCategories.next([]);
    this._activeCategories.next([]);
  }
}
