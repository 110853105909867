import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { thousandSeparatorAdd, thousandSeparatorRemove } from '@shared/utils';

@Directive({
  selector: '[elBaseNeoCommaMoving]',
})
export class CommaMovingDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    let inputValue = this.el.nativeElement.value;
    inputValue = thousandSeparatorRemove(inputValue);
    inputValue = thousandSeparatorAdd(inputValue);
    this.el.nativeElement.value = inputValue;
  }
}
