export enum ACCOUNT_REFERENCE_CATEGORIES {
  BANKS = 'BANKS',
  BANK_BRANCHES = 'BRANCHES',
}

export enum ACCOUNT_TYPES {
  SAVINGS = 'Savings',
  CURRENT = 'Current',
  DEPOSIT = 'Deposit',
}
