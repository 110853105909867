export const thousandSeparatorRemove = (
  value: number | string,
  currencyCode?: string
): number => {
  value = String(value);
  if (value.includes(`${currencyCode} `)) {
    const levels = value.split(`${currencyCode} `);
    value = String(levels[1]);
  }
  value = value.replace(/,/g, '');
  value = value.replace(/\s/g, '');

  return Number(value);
};

export const thousandSeparatorAdd = (
  num: string | number,
  currencyCode?: string
) => {
  const n = thousandSeparatorRemove(String(num)).toString();
  const p = n.indexOf('.');
  const prefix = currencyCode ? `${currencyCode} ` : '';

  return (
    prefix +
    n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) =>
      p < 0 || i < p ? `${m},` : m
    )
  );
};

export const thousandSeparatorAddWithTwoDecimal = (num: string | number) => {
  let n = thousandSeparatorRemove(String(num)).toString();
  n = n.replace(/[^\d.]/g, '');

  const [integerPart, decimalPart] = n.split('.');

  // Apply thousand separator to the integer part
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
  if (decimalPart !== undefined) {
    const formattedDecimalPart = decimalPart.slice(0, 2);
    n = `${formattedIntegerPart}.${formattedDecimalPart}`;
  } else {
    n = formattedIntegerPart;
  }

  return n;
};
