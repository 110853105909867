export enum INVOICE_STATUS {
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_PENDING_PARTIALLY_PAID = 'PAYMENT_PENDING_PARTIALLY_PAID',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  PAYMENT_PENDING_OVERDUE = 'PAYMENT_PENDING_OVERDUE',
}

//only for customer mobile app
export enum INVOICE_FILTER_TYPES {
  ALL = 'ALL',
  DUE = 'DUE',
  PAID = 'PAID',
}
