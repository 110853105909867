import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IConfigRadioChipButton } from './types';

@Component({
  selector: 'app-radio-chip-button',
  templateUrl: './radio-chip-button.component.html',
  styleUrls: ['./radio-chip-button.component.scss'],
})
/**
 * This component has to be used when only two radio buttons required.
 */
export class RadioChipButtonComponent {
  @Input() config: IConfigRadioChipButton = null;

  @Input() index = 0;

  @Output() onSelectAction = new EventEmitter<{
    data: IConfigRadioChipButton;
  }>();

  isFirstChipSelected = false;
  isSecondChipSelected = false;

  onToggle(e) {
    switch (e) {
      case 1:
        if (!this.config.firstChip.disable) {
          this.config.firstChip.selected = true;
          this.config.secondChip.selected = false;
        }

        break;
      case 2:
        if (!this.config.secondChip.disable) {
          this.config.firstChip.selected = false;
          this.config.secondChip.selected = true;
        }
        break;
      default:
        break;
    }
    this.config.valid = false;
    if (this.config.firstChip.selected || this.config.secondChip.selected) {
      this.config.valid = true;
    }
    this.onSelectAction.emit({ data: this.config });
  }
}
