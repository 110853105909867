<mat-card-title mat-dialog-title class="d-flex align-items-center mb-0">
  <mat-icon class="mr-2">{{ data.icon }}</mat-icon>
  {{ data.title }}
</mat-card-title>
<mat-card-subtitle class="popup-subtitle mb-1">{{
  data.subtitle
}}</mat-card-subtitle>

<mat-divider></mat-divider>

<mat-dialog-content class="mat-typography mt-3">
  <form [formGroup]="shareCustomerFormGroup">
    <p>{{ 'shared.share-to.share-via' | translate }}</p>
    <section class="share-to__share-via-wrapper">
      <mat-checkbox
        formControlName="isEmailSelected"
        class="share-to__selection"
        >{{ 'shared.share-to.email' | translate }}</mat-checkbox
      >
      <mat-checkbox
        formControlName="isSmsSelected"
        class="share-to__selection"
        >{{ 'shared.share-to.sms' | translate }}</mat-checkbox
      >
    </section>

    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>{{ 'shared.share-to.email-address' | translate }}</mat-label>
      <input
        matInput
        [placeholder]="'shared.share-to.email-address-placeholder' | translate"
        pattern="^([A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64})|([0-9]{10})$"
        required
        (input)="handleOnEmailChange($event)"
        [disabled]="!isEmailSelected"
        [value]="shareCustomerFormGroup.get('email').value"
      />
      <mat-error>{{
        'shared.share-to.error-messages.email-address' | translate
      }}</mat-error>
    </mat-form-field>
    <el-phone-number-input
      class="mb-3"
      style="width: 97%"
      (onChange)="handleOnChangePhoneNumber($event)"
      [required]="true"
      [disabled]="!isSmsSelected"
      [value]="shareCustomerFormGroup.get('phoneNumber').value"
    ></el-phone-number-input>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="d-flex justify-content-end">
    <button
      mat-flat-button
      [matTooltip]="'shared.share-to.action-buttons.close' | translate"
      [mat-dialog-close]="onDoneClicked()"
      (click)="onDoneClicked()"
    >
      {{ 'shared.share-to.action-buttons.close' | translate }}
    </button>
    <button
      class="mr-2"
      color="primary"
      [matTooltip]="'shared.share-to.action-buttons.send' | translate"
      mat-raised-button
      (click)="shareHandler()"
      [disabled]="onHandleValidation()"
    >
      {{ 'shared.share-to.action-buttons.send' | translate }}
    </button>
  </div>
</mat-dialog-actions>
