import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import {
  MedicalGroupProposalConfigurationLoadings,
  MedicalIndividualConfigurationLoadings,
  MotorFleetProposalConfigurationLoadings,
  PremiumCalculationData,
  PremiumCalSubInfo,
  ProposalConfigurationLoadings,
  TravelGroupConfigurationLoadings,
} from '@shared/interfaces';
import { thousandSeparatorAdd, thousandSeparatorRemove } from '@shared/utils';

import { FlowControlService } from '../../../../services/flow-control.service';
import { CurrenciesService } from '../../../setup/currencies/services/currencies.service';

@Component({
  selector: 'premium-displayer',
  templateUrl: './premium-displayer.component.html',
  styleUrls: ['./premium-displayer.component.scss'],
})
export class DisplayPremiumSectionComponent implements OnInit, OnDestroy {
  @Input() premiumCalculationData: PremiumCalculationData;
  @Input() premiumCalculationDataUpdates: Observable<PremiumCalculationData>;
  @Input() configurationLoadings: Observable<
    | ProposalConfigurationLoadings[]
    | MotorFleetProposalConfigurationLoadings[]
    | MedicalGroupProposalConfigurationLoadings[]
    | MedicalIndividualConfigurationLoadings[]
    | TravelGroupConfigurationLoadings[]
  >;
  @Input() baseCurrency: string;
  @Input() loading_list: KeyValue<string, string>[] = [];

  covers: PremiumCalSubInfo[] = [];
  fees_and_charges: PremiumCalSubInfo[] = [];
  premium: number;
  _premium: number;

  _configurationLoadings: ProposalConfigurationLoadings[] = [];

  displayLoadings: KeyValue<string, number>[] = [];

  thousandSeparatorAdd = thousandSeparatorAdd;

  subscriptions = new Subscription();

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private flowControlService: FlowControlService,
    private currenciesService: CurrenciesService
  ) {
    this.flowControlService.updateRouteInfo(
      this.router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.premiumCalculationDataUpdates?.subscribe((data) => {
        if (data) {
          this.premiumCalculationData = data;
          this.setData();
        }
      })
    );

    this.subscriptions.add(
      this.configurationLoadings?.subscribe((data) => {
        if (data?.length > 0) {
          this._configurationLoadings = data as ProposalConfigurationLoadings[];
          this.setData();
        }
      })
    );

    this.baseCurrency =
      this.baseCurrency || `(${this.currenciesService.baseCurrency.code})`;
    this.setData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setData() {
    this.covers = this.premiumCalculationData?.covers;
    this.fees_and_charges = this.premiumCalculationData?.fees_and_charges;
    this.premium = this.premiumCalculationData?.premium_amount;

    if (this.covers?.length > 0) {
      this.covers.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }

    this.setDataWithConfigs();
  }

  setDataWithConfigs() {
    this.displayLoadings = [];
    this._premium = this.premium;
    this._configurationLoadings?.forEach((a) => {
      const value = Number(
        (
          (thousandSeparatorRemove(a?.value) *
            thousandSeparatorRemove(this.premium)) /
          100
        )?.toFixed(2)
      );
      this.displayLoadings?.push({
        key: this.loading_list?.find((b) => b?.key === a?.loading)?.value,
        value,
      });
    });
    this.displayLoadings?.forEach((a) => {
      this.premium += a?.value;
    });
  }
}
