import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from '@sentry/angular';
import { registerLicense } from '@syncfusion/ej2-base';

// import { ENVIRONMENTS } from '@shared/interfaces';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

registerLicense('12345');
// if (environment.SENTRY_DSN) {
//   Sentry.init({
//     dsn: environment.SENTRY_DSN,
//     integrations: [
//       Sentry.captureConsoleIntegration(),
//       Sentry.httpClientIntegration(),
//       Sentry.extraErrorDataIntegration(),
//       Sentry.browserTracingIntegration(),
//       Sentry.browserProfilingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     beforeSend(event) {
//       if (
//         event.exception &&
//         event.event_id &&
//         environment.env !== ENVIRONMENTS.LOCAL
//       ) {
//         Sentry.showReportDialog({ eventId: event.event_id });
//       }
//       return event;
//     },
//     environment: environment.env,
//     release: environment.VERSION,
//     dist: environment.CORE_VERSION,
//     autoSessionTracking: true, // Enable auto session tracking
//     attachStacktrace: true,
//     debug: environment.isSentryDebugMode,
//     enabled: environment.env !== ENVIRONMENTS.LOCAL,
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// } else {
//   console.error('Sentry DSN not set');
// }

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
