export enum BONUSES_FIELDS {
  CODE = 'CODE',
  BONUS = 'BONUS',
  CLASS = 'CLASS',
}

export enum DISCOUNTS_FIELDS {
  CODE = 'CODE',
  DISCOUNT = 'DISCOUNT',
  CLASS = 'CLASS',
  START_DATE = 'START DATE',
  END_DATE = 'END_DATE',
  IS_ENABLE = 'ISENABLE',
}

export enum CHECKLISTS_FIELDS {
  TYPE = 'TYPE',
  SOURCE = 'SOURCE',
}

export enum COMMENCEMENT_OPTIONS_FIELDS {
  COMMENCEMENT_OPTION = 'COMMENCEMENT_OPTION',
}

export enum CLASSES_FIELDS {
  CODE = 'CODE',
  CLASS = 'CLASS',
  DESCRIPTION = 'DESCRIPTION',
  TYPE = 'TYPE',
}

export enum CLAUSES_FIELDS {
  CODE = 'CODE',
  CLAUSE = 'CLAUSE',
  DESCRIPTION = 'DESCRIPTION',
  EFFECTIVE_FROM = 'EFFECTIVE FROM',
  EFFECTIVE_TO = 'EFFECTIVE TO',
  IS_DEFAULT = 'ISDEFAULT',
  CLASS = 'CLASS',
}

export enum MODIFICATION_NOTES_FIELDS {
  CODE = 'CODE',
  MODIFICATION_NOTE = 'MODIFICATION NOTE',
  DESCRIPTION = 'DESCRIPTION',
}

export enum COMMISSIONS_FIELDS {
  CODE = 'CODE',
  COMMISSION = 'COMMISSION',
  CLASS = 'CLASS',
  IS_DEFAULT = 'ISDEFAULT',
}

export enum COVERS_FIELDS {
  CODE = 'CODE',
  COVER = 'COVER',
  CLAUSE = 'CLAUSE',
  CLAUSE_NUMBER = 'CLAUSE NUMBER',
  DESCRIPTION = 'DESCRIPTION',
  EFFECTIVE_FROM = 'EFFECTIVE FROM',
  EFFECTIVE_TO = 'EFFECTIVE TO',
  IS_DEFAULT = 'ISDEFAULT',
  CLASS = 'CLASS',
  FORMULAE = 'FORMULAE',
  SECTION = 'SECTION',
}

export enum FEES_AND_CHARGES_FIELDS {
  CODE = 'CODE',
  CLASS = 'CLASS',
  DESCRIPTION = 'DESCRIPTION',
  IS_DEFAULT = 'ISDEFAULT',
  IS_MANDATORY = 'ISMANDATORY',
  FEE_OR_CHARGE = 'FEE OR CHARGE',
  VALUE = 'VALUE',
  VALUE_TYPE = 'VALUE TYPE',
}

export enum NATURES_FIELDS {
  CODE = 'CODE',
  NATURE = 'NATURE',
  CLASS = 'CLASS',
}

export enum SUB_CLASSES_FIELDS {
  CODE = 'CODE',
  USAGE = 'USAGE',
  CLASS = 'CLASS',
  SUB_CLASS = 'SUB-CLASS',
}

export enum COVERAGES_TYPE_FIELDS {
  NAME = 'NAME',
}

export enum COVERAGES_FIELDS {
  CODE = 'CODE',
  TYPE = 'TYPE',
  CLASS = 'CLASS',
  COVERAGE = 'COVERAGE',
  COVERAGE_TYPE = 'COVERAGE TYPE',
  DESCRIPTION = 'DESCRIPTION',
}

export enum TYPES_FIELDS {
  CODE = 'CODE',
  TYPE = 'TYPE',
}

export enum USAGE_FIELDS {
  USAGE = 'USAGE',
}

export enum REFUNDABLE_METHODS_FIELDS {
  METHOD = 'METHOD',
}

export enum SUBLIMITS_FIELDS {
  CODE = 'CODE',
  SUBLIMIT = 'SUBLIMIT',
  EFFECTIVE_FROM = 'EFFECTIVE FROM',
  EFFECTIVE_TO = 'EFFECTIVE TO',
  IS_DEFAULT = 'ISDEFAULT',
  PARENT_COVER = 'PARENT COVER',
}

export enum CITIES_FIELDS {
  CITY = 'CITY',
  DISTRICT = 'DISTRICT',
  POSTAL_CODE = 'POSTAL CODE',
}

export enum DISTRICTS_FIELDS {
  DISTRICT = 'DISTRICT',
  PROVINCE = 'PROVINCE',
}

export enum COUNTRIES_FIELDS {
  CODE = 'CODE',
  COUNTRY = 'COUNTRY',
}

export enum PROVINCES_FIELDS {
  CODE = 'CODE',
  PROVINCE = 'PROVINCE',
  COUNTRY = 'COUNTRY',
}

export enum FORMULAE_FIELDS {
  CODE = 'CODE',
  TITLE = 'TITLE',
  FORMULA = 'FORMULA',
}

export enum DOCUMENT_TYPES_FIELDS {
  TYPE = 'DOCUMENT NAME',
}
export enum COMPANY_NAME_FIELDS {
  CODE = 'CODE',
  NAME = 'NAME',
}

export enum FORM_TYPES_FIELDS {
  TYPE = 'TYPE',
}

export enum TRIGGERS_FIELDS {
  TRIGGER = 'TRIGGER',
  EMAIL_TITLE = 'TITLE (EMAIL)',
  EMAIL_DESCRIPTION = 'DESCRIPTION (EMAIL)',
  SMS_TITLE = 'TITLE (SMS)',
  SMS_DESCRIPTION = 'DESCRIPTION (SMS)',
  CLASS = 'CLASS',
}

export enum VEHICLE_MAKES_FIELDS {
  CODE = 'CODE',
  NAME = 'MAKE',
}

export enum VEHICLE_MODELS_FIELDS {
  CODE = 'CODE',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
}

export enum VEHICLE_TYPES_FIELDS {
  CODE = 'CODE',
  TYPE = 'TYPE',
}

export enum TRAVEL_PLAN_FIELDS {
  CODE = 'CODE',
  PLAN = 'PLAN',
}

export enum TRAVEL_SECTION_FIELDS {
  SECTION = 'SECTION',
  CODE = 'CODE',
  IS_DEFAULT = 'ISDEFAULT',
}
