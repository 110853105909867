import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@shared/constants';
import { CommonResponseDTO } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShareToService {
  constructor(private http: HttpClient) {}

  shareToCustomer(body: any): Observable<CommonResponseDTO<any>> {
    return this.http.post<CommonResponseDTO<any>>(`${URLS.SHARE}/`, {
      ...body,
    });
  }
}
