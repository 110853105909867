<app-size-detector></app-size-detector>

<ng-container *ngFor="let field of fields; let i = index">
  <div class="row no-gutters mt-4">
    <div class="col-11">
      <!-- First row (without T) -->
      <div class="row no-gutters">
        <!-- Field Name -->
        <div class="pr-1 col-sm">
          <mat-form-field class="single-field" appearance="fill">
            <mat-label>{{
              'references.add-new-reference-category.field-name-label'
                | translate
            }}</mat-label>
            <input
              matInput
              required
              [formControl]="field.name"
              placeholder="{{
                'references.add-new-reference-category.field-placeholder'
                  | translate
              }}"
              [readonly]="disabled || field.mandatory"
            />
            <mat-error>{{
              'references.add-new-reference-category.field-error-hint'
                | translate
            }}</mat-error>
          </mat-form-field>
        </div>

        <!-- Field Type -->
        <div class="pl-1 col-sm">
          <mat-form-field appearance="fill" class="single-field pr-sm-1">
            <mat-label>{{
              'references.add-new-reference-category.field-type-label'
                | translate
            }}</mat-label>
            <mat-select
              placeholder="{{
                'references.add-new-reference-category.field-type-placeholder'
                  | translate
              }}"
              required
              [formControl]="field.type"
              [disabled]="field.disableField || disabled || field.mandatory"
              (selectionChange)="onFieldTypeChange(i)"
            >
              <mat-option
                *ngFor="let type of fieldTypesOptions"
                [value]="type.value"
                (click)="field.selectedCategory = null"
              >
                {{ type.key | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        class="row no-gutters first-short-width-row"
        *ngIf="collectKeyFromUser"
      >
        <!-- Horizontal T lines -->
        <div class="col-1 mr-n2" *ngIf="isDividersVisible">
          <mat-divider class="horizontal-divider"></mat-divider>
          <mat-divider
            vertical
            class="vertical-divider"
            [ngClass]="{
              'vertical-divider--small':
                !field.additionalFormFields?.length && !hasDefaultValue(field)
            }"
          >
          </mat-divider>
        </div>

        <!-- Field Key -->
        <div class="col pl-sm-3 pl-xs-4" [ngStyle]="dynamicStyles">
          <mat-form-field class="single-field" appearance="fill">
            <mat-label>{{
              'references.add-new-reference-category.field-key-label'
                | translate
            }}</mat-label>
            <input
              matInput
              required
              [formControl]="field.uniqueID"
              placeholder="{{
                'references.add-new-reference-category.field-key-placeholder'
                  | translate
              }}"
              [disabled]="disabled || field.mandatory"
            />
            <mat-error>{{
              'references.add-new-reference-category.field-key-error-hint'
                | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div
        class="row no-gutters"
        [ngClass]="{ 'first-short-width-row': !collectKeyFromUser }"
      >
        <!-- Horizontal T lines -->
        <div
          *ngIf="
            (isBoolean(i) ||
              isReference(i) ||
              isCheckbox(i) ||
              isRadioButton(i)) &&
            isDividersVisible
          "
          class="col-1 mr-n2"
        >
          <mat-divider class="horizontal-divider"></mat-divider>
          <mat-divider
            vertical
            class="vertical-divider"
            [ngClass]="{
              'vertical-divider--small':
                !field.additionalFormFields?.length &&
                !hasDefaultValue(field) &&
                !isCheckbox(i) &&
                !isRadioButton(i),
              'vertical-divider--long': isReference(i) && screenSize.size < 3
            }"
          >
          </mat-divider>
          <mat-divider
            *ngIf="screenSize.size < 3"
            class="horizontal-divider horizontal-divider--additional"
          ></mat-divider>
        </div>

        <!-- Boolean type field labels -->
        <div
          *ngIf="isBoolean(i)"
          class="col pl-sm-3 pl-xs-4"
          [ngStyle]="dynamicStyles"
        >
          <div class="row no-gutters">
            <!-- True value label -->
            <div class="col-sm">
              <mat-form-field appearance="fill" class="single-field pr-sm-1">
                <mat-label>{{
                  'configurable-fields.boolean.true-label' | translate
                }}</mat-label>
                <input
                  matInput
                  required
                  [formControl]="field.booleanLabels?.trueLabel"
                  [placeholder]="
                    'configurable-fields.boolean.true-label-placeholder'
                      | translate
                  "
                  [readonly]="disabled || field.mandatory"
                  (ngModelChange)="onBooleanLabelsPristine()"
                />
                <mat-error
                  *ngIf="field.booleanLabels?.trueLabel?.hasError('required')"
                  >{{
                    'configurable-fields.boolean.true-label-required'
                      | translate
                  }}</mat-error
                >
              </mat-form-field>
            </div>

            <!-- False value label -->
            <div class="col-sm">
              <mat-form-field appearance="fill" class="single-field pl-sm-1">
                <mat-label>{{
                  'configurable-fields.boolean.false-label' | translate
                }}</mat-label>
                <input
                  matInput
                  required
                  [formControl]="field.booleanLabels?.falseLabel"
                  [placeholder]="
                    'configurable-fields.boolean.false-label-placeholder'
                      | translate
                  "
                  [readonly]="disabled || field.mandatory"
                  (ngModelChange)="onBooleanLabelsPristine()"
                />
                <mat-error
                  *ngIf="field.booleanLabels?.falseLabel?.hasError('required')"
                  >{{
                    'configurable-fields.boolean.false-label-required'
                      | translate
                  }}</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- Reference Categories -->
        <div
          *ngIf="isReference(i)"
          class="col pl-sm-3 pl-xs-4"
          [ngStyle]="dynamicStyles"
        >
          <div class="row no-gutters">
            <!-- Reference Field Type -->

            <div class="col-lg-6 reference-config-field">
              <el-autocomplete
                [selected]="field.reference_category.value"
                [options]="referenceCategories"
                [multiSelect]="false"
                [hideTheAllOption]="true"
                [label]="
                  'references.add-new-reference-category.field-type-label'
                    | translate
                "
                appearance="fill"
                [required]="true"
                [disabled]="disabled"
                (itemsSelected)="onReferenceCategorySelected(i, $event)"
              >
              </el-autocomplete>
            </div>

            <!-- Reference Field Value -->
            <div class="col-lg-6 reference-config-field">
              <el-autocomplete
                [selected]="field.reference_field.value"
                [options]="field.referenceFieldsForSelectedCategory ?? []"
                [multiSelect]="false"
                [hideTheAllOption]="true"
                [label]="
                  'references.add-new-reference-category.reference-field-label'
                    | translate
                "
                appearance="fill"
                [required]="true"
                [disabled]="!field.selectedCategory || disabled"
                (itemsSelected)="setReferenceValues(i, $event)"
              >
              </el-autocomplete>
            </div>
          </div>
        </div>

        <!-- Add Checkbox Field Button-->
        <div
          *ngIf="isCheckbox(i) || isRadioButton(i)"
          class="col pl-sm-3 pl-xs-4"
          [ngStyle]="dynamicStyles"
        >
          <button
            mat-stroked-button
            type="button"
            class="mt-1 mb-4"
            (click)="addNewRadioCheckboxOption(i)"
          >
            +&nbsp;Add Option
          </button>
        </div>
      </div>

      <!-- Default value (for fields that aren't reference type) -->
      <div *ngIf="hasDefaultValue(field)" class="row no-gutters">
        <!-- Horizontal T lines -->
        <div class="col-1 mr-n2" *ngIf="isDividersVisible">
          <mat-divider class="horizontal-divider"> </mat-divider>
          <mat-divider
            vertical
            class="vertical-divider"
            [ngClass]="{
              'vertical-divider--small':
                !field.additionalFormFields?.length && !isAdvanced(field)
            }"
          >
          </mat-divider>
        </div>

        <div
          class="default-value col pl-sm-3 pl-xs-4"
          [ngStyle]="dynamicStyles"
        >
          <app-display-single-configurable-field
            [fieldItem]="field.displayTheDefaultFieldItem"
            [disabled]="disabled"
            [fields]="fields"
            [disableUniqueFields]="true"
          ></app-display-single-configurable-field>
        </div>
      </div>

      <!-- Checkbox Fields -->
      <ng-container *ngIf="isCheckbox(i)">
        <div
          *ngFor="
            let checkboxOption of field.checkboxOptions ?? [];
            let idx = index
          "
          class="row no-gutters"
        >
          <div class="col-sm-1 mr-n2" *ngIf="isDividersVisible">
            <mat-divider class="horizontal-divider"></mat-divider>
            <mat-divider vertical class="vertical-divider"></mat-divider>
          </div>

          <div class="col pl-sm-3 pl-xs-4" [ngStyle]="dynamicStyles">
            <div class="row no-gutters">
              <div class="col-sm-4">
                <mat-form-field appearance="fill" class="single-field pr-sm-1">
                  <mat-label>Display Value</mat-label>
                  <input
                    type="text"
                    matInput
                    required
                    [formControl]="checkboxOption.displayValue"
                  />
                </mat-form-field>
              </div>
              <div class="col-sm-4">
                <mat-form-field appearance="fill" class="single-field pl-sm-1">
                  <mat-label>Value</mat-label>
                  <input
                    type="text"
                    matInput
                    required
                    [formControl]="checkboxOption.value"
                  />
                  <mat-error *ngIf="checkboxOption.value.hasError('required')">
                    {{ 'configurable-fields.value-required-error' | translate }}
                  </mat-error>
                  <mat-error *ngIf="checkboxOption.value.hasError('notUnique')">
                    {{ 'configurable-fields.value-unique-error' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-2">
                <mat-checkbox
                  class="m-3"
                  (change)="checkboxBehaviour($event, i, idx)"
                  [checked]="checkboxOption.checked"
                  [disabled]="
                    checkboxOption.displayValue.invalid ||
                    checkboxOption.value.invalid
                  "
                >
                  Default
                </mat-checkbox>
              </div>
              <div class="col-sm">
                <button
                  class="delete-btn"
                  mat-icon-button
                  type="button"
                  matTooltip="Delete"
                  [disabled]="(field.checkboxOptions ?? []).length < 2"
                  (click)="onClickDeleteCheckboxField(i, idx)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- Radio Button Fields -->
      <ng-container *ngIf="isRadioButton(i)">
        <div
          *ngFor="
            let radioButtonOption of field.radioButtonOptions ?? [];
            let idx = index
          "
          class="row no-gutters"
        >
          <div class="col-sm-1 mr-n2" *ngIf="isDividersVisible">
            <mat-divider class="horizontal-divider"></mat-divider>
            <mat-divider vertical class="vertical-divider"></mat-divider>
          </div>

          <div class="col pl-sm-3 pl-xs-4" [ngStyle]="dynamicStyles">
            <div class="row no-gutters">
              <div class="col-sm-4">
                <mat-form-field appearance="fill" class="single-field pr-sm-1">
                  <mat-label>Display Value</mat-label>
                  <input
                    type="text"
                    matInput
                    required
                    [formControl]="radioButtonOption.displayValue"
                  />
                </mat-form-field>
              </div>
              <div class="col-sm-4">
                <mat-form-field appearance="fill" class="single-field pl-sm-1">
                  <mat-label>Value</mat-label>
                  <input
                    type="text"
                    matInput
                    required
                    [formControl]="radioButtonOption.value"
                  />
                  <mat-error
                    *ngIf="radioButtonOption.value.hasError('required')"
                  >
                    {{ 'configurable-fields.value-required-error' | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="radioButtonOption.value.hasError('notUnique')"
                  >
                    {{ 'configurable-fields.value-unique-error' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-2">
                <mat-radio-button
                  class="m-3"
                  [checked]="radioButtonOption.checked"
                  [name]="'default' + i"
                  (change)="radioButtonBehaviour(i, idx)"
                  [disabled]="
                    radioButtonOption.displayValue.invalid ||
                    radioButtonOption.value.invalid
                  "
                >
                  Default
                </mat-radio-button>
              </div>
              <div class="col-sm">
                <button
                  class="delete-btn"
                  mat-icon-button
                  type="button"
                  matTooltip="Delete"
                  [disabled]="(field.radioButtonOptions ?? []).length < 2"
                  (click)="onClickDeleteRadioButtonField(i, idx)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="row no-gutters" *ngIf="isAdvanced(field)">
        <!-- Horizontal T lines -->
        <div class="col-1 mr-n2" *ngIf="isDividersVisible">
          <mat-divider class="horizontal-divider"></mat-divider>
          <mat-divider vertical class="vertical-divider"></mat-divider>
        </div>

        <!-- Field Layout -->
        <div class="col pl-sm-3 pl-xs-4" [ngStyle]="dynamicStyles">
          <mat-form-field appearance="fill" class="single-field">
            <mat-label>{{
              'references.add-new-reference-category.layout-label' | translate
            }}</mat-label>
            <mat-select
              placeholder="{{
                'references.add-new-reference-category.layout-placeholder'
                  | translate
              }}"
              required
              [formControl]="field.layout"
            >
              <mat-option
                *ngFor="let type of fieldWidthOptions"
                [value]="type.value"
              >
                {{ type.key | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Regex field section -->
      <div class="row no-gutters mb-3" *ngIf="isText(i) && isAdvanced(field)">
        <!-- Horizontal T lines -->
        <div class="col-1 mr-n2" *ngIf="isDividersVisible">
          <mat-divider class="horizontal-divider"></mat-divider>
          <mat-divider
            vertical
            class="vertical-divider vertical-divider--long"
          ></mat-divider>
        </div>

        <div class="col pl-sm-3 pl-xs-4" [ngStyle]="dynamicStyles">
          <div class="row no-gutters mb-2">
            <div class="col-8">
              <!-- Regular Expression -->
              <mat-form-field
                appearance="fill"
                id="regex-expression-field"
                class="single-field"
              >
                <mat-label>Regular Expressions</mat-label>
                <input
                  type="text"
                  matInput
                  name="regex{(i)}"
                  (change)="field.regexField?.regexType?.setValue('custom')"
                  [formControl]="field.regexField?.regex"
                  placeholder="[A-Za-z0-9]"
                />
                <mat-hint
                  >Do not add the beginning and ending slashes of the regular
                  expression</mat-hint
                >
                <mat-error
                  *ngIf="field.regexField?.regex.hasError('invalidRegex')"
                  >Regular expression is invalid</mat-error
                >
                <mat-divider
                  *ngIf="isDividersVisible"
                  matSuffix
                  [vertical]="true"
                  class="form-suffix-divider"
                ></mat-divider>
              </mat-form-field>
            </div>
            <div class="col-4">
              <!-- Regular Expression Type -->
              <mat-form-field
                id="regex-type-field"
                matSuffix
                appearance="fill"
                class="single-field"
              >
                <mat-label>Regex Type</mat-label>
                <mat-select
                  [formControl]="field.regexField?.regexType"
                  (selectionChange)="onRegexTypeChange($event.value, i)"
                >
                  <mat-option value="email">Email</mat-option>
                  <mat-option value="custom">Custom</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Additional fields -->
      <div
        class="row no-gutters"
        *ngIf="
          field?.additionalFormFields && field?.additionalFormFields?.length > 0
        "
      >
        <ng-container
          *ngFor="
            let additionalField of field?.additionalFormFields;
            let j = index
          "
        >
          <mat-form-field
            width="50%"
            appearance="fill"
            class="ref-fields-form-field"
          >
            <mat-label>{{ additionalField.label }}</mat-label>
            <input
              *ngIf="additionalField.type === 'TEXT'"
              type="text"
              matInput
              [name]="additionalField.key"
              [value]="additionalField?.field?.value || ''"
              [required]="additionalField.isRequired"
              [formControl]="additionalField.field"
              [placeholder]="additionalField.placeholder"
              [disabled]="additionalField.isDisabled"
            />

            <mat-select
              *ngIf="additionalField.type === 'DROPDOWN'"
              [placeholder]="additionalField.placeholder"
              [value]="additionalField?.field?.value || ''"
              [required]="additionalField.isRequired"
              [formControl]="additionalField.field"
              [placeholder]="additionalField.placeholder"
              [disabled]="additionalField.isDisabled"
              (selectionChange)="
                onAdditionalFieldSelectionChanged($event.value, i, j)
              "
            >
              <mat-option
                *ngFor="let option of additionalField.options"
                [value]="option.value"
              >
                {{ option.value | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error>{{ additionalField.errorMessage }}</mat-error>
          </mat-form-field>
        </ng-container>
      </div>

      <!-- Add Criteria section -->
      <div class="row no-gutters" *ngIf="isAdvanced(field)">
        <!-- Horizontal T lines -->
        <div class="col-1 mr-2" *ngIf="isDividersVisible">
          <mat-divider class="horizontal-divider"></mat-divider>
          <mat-divider
            vertical
            class="vertical-divider"
            [ngClass]="{
              'vertical-divider--small': !field.criteria?.length
            }"
          >
          </mat-divider>
        </div>

        <div class="col-sm mt-1 mb-4">
          <button type="button" mat-stroked-button (click)="addNewCriteria(i)">
            +&nbsp;Add Criteria
          </button>
        </div>
      </div>

      <div class="row no-gutters" *ngIf="isAdvanced(field)">
        <div class="col">
          <ng-container
            *ngFor="let criteria of field.criteria ?? []; let idx = index"
          >
            <div class="row no-gutters">
              <!-- Horizontal T lines -->
              <div class="col-sm-1 mr-n2" *ngIf="isDividersVisible">
                <mat-divider class="horizontal-divider"></mat-divider>
                <mat-divider
                  vertical
                  class="vertical-divider vertical-divider--small"
                  [ngClass]="{
                    'vertical-divider--long':
                      idx < (field.criteria?.length ?? 0) - 1
                  }"
                >
                </mat-divider>
              </div>

              <div class="col-sm">
                <div class="row no-gutters">
                  <div class="col-11">
                    <div class="row no-gutters">
                      <!-- Criteria Type -->
                      <div class="col-6">
                        <mat-form-field
                          appearance="fill"
                          class="single-field pr-sm-1"
                        >
                          <mat-label>Criteria Type</mat-label>
                          <mat-select
                            placeholder="Criteria Type"
                            required
                            [formControl]="criteria.criteria"
                          >
                            <mat-option
                              *ngFor="let type of getCriteriaTypes(i)"
                              [value]="type.value"
                            >
                              {{ type.key | titlecase }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <!-- Dependent Field -->
                      <div class="col-6">
                        <mat-form-field
                          appearance="fill"
                          class="single-field pl-sm-1"
                        >
                          <mat-label>Dependent Field</mat-label>
                          <mat-select
                            *ngIf="isReference(i)"
                            placeholder="Dependent Field"
                            required
                            [formControl]="criteria.dependent_field"
                            (selectionChange)="dependentFieldChange()"
                          >
                            <mat-option
                              *ngFor="
                                let dependent_field of getDependentFields(i);
                                let dependent_field_index = index
                              "
                              [value]="dependent_field.uniqueID.value"
                            >
                              {{ dependent_field.name.value }}
                            </mat-option>
                          </mat-select>

                          <mat-select
                            *ngIf="!isReference(i)"
                            placeholder="Dependent Field"
                            required
                            [formControl]="criteria.dependent_field"
                            (selectionChange)="
                              dependentFieldChange();
                              criteria.compare_value.setValue('')
                            "
                          >
                            <mat-option
                              *ngFor="
                                let _field of getDependentFields(i);
                                let depFieldIndex = index
                              "
                              [value]="_field.uniqueID.value"
                            >
                              {{ _field.name.value }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <button
                      type="button"
                      class="delete-btn"
                      mat-icon-button
                      (click)="onClickDeleteCriteria(i, idx)"
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>

                <div class="row no-gutters">
                  <!-- L shape dividers -->
                  <div class="col-sm-1 mr-n2" *ngIf="isDividersVisible">
                    <mat-divider class="horizontal-divider"></mat-divider>
                    <mat-divider
                      vertical
                      class="vertical-divider vertical-divider--small"
                    >
                    </mat-divider>
                  </div>

                  <div
                    [ngStyle]="{
                      width:
                        criteria.compare_with?.value === 'DIRECT'
                          ? '30%'
                          : '45%'
                    }"
                  >
                    <!-- Compare With -->
                    <mat-form-field
                      appearance="fill"
                      class="single-field--fill pr-sm-1"
                    >
                      <mat-label>Compare With</mat-label>
                      <mat-select
                        placeholder=""
                        required
                        [formControl]="criteria.compare_with"
                      >
                        <mat-option
                          *ngFor="let compareType of getCriteriaCompareTypes(i)"
                          [value]="compareType.value"
                        >
                          {{ compareType.key | titlecase }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    style="width: 30%"
                    *ngIf="criteria.compare_with?.value === 'DIRECT'"
                  >
                    <!-- Comparator -->
                    <mat-form-field
                      appearance="fill"
                      class="single-field--fill pr-sm-1"
                    >
                      <mat-label>Comparator</mat-label>
                      <mat-select
                        placeholder="Comparator"
                        required
                        [formControl]="criteria.comparator"
                      >
                        <mat-option
                          *ngFor="
                            let comparator of getComparisonTypes(
                              criteria.dependent_field.value
                            )
                          "
                          [value]="comparator.value"
                        >
                          {{ comparator.key }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- Compare Value -->
                  <div
                    [ngStyle]="{
                      width:
                        criteria.compare_with?.value === 'DIRECT'
                          ? '30%'
                          : '45%'
                    }"
                  >
                    <!-- empty compare value field -->
                    <ng-container
                      *ngIf="
                        !getDependentFieldType(criteria.dependent_field.value)
                      "
                    >
                      <mat-form-field
                        appearance="fill"
                        class="single-field--fill"
                      >
                        <mat-label>Compare Value</mat-label>
                        <mat-select
                          placeholder="Compare Value"
                          required
                          [formControl]="criteria.compare_value"
                          [disabled]="true"
                        >
                          <mat-option></mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.REFERENCES
                      "
                    >
                      <mat-form-field
                        *ngIf="isReference(i)"
                        appearance="fill"
                        class="single-field--fill"
                      >
                        <mat-label>Compare Value</mat-label>
                        <mat-select
                          placeholder="Compare Value"
                          required
                          [formControl]="criteria.compare_value"
                        >
                          <mat-option
                            *ngFor="
                              let comparable_field of field.selectedCategory
                                ?.fields
                            "
                            [value]="comparable_field._id"
                          >
                            {{ comparable_field?.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field
                        *ngIf="!isReference(i)"
                        appearance="fill"
                        class="single-field--fill"
                      >
                        <mat-label>Compare Value</mat-label>
                        <!-- get dependent field's references -->
                        <mat-select
                          placeholder="Compare Value"
                          required
                          [formControl]="criteria.compare_value"
                        >
                          <mat-option
                            *ngFor="
                              let referenceValues of getDependentReferenceFieldValues(
                                criteria.dependent_field.value
                              )
                            "
                            [value]="referenceValues.value"
                          >
                            {{ referenceValues.displayValue }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.NUMBER
                      "
                    >
                      <mat-form-field
                        appearance="fill"
                        class="single-field--fill"
                      >
                        <mat-label>Compare Value</mat-label>

                        <input
                          type="number"
                          matInput
                          [formControl]="criteria.compare_value"
                        />
                      </mat-form-field>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.CHECKBOX ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.RADIO_BUTTON
                      "
                    >
                      <!-- Because both radio and checkbox options should be shown as dropdown elements here -->
                      <!-- So, cannot use the display-configurable-field normal flow here -->
                      <mat-form-field
                        appearance="fill"
                        class="single-field--fill"
                      >
                        <mat-label>Compare Value</mat-label>
                        <mat-select
                          placeholder="Compare Value"
                          required
                          [formControl]="criteria.compare_value"
                        >
                          <mat-option
                            *ngFor="
                              let comparable_field of getComparableFields(
                                i,
                                idx
                              )
                            "
                            [value]="comparable_field.value?.value"
                          >
                            {{ comparable_field.displayValue?.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.TEXT ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.CURRENCY ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.DATE ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.DATE_TIME ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.ADDRESS ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.NAME ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.CONTACT_NUMBER ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.EMAIL ||
                        getDependentFieldType(
                          criteria.dependent_field.value
                        ) === fieldTypes.BOOLEAN
                      "
                    >
                      <app-display-single-configurable-field
                        id="display-config-field"
                        [fieldItem]="
                          getCompareValueField(
                            criteria.compare_value,
                            criteria.dependent_field.value
                          )
                        "
                      ></app-display-single-configurable-field>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Delete Field button -->
    <div class="col-1">
      <button
        class="pt-1"
        mat-icon-button
        (click)="removeForm(i)"
        type="button"
        matTooltip="Delete"
        [disabled]="field.disableField || disabled || field.mandatory"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <!-- Checkboxes -->
  <div class="col-11">
    <div class="row d-flex justify-content-lg-between pl-lg-2 pr-lg-2">
      <ng-container *ngFor="let checkBox of field?.checkboxes; let j = index">
        <div class="col-12 col-lg-auto p-0">
          <mat-checkbox
            *ngIf="checkBox && checkBox?.key"
            (change)="onCheckClicked(i, j)"
            [checked]="checkBox.isChecked"
            class="checkbox"
            [disabled]="
              disabled ||
              isCheckboxDisabled(i, j) ||
              checkBox.disabled ||
              field.disableField
            "
            >{{ checkBox.key }}
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<div *ngIf="fields && fields.length === 0">
  <app-no-results-found></app-no-results-found>
</div>
