export enum PAYMENTS_STATUS {
  IN_QUEUE = 'In Queue',
  PENDING_APPROVAL = 'Pending Approval',
  OPEN = 'Open',
  PAID = 'Paid',
  PAYMENT_PROCESSING = 'Payment Processing',
  MIXED = 'Mixed',
  PENDING_APPROVAL_REPRINT = 'Pending Approval(Re-print)',
  PENDING_APPROVAL_CANCELLATION = 'Pending Approval(Cancellation)',
  CANCELLED = 'Cancelled',
}

export enum PAYMENTS_TYPES {
  CLAIM = 'Claim',
  ENDORSEMENT = 'Endorsement',
}

export enum PAYMENTS_CLAIM_TYPES {
  MOTOR = 'Motor',
  MEDICAL = 'Medical',
  TRAVEL = 'Travel',
  FIRE = 'Fire',
  MARINE = 'Marine',
}

export enum PAYMENTS_METHOD_TYPES {
  BANK_TRANSFER = 'Bank Transfer',
  CHEQUE = 'Cheque',
  MIXED = 'Mixed',
}
