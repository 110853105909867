import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'turnaroundTime',
})
export class TurnaroundTimePipe implements PipeTransform {
  transform(_createdDate: any): string {
    const createdDate = new Date(_createdDate);
    const now = new Date();
    const timeDifference = now.getTime() - createdDate.getTime();

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    const dayString = days === 1 ? `${days} day` : `${days} days`;
    const hourString = hours === 1 ? `${hours} hour` : `${hours} hours`;
    const minuteString =
      minutes === 1 ? `${minutes} minute` : `${minutes} minutes`;

    if (days > 0) {
      return `${dayString} ${hourString} ${minuteString}`;
    } else {
      return `${hourString} ${minuteString}`;
    }
  }
}
