export enum BRANCH_REFERENCE_CATEGORIES {
  BRANCH_PROVINCES = 'BRANCH PROVINCES',
  BRANCH_DISTRICTS = 'BRANCH DISTRICTS',
  BRANCH_REGIONS = 'BRANCH REGIONS',
  BRANCH_ZONES = 'BRANCH ZONES',
}
export enum ADD_MANAGER_STATUS {
  UNASSIGNED = 'UNASSIGNED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}
