export enum CONTROL_REFERENCE_CATEGORIES {
  COLLECTION = 'COLLECTION CATEGORIES',
  PAYMENT = 'PAYMENT CATEGORIES',
  INSTRUMENT_TYPES = 'INSTRUMENT TYPES',
}

export enum CONTROL_TYPES {
  BANK = 'Bank',
  RECEIPT = 'Receipt',
}

export enum TRANSACTION_TYPES {
  COLLECTION = 'Collection',
  PAYMENT = 'Payment',
}
