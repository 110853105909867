export enum PRIVILEGE_ATTACHMENT_TYPE {
  ROLE = 'ROLE',
  RULE = 'RULE',
}

export enum PRIVILEGE_NATURE_TYPE {
  PRODUCT = 'PRODUCT',
}

export enum PRODUCT_PRIVILEGE_TYPE {
  SUM_INSURED_LIMIT = 'SUM INSURED LIMIT',
  PREMIUM_AUTHORIZATION_LIMIT = 'PREMIUM AUTHORIZATION LIMIT',
  CREDIT_AUTHORIZATION_LIMIT = 'CREDIT AUTHORIZATION LIMIT',
  POLICY_CANCELATION_LIMIT = 'POLICY CANCELLATION LIMIT',
  ENDORSEMENT_AUTHORIZATION_LIMIT = 'ENDORSEMENT AUTHORIZATION LIMIT',
}
