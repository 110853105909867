<div style="display: flex; align-items: center">
  <div style="display: flex; align-items: center">
    <mat-icon *ngIf="values.icon">{{ values.icon }}</mat-icon>
  </div>
  <div style="padding-left: 4px">
    <h2 style="display: inline" mat-dialog-title>
      {{ 'shared.confirmation-popup.title' | translate }}
    </h2>
  </div>
</div>

<br />

<mat-dialog-content
  class="mat-typography"
  style="padding-left: 4px; margin: 0; overflow: hidden"
>
  <div>
    {{ data?.description || '' }}
  </div>
  <div class="mt-3" *ngIf="data.need_authentication">
    {{ 'shared.confirmation-popup.auth-confirm' | translate }}
  </div>
  <form *ngIf="data.need_authentication" [formGroup]="authentication">
    <mat-form-field
      color="primary"
      class="mt-3"
      style="width: 100%"
      appearance="fill"
    >
      <mat-label>{{
        'shared.confirmation-popup.password' | translate
      }}</mat-label>
      <input
        data-testid="confirmation_popup_input_password"
        #password
        id="password"
        type="password"
        [type]="hide ? 'password' : 'text'"
        matInput
        [placeholder]="'shared.confirmation-popup.placeholder-pwd' | translate"
        formControlName="password"
        required
        ng-model="password"
        autocomplete="off"
        readonly
        (focus)="password.readOnly = false"
        (blur)="password.readOnly = true"
      />
      <button
        data-testid="confirmation_popup_btn_show_password"
        type="button"
        *ngIf="hide"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        matTooltip="{{ 'shared.confirmation-popup.pwd-hidden' | translate }}"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>
      <button
        data-testid="confirmation_popup_btn_hide_password"
        type="button"
        *ngIf="!hide"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        matTooltip="{{ 'shared.confirmation-popup.pwd-visible' | translate }}"
      >
        <mat-icon>visibility</mat-icon>
      </button>
      <mat-error *ngIf="authentication.controls.password.hasError('required')">
        <p>
          {{
            'shared.confirmation-popup.validation.password-required' | translate
          }}
        </p>
      </mat-error>
      <mat-error *ngIf="authentication.controls.password.hasError('incorrect')">
        <p>
          {{
            'shared.confirmation-popup.validation.password-invalid' | translate
          }}
        </p>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<br />

<h6 style="padding-left: 4px; margin: 0">
  {{ 'shared.confirmation-popup.proceed' | translate }}
</h6>

<mat-dialog-actions align="end">
  <button
    data-testid="confirmation_popup_btn_proceed_option_no"
    mat-button
    [mat-dialog-close]="false"
    matTooltip="{{ 'shared.confirmation-popup.no' | translate }}"
  >
    {{ 'shared.common.no' | translate }}
  </button>
  <button
    data-testid="confirmation_popup_btn_proceed_option_yes"
    mat-raised-button
    color="primary"
    [disabled]="
      data.need_authentication &&
      (authentication.controls.password.invalid ||
        authentication.controls.password.pristine)
    "
    (click)="onClickYes()"
    cdkFocusInitial
    matTooltip="{{ 'shared.confirmation-popup.yes' | translate }}"
  >
    {{ 'shared.common.yes' | translate }}
  </button>
</mat-dialog-actions>
