import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { ENDPOINTS, PASSWORD_RESET_STRATEGY, URLS } from '@shared/constants';
import {
  CheckUsernameRequestDTO,
  CheckUsernameResponseDTO,
  ImpersonateLoginRequestDTO,
  LoginRequestDTO,
  LoginResponseDTO,
  LoginV2RequestDTO,
  TokenResponseDTO,
  TokenVerificationDTO,
  ValidateCodeRequestDTO,
} from '@shared/dtos';
import {
  CommonResponseDTO,
  IConfigurableFieldValue,
  IIdentityResponse,
  IJwtPayload,
  IPhoneNumber,
} from '@shared/interfaces';
import { generateURL } from '@shared/utils';

import { INTERCEPTOR_SKIPPERS } from '../../../constants';

import { JwtService } from './jwt.service';
import { LoggedUserService } from './logged-user.service';

interface CheckUserDTO {
  password: string;
}
export interface RegisterRequestDTO {
  salutation: string;
  email?: string;
  phone_number?: IPhoneNumber;
  first_name: string;
  last_name: string;
}

export interface RegisterOtpVerifyRequestDTO {
  reference: string;
  otp: string;
  phone_number?: IPhoneNumber;
  email?: string;
}

export interface RegisterOtpVerifyResponseDTO {
  isVerified: boolean;
  token: string;
}

interface SetPasswordRequestDTO {
  token: string;
  password: string;
  username?: string;
  internal_fields?: IConfigurableFieldValue[];
  external_fields?: IConfigurableFieldValue[];
}

interface ResetPasswordRequestDTO {
  current_password: string;
  new_password: string;
}

export interface ForgotPasswordPatchRequestDTO {
  email?: string;
  phone_number?: string;
  username?: string;
}

interface ForgotPasswordPatchResponseDTO {
  sent: boolean;
  method: PASSWORD_RESET_STRATEGY;
  identity_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public tempLoginData: {
    current_password?: string;
    access_token: string;
    refresh_token: string;
  };
  public access_token: string;
  public access_token_decoded: IJwtPayload;
  private timeout = null;
  private refresh_token: string;

  private impersonatorData = undefined;

  public token = new BehaviorSubject<string>(null);

  constructor(
    private http: HttpClient,
    private loggedUserService: LoggedUserService,
    private jwtService: JwtService,
    private _localStorage: LocalStorage,
    private router: Router
  ) {
    // inter-tab synchronization
    window.addEventListener('storage', (e: StorageEvent) => {
      if (e.key.toString() === 'refresh_token') {
        this.refresh_token = e.newValue;

        const publicRoutes = ['preview/storage', '/auth/verify'];
        const isPublicRoute = publicRoutes.some((route) =>
          this.router.url.includes(route)
        );
        // if there was a previous refresh_token
        // but there is no new refresh_token
        // the user logged out from a different tab
        // logout from this tab as well
        if (e.oldValue && !e.newValue) {
          this.logout().then(() => {
            if (!isPublicRoute) {
              router.navigate(['/app/auth/login'], {
                queryParams: {
                  returnUrl: this.router.url,
                },
              });
            }
          });
        }

        // if there was no previous refresh_token
        // but there is a new refresh_token
        // the user logged in from a different tab
        // login from this tab as well
        if (!e.oldValue && e.newValue) {
          this.refreshToken(e.newValue).subscribe(() => {
            if (!isPublicRoute) {
              router.navigate(['/app']);
            }
          });
        }
      }
    });

    this._localStorage.getItem('impersonate_data').subscribe((data) => {
      if (data) {
        this.impersonatorData = data;
      }
    });
  }

  public autoLogin(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.refresh_token = localStorage.getItem('refresh_token');
      if (this.refresh_token) {
        this.refreshToken(this.refresh_token).subscribe({
          next: (res) => {
            resolve(res.data?.access_token || '');
          },
          error: () => {
            resolve('');
          },
        });
      } else {
        reject('');
      }
    });
  }

  public refreshToken(
    token: string,
    beforeImpersonation = undefined // the person who impersonated
  ): Observable<CommonResponseDTO<TokenResponseDTO>> {
    if (beforeImpersonation) {
      this.access_token = beforeImpersonation.access_token;
      this.token.next(this.access_token);
      this.access_token_decoded = beforeImpersonation.access_token_decoded;
      this.refresh_token = beforeImpersonation.refresh_token;
      this._localStorage.clear().subscribe();
    }
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_REFRESH_TOKEN });

    return this.http
      .patch<CommonResponseDTO<TokenResponseDTO>>(url, {
        token,
      })
      .pipe(
        tap((res) => {
          this.saveTokens(res.data?.access_token, res.data?.refresh_token);
        })
      );
  }

  private startRefreshing(expires_on: number): void {
    const expiryMoment = moment.unix(expires_on);
    const currentMoment = moment.utc();
    const duration = moment.duration(expiryMoment.diff(currentMoment));

    if (expires_on <= 0) return;
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.refreshToken(this.refresh_token).subscribe({
        error: () => {
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
        },
      });
    }, duration.asMilliseconds() / 2);
  }

  public login(
    requestData: LoginRequestDTO
  ): Observable<CommonResponseDTO<LoginResponseDTO>> {
    const headers = new HttpHeaders().set(
      INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR,
      'valid'
    );

    const url = generateURL({ endpoint: ENDPOINTS.AUTH_LOGIN });

    return this.http
      .post<CommonResponseDTO<LoginResponseDTO>>(url, requestData, {
        headers,
      })
      .pipe(
        tap((res) => {
          if (res.data?.is_password_reset_required) {
            this.tempLoginData = {
              current_password: requestData.password,
              access_token: res.data?.access_token,
              refresh_token: res.data?.refresh_token,
            };

            return;
          }

          this.saveTokens(res.data?.access_token, res.data?.refresh_token);
        })
      );
  }

  public codeGrantLogin(
    requestData: LoginV2RequestDTO
  ): Observable<CommonResponseDTO<LoginResponseDTO>> {
    const headers = new HttpHeaders().set(
      INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR,
      'valid'
    );

    const url = generateURL({ endpoint: ENDPOINTS.AUTH_LOGIN, version: 2 });

    return this.http
      .post<CommonResponseDTO<LoginResponseDTO>>(url, requestData, {
        headers,
      })
      .pipe(
        tap((res) => {
          if (res.data?.is_password_reset_required) {
            this.tempLoginData = {
              current_password: requestData.password,
              access_token: res.data?.access_token,
              refresh_token: res.data?.refresh_token,
            };

            return;
          }

          this.saveTokens(res.data?.access_token, res.data?.refresh_token);
        })
      );
  }

  public saveTokens(accessToken: string, refreshToken?: string): void {
    const [error, tokenData] = this.jwtService.decodeJwt(accessToken);
    if (!error) {
      this.access_token = accessToken || '';
      this.token.next(this.access_token);
      this.access_token_decoded = tokenData;
      const refresh_token = refreshToken;
      const expires_on = tokenData.exp || 0;
      localStorage.setItem('refresh_token', refresh_token);
      this.refresh_token = refresh_token;
      this.loggedUserService.getLatestUserProfile();
      this.startRefreshing(expires_on);
    }
  }

  public validateCode(
    requestData: ValidateCodeRequestDTO
  ): Observable<CommonResponseDTO<LoginResponseDTO>> {
    const headers = new HttpHeaders().set(
      INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR,
      'valid'
    );

    const url = generateURL({ endpoint: ENDPOINTS.AUTH_CODE });

    return this.http
      .post<CommonResponseDTO<LoginResponseDTO>>(url, requestData, {
        headers,
      })
      .pipe(
        tap((res) => {
          if (res.data?.is_password_reset_required) {
            this.tempLoginData = {
              access_token: res.data?.access_token,
              refresh_token: res.data?.refresh_token,
            };

            return;
          }

          this.saveTokens(res.data?.access_token, res.data?.refresh_token);
        })
      );
  }

  public checkUser(
    requestData: CheckUserDTO
  ): Observable<CommonResponseDTO<CheckUserDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_CHECK_USER });

    return this.http.post<CommonResponseDTO<CheckUserDTO>>(url, requestData);
  }

  public checkUsername(
    requestData: CheckUsernameRequestDTO
  ): Observable<CommonResponseDTO<CheckUsernameResponseDTO>> {
    const headers = new HttpHeaders().set(
      INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR,
      'valid'
    );

    const url = generateURL({
      endpoint: ENDPOINTS.AUTH_USERNAME,
    });

    return this.http.post<CommonResponseDTO<CheckUsernameResponseDTO>>(
      url,
      requestData,
      { headers }
    );
  }

  public logout(): Promise<void> {
    return new Promise((resolve) => {
      const requestData = { token: this.refresh_token };

      const clearData = () => {
        localStorage.removeItem('refresh_token');
        this.refresh_token = '';
        this.access_token = '';
        this.token.next(this.access_token);
        this.access_token_decoded = null;
        this.loggedUserService.clearUser();
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        resolve();
      };

      const url = generateURL({ endpoint: ENDPOINTS.AUTH_LOGOUT });

      if (!this.refresh_token) {
        return clearData();
      } else {
        this.http
          .put(url, requestData)
          .subscribe({ next: () => clearData(), error: () => clearData() });
      }
    });
  }

  public impersonateLogin(
    requestData: ImpersonateLoginRequestDTO
  ): Observable<CommonResponseDTO<LoginResponseDTO>> {
    const headers = new HttpHeaders().set(
      INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR,
      'valid'
    );

    this.impersonatorData = {
      access_token: this.access_token,
      access_token_decoded: this.access_token_decoded,
      refresh_token: this.refresh_token,
    };

    this._localStorage
      .setItem('impersonate_data', this.impersonatorData)
      .subscribe();

    const url = generateURL({ endpoint: ENDPOINTS.AUTH_LOGIN_IMPERSONATE });

    return this.http
      .post<CommonResponseDTO<LoginResponseDTO>>(url, requestData, {
        headers,
      })
      .pipe(
        tap((res) => {
          this.saveTokens(res.data?.access_token, res.data?.refresh_token);
        })
      );
  }

  public forgotPassword(
    data: ForgotPasswordPatchRequestDTO
  ): Observable<CommonResponseDTO<ForgotPasswordPatchResponseDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_FORGOT_PASSWORD });
    const headers = new HttpHeaders().set(
      INTERCEPTOR_SKIPPERS.ERROR_INTERCEPTOR,
      'valid'
    );

    return this.http.patch<CommonResponseDTO<ForgotPasswordPatchResponseDTO>>(
      url,
      data,
      { headers }
    );
  }

  public validateVerificationToken(data: {
    token: string;
  }): Observable<CommonResponseDTO<TokenVerificationDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_VALIDATE_TOKEN });

    return this.http.post<CommonResponseDTO<TokenVerificationDTO>>(url, data);
  }

  public resetPassword(
    data: SetPasswordRequestDTO
  ): Observable<CommonResponseDTO<void>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_FORGOT_PASSWORD });

    return this.http.post<CommonResponseDTO<void>>(url, data);
  }

  public register(
    data: RegisterRequestDTO
  ): Observable<CommonResponseDTO<IIdentityResponse>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_REGISTER });

    return this.http.post<CommonResponseDTO<IIdentityResponse>>(url, data);
  }

  public verifyAccount(
    data: SetPasswordRequestDTO
  ): Observable<CommonResponseDTO<LoginResponseDTO>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_VERIFY });

    return this.http.post<CommonResponseDTO<LoginResponseDTO>>(url, data).pipe(
      tap((res) => {
        this.saveTokens(res.data?.access_token, res.data?.refresh_token);
      })
    );
  }

  public resetLoggedInUserPassword(
    data: Partial<ResetPasswordRequestDTO>,
    accessToken?: string
  ): Observable<CommonResponseDTO<void>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_RESET_PASSWORD });

    return this.http.post<CommonResponseDTO<void>>(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  verifyOTP(
    body: RegisterOtpVerifyRequestDTO
  ): Observable<CommonResponseDTO<RegisterOtpVerifyResponseDTO>> {
    const url = `${URLS.AUTH}/otp/validate`;
    return this.http.post<CommonResponseDTO<RegisterOtpVerifyResponseDTO>>(
      url,
      body
    );
  }

  public resendOTPCode(data: {
    reference: string;
    email?: string;
    phone_number?: IPhoneNumber;
  }): Observable<CommonResponseDTO<{ sent: boolean }>> {
    const url = generateURL({ endpoint: ENDPOINTS.AUTH_SEND_OTP });
    return this.http.post<CommonResponseDTO<{ sent: boolean }>>(url, data);
  }

  public redirectToLogin() {
    const loginRoute = new URL(window.location.href);
    switch (true) {
      // verification url should pass through regardless of logged in status
      case loginRoute.pathname.includes('app/auth/verify') ||
        loginRoute.pathname.includes('app/checkout'):
        this.router.navigate([loginRoute]);
        break;

      // if not authenticated to the system, should not be redirected to login page
      case loginRoute.pathname.includes('/app/auth/login') ||
        loginRoute.pathname.includes('/app/auth/recover') ||
        loginRoute.pathname.includes('/app/auth/external') ||
        loginRoute.pathname.includes('/app/preview'):
        break;

      // if another error caught in auto login, should fallback to login page
      default:
        this.router.navigate(['/app/auth/login'], {
          queryParams: {
            returnUrl: `${loginRoute.pathname + loginRoute.search}`,
          },
        });
        break;
    }
  }
}
