export enum OCCUPATION_FIELDS {
  OCCUPATION_CODE = 'CODE',
  OCCUPATION = 'OCCUPATION',
}

export enum RELATIONSHIP_FIELDS {
  RELATIONSHIP_CODE = 'CODE',
  RELATIONSHIP = 'RELATIONSHIP',
}

export enum NATURE_OF_LOSS_FIELDS {
  LOSS_CODE = 'CODE',
  NATURE_OF_LOSS = 'NATURE OF LOSS',
}

export enum VEHICLE_MAKE_FIELDS {
  MAKE_CODE = 'CODE',
  MAKE_NAME = 'NAME',
}

export enum VEHICLE_MODEL_FIELDS {
  MODEL_CODE = 'CODE',
  MODEL_NAME = 'MODEL',
  MAKE_NAME = 'MAKE',
}

export enum INSURANCE_COMPANIES_FIELDS {
  INSURANCE_COMPANY_CODE = 'CODE',
  INSURANCE_COMPANY_NAME = 'NAME',
}

export enum BUSINESS_ENTERPRISES_FIELDS {
  BUSINESS_ENTERPRISE_CODE = 'CODE',
  BUSINESS_ENTERPRISE_NAME = 'NAME',
}

export enum SPARE_PARTS_FIELDS {
  NAME = 'NAME',
  CODE = 'CODE',
}

export enum INSURANCE_COMPANY_FIELDS {
  NAME = 'NAME',
  CODE = 'CODE',
}
export enum TRV_COUNTRIES_FIELDS {
  CODE = 'CODE',
  NAME = 'NAME',
}
export enum TRV_LOCATION_FIELDS {
  CODE = 'CODE',
  NAME = 'NAME',
  COUNTRY = 'COUNTRY',
}
