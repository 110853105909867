import {
  IConfigurableFieldValueResponse,
  IReferenceResponse,
} from '@shared/interfaces';

// COMMON FUNC FOR BOMS REF GETTER
export const extractValueFromKey = (
  references: IReferenceResponse[],
  key: string,
  fieldType: string
) => {
  const val =
    references?.find((ref) => ref._id?.toString() === key?.toString())
      ?.reference || [];

  return (
    val
      ?.find((ref) => ref?.field?.name?.toUpperCase()?.trim() === fieldType)
      ?.value?.toString() || ''
  );
};

export const getListValueFromReference = (
  reference: IConfigurableFieldValueResponse[],
  type: string
) => {
  return (
    reference
      ?.find(
        (ref) => ref?.field?.name?.toUpperCase()?.trim() === type.toUpperCase()
      )
      ?.value?.toString() || ''
  );
};
