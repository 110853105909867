export enum MAP_TYPES {
  DIGITAL = 'Digital',
  MANUAL = 'Manual',
}

export enum MAP_REFERENCE_CATEGORIES {
  REGIONS = 'REGIONS',
  CLASSES = 'CLASSES',
}

export enum RISK_TYPES {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}
