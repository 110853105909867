<label>{{ config.label }} {{ config.isRequired ? '*' : '' }}</label>
<mat-chip-list>
  <mat-chip
    color="primary"
    (click)="onToggle(1)"
    [selected]="config.firstChip.selected"
    [disabled]="config.firstChip.disable"
  >
    {{ config.firstChip.value }}
  </mat-chip>
  <mat-chip
    color="primary"
    (click)="onToggle(2)"
    [selected]="config.secondChip.selected"
    [disabled]="config.secondChip.disable"
  >
    {{ config.secondChip.value }}
  </mat-chip>
</mat-chip-list>
