// Always use plural values
// API_KEYS: api_keys <--- CORRECT
// API_KEYS: api_key <--- INCORRECT

export enum DB_COLLECTION_NAMES {
  // A
  ACCOUNTS = 'accounts',
  AGREEMENTS = 'agreements',
  ARTICLES = 'articles',
  ARTICLE_CATEGORIES = 'article_categories',
  ARTICLE_SUB_CATEGORIES = 'article_sub_categories',
  ARTICLE_TAGS = 'article_tags',
  ACCESS_CREDENTIALS = 'access_credentials',
  API_KEYS = 'api_keys',
  ASSESSORS = 'assessors',
  // B
  BLACKLIST = 'blacklist',
  BRANCHES = 'branches',
  AUTH_REQUESTS = 'auth_requests',
  AUTH_CODES = 'auth_codes',
  // C
  CONNECTED_APPS = 'connected_apps',
  CONNECTIONS = 'connections',
  CHART_OF_ACCOUNTS = 'chart_of_accounts',
  CONTROLS = 'controls',
  COVER_NOTES = 'cover_notes',
  CUSTOMERS = 'customers',
  CLAIMS = 'claims',
  CLAIMS_LOGS = 'claim_logs',
  CLAIMS_ASSESSMENTS = 'claim_assessments',
  CLAIMS_FEEDBACKS = 'claim_feedbacks',
  // D
  DATA_POINTS = 'data_points',
  DOUBLE_ENTRIES = 'double_entries',

  // D
  DELETED_CLIENT_SECRETS = 'deleted_client_secrets',
  DELETED_API_KEYS = 'deleted_api_keys',
  // E
  ERROR_LOGS = 'error_logs',
  ENTRIES = 'entries',
  ENDORSEMENTS = 'endorsements',
  EXCHANGE_RATES = 'exchange_rates',
  // G
  GATEWAYS = 'gateways',
  // I
  IDENTITIES = 'identities',
  INCIDENTS = 'incidents',
  INVOICES = 'invoices',
  INTEGRATION_ENDPOINTS = 'integration_endpoints',
  INTEGRATION_MAPPINGS = 'integration_mappings',
  // L
  LOGS = 'logs',
  // M
  MESSAGES = 'messages',
  MESSAGES_RESPONSES = 'messages_responses',
  MODULES = 'modules',
  MAPS = 'maps',
  // N
  NOTIFICATIONS = 'notifications',
  NOTIFICATION_IDENTITIES = 'notification_identities',
  NOTICES = 'notices',
  // O
  OTP = 'otp',
  // P
  PARTNERS = 'partners',
  PERMISSIONS = 'permissions',
  PRIVILEGES = 'privileges',
  POLICIES = 'policies',
  POLICIES_MOBILE = 'policies-mobile',
  POST_IT_NOTES = 'post-it-notes',
  PRODUCTS = 'products',
  PERSONALIZATIONS = 'personalizations',
  PAYMENTS = 'payments',
  PREDEFINED_REFERENCES_COUNTRIES = 'predefined_reference_countries',
  PREDEFINED_REFERENCES_STATES = 'predefined_reference_states',
  PREDEFINED_REFERENCES_DISTRICTS = 'predefined_reference_districts',
  PREDEFINED_REFERENCES_CITIES = 'predefined_reference_cities',
  PUSH_NOTIFICATION_DEVICE = 'push_notification_devices',
  // Q
  QUICK_ACTIONS = 'quick_actions',
  // R
  REFERENCE_CATEGORIES = 'reference_categories',
  REFERENCES = 'references',
  REFRESH_TOKENS = 'refresh_tokens',
  REINSURERS = 'reinsurers',
  PACKAGES = 'packages',
  ROLES = 'roles',
  REMINDER = 'reminders',
  RECEIPTS = 'receipts',
  // S
  SETUP_BACKUP_SCHEDULES = 'setup_backup_schedules',
  SETUP_CLIENT = 'setup_client',
  SETUP_COLOR_PALLETS = 'setup_color_palettes',
  SETUP_GENERAL = 'setup_general',
  SETUP_IAM = 'setup_iams',
  SETUP_IDENTITY = 'setup_identity',
  SETUP_LANGUAGES = 'setup_languages',
  SETUP_CURRENCIES = 'setup_currencies',
  SETUP_MESSAGE_GROUPS = 'setup_message_groups',
  SETUP_MESSAGE_RULES = 'setup_message_rules',
  SETUP_MESSAGE_CONFIG = 'setup_message_config',
  SETUP_NOTIFICATION_CONFIG = 'setup_notification_configs',
  SETUP_PWA = 'setup_progressive_web_apps',
  SETUP_REFERENCE_MAPPINGS = 'setup_reference_mappings',
  SETUP_RELEASES = 'setup_releases',
  SETUP_EXTERNAL_SYSTEMS = 'setup_external_systems',
  SETUP_SECURITY = 'setup_securities',
  SETUP_SECURITY_WEB_HOOKS = 'setup_security_web_hooks',
  SETUP_STORAGE = 'setup_storages',
  SETUP_SUPPORT = 'setup_supports',
  SETUP_SYSTEM_VARIABLES = 'setup_system_variables',
  SETUP_MESSAGE = 'setup_messages',

  STORAGE = 'storages',
  STORAGE_ENTITY = 'storage_entities',
  STORAGE_ENTITY_INTERNAL_SHARE = 'storage_entities_internal_share',
  STORAGE_ENTITY_EXTERNAL_SHARE = 'storage_entities_external_share',
  STORAGE_ENTITY_EXTERNAL_SHARE_GENERATED_OTP = 'storage_entities_external_share_generated_otp',

  // T
  TEMPLATES = 'templates',
  TASK = 'tasks',
  TRANSACTIONS = 'transactions',

  // U
  USERS = 'users',
  // V
  VERIFICATION_TOKENS = 'verification_tokens',

  // W
  WIDGETS = 'widgets',
  WIDGET_LAYOUT = 'widget_layouts',
  WORKFLOWS = 'workflows',
}
