import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { MODULES, PERMISSION_ACTIONS } from '@shared/constants';
import { getModulePermission } from '@shared/utils';

import {
  LoggedUserService,
  UserInfoResponseDTO,
} from '../../modules/auth/services';
import { ModulesService } from '../../modules/modules/services/modules.service';
import {
  IAM_STATUS,
  IamConfigService,
} from '../../modules/setup/iam-config/services/iam-config.service';
import {
  DMS_STATUS,
  StorageSetupService,
} from '../../modules/setup/storage/services/storage-setup.service';
import { FlowControlService } from '../../services/flow-control.service';
import {
  IShowModule,
  PermissionFiltrationService,
} from '../../services/permission-filtration.service';

export interface IModuleIconData {
  routerLink: string;
  tooltip: string;
  image: string;
  alt: string;
  title: string;
  searchKey: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  moduleFilterValue = '';
  filteredModuleCards = [];
  loggedUserInfo: UserInfoResponseDTO;
  noticeCount = 0;
  isNoticesModuleEnabled = false;
  isNoticesModuleReadPermission = false;
  private subscriptions = new Subscription();

  isInternalIAM = true;
  isInternalStorage = true;

  isEmptyModules = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    route: ActivatedRoute,
    flowControlService: FlowControlService,
    private permissionFiltrationService: PermissionFiltrationService,
    private loggedUserService: LoggedUserService,
    private modulesService: ModulesService,
    private storageConfigService: StorageSetupService,
    private iamConfigService: IamConfigService
  ) {
    flowControlService.updateRouteInfo(
      router.url.split('?')[0],
      route.snapshot.data
    );
  }

  ngOnInit() {
    this.initializeLoggedUserData();
    this.initializeModulesData();
    this.router.navigate([], {
      queryParams: {
        returnUrl: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeLoggedUserData(): void {
    this.loggedUserService.dataStore.subscribe((data) => {
      this.loggedUserInfo = data;
      this.isNoticesModuleReadPermission =
        this.permissionFiltrationService.validatePermissions({
          permissions: getModulePermission(MODULES.NOTICES, [
            PERMISSION_ACTIONS.READ,
          ]),
        });
      this.initializeIamConfig();
      this.initializeConnectedStorageConfig();
      this.getAllModules();
    });
  }

  initializeModulesData(): void {
    this.subscriptions.add(
      this.modulesService
        .listenToAvailability([MODULES.NOTICES])
        .subscribe((change) => {
          this.isNoticesModuleEnabled = change;
        })
    );
  }

  private initializeIamConfig(): void {
    this.subscriptions.add(
      this.iamConfigService.dataStore.subscribe((data) => {
        const iamStatus = data?.iam?.status;
        if (
          iamStatus === IAM_STATUS.INTERNAL ||
          iamStatus === IAM_STATUS.INTERNAL_DISCONNECTED ||
          iamStatus === IAM_STATUS.INTERNAL_PENDING
        ) {
          this.isInternalIAM = true;
        } else {
          this.isInternalIAM = false;
        }
      })
    );
  }

  private initializeConnectedStorageConfig(): void {
    this.subscriptions.add(
      this.storageConfigService.dataStore.subscribe((data) => {
        const storageStatus = data?.storage?.status;
        if (
          storageStatus === DMS_STATUS.INTERNAL ||
          storageStatus === DMS_STATUS.INTERNAL_DISCONNECTED ||
          storageStatus === DMS_STATUS.INTERNAL_PENDING ||
          storageStatus === DMS_STATUS.EXTERNAL_PENDING
        ) {
          this.isInternalStorage = true;
        } else {
          this.isInternalStorage = false;
        }
      })
    );
  }
  getAllModules() {
    this.translate
      .get([
        'pages.home.setup-module-tooltip',
        'pages.home.setup',
        'pages.home.knowledgebase-module-tooltip',
        'pages.home.knowledgebase',
        'pages.home.logs-module-tooltip',
        'pages.home.logs',
        'pages.home.permissions-module-tooltip',
        'pages.home.permissions',
        'pages.home.identities-module-tooltip',
        'pages.home.identities',
        'pages.home.modules',
        'pages.home.modules-module-tooltip',
        'pages.home.messages',
        'pages.home.messages-module-tooltip',
        'pages.home.apps',
        'pages.home.apps-module-tooltip',
        'pages.home.storages',
        'pages.home.storages-module-tooltip',
        'pages.home.connections',
        'pages.home.connections-module-tooltip',
        'pages.home.roles',
        'pages.home.roles-module-tooltip',
        'pages.home.drives',
        'pages.home.drives-module-tooltip',
        'pages.home.recycleBin',
        'pages.home.recycleBin-module-tooltip',
        'pages.home.notices',
        'pages.home.notices-module-tooltip',
        'pages.home.agreements-face',
        'pages.home.agreements-module-tooltip',
        'pages.home.incidents-face',
        'pages.home.incidents-module-tooltip',
        'pages.home.reinsurers-face',
        'pages.home.reinsurers-module-tooltip',
        'pages.home.partners-face',
        'pages.home.partners-module-tooltip',
        'pages.home.products-face',
        'pages.home.products-module-tooltip',
        'pages.home.packages-face',
        'pages.home.packages-module-tooltip',
        'pages.home.workflows-face',
        'pages.home.workflows-module-tooltip',
        'pages.home.maps-face',
        'pages.home.maps-module-tooltip',
        'pages.home.branches-face',
        'pages.home.branches-module-tooltip',
        'pages.home.accounts-face',
        'pages.home.accounts-module-tooltip',
        'pages.home.chart-of-accounts-face',
        'pages.home.chart-of-accounts-module-tooltip',
        'pages.home.entries-face',
        'pages.home.entries-module-tooltip',
        'pages.home.invoices-face',
        'pages.home.invoices-module-tooltip',
        'pages.home.controls-face',
        'pages.home.controls-module-tooltip',
        'pages.home.policies-face',
        'pages.home.policies-module-tooltip',
        'pages.home.claims-face',
        'pages.home.claims-module-tooltip',
        'pages.home.assessors-face',
        'pages.home.assessors-module-tooltip',
        'pages.home.assessments-face',
        'pages.home.assessments-module-tooltip',
        'pages.home.payments-face',
        'pages.home.payments-module-tooltip',
        'pages.home.cover-notes-face',
        'pages.home.cover-notes-module-tooltip',
        'pages.home.customers-face',
        'pages.home.customers-module-tooltip',
        'pages.home.receipts-face',
        'pages.home.receipts-module-tooltip',
        'pages.home.endorsements-face',
        'pages.home.endorsements-module-tooltip',
        'pages.home.blacklist-face',
        'pages.home.blacklist-module-tooltip',
        'pages.home.gateways-face',
        'pages.home.gateways-module-tooltip',
        'pages.home.transactions-face',
        'pages.home.transactions-module-tooltip',
        'pages.home.reports-face',
        'pages.home.reports-module-tooltip',
        'pages.home.exchange-rates-face',
        'pages.home.exchange-rates-module-tooltip',
      ])
      .subscribe((translations) => {
        const allModuleCards: IShowModule[] = [];
        this.modulesService.dataStore.subscribe((data) => {
          if (data) {
            data
              .filter((item) => !item?.is_hidden)
              .forEach((item) => {
                switch (item.key) {
                  case MODULES.MODULES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/modules',
                        tooltip:
                          translations['pages.home.modules-module-tooltip'],
                        image: 'assets/modules/svg/Modules.svg',
                        alt: 'modules',
                        title: translations['pages.home.modules'],
                        searchKey: 'modules',
                      },
                      allowedPermissions: getModulePermission(MODULES.MODULES, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.APPS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/apps',
                        tooltip: translations['pages.home.apps-module-tooltip'],
                        image: 'assets/modules/svg/Apps.svg',
                        alt: 'apps',
                        title: translations['pages.home.apps'],
                        searchKey: 'apps',
                      },
                      allowedPermissions: getModulePermission(MODULES.APPS, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.AUTHORIZATION_PERMISSIONS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/authorization/permissions',
                        tooltip:
                          translations['pages.home.permissions-module-tooltip'],
                        image: 'assets/modules/svg/Permissions.svg',
                        alt: 'permissions',
                        title: translations['pages.home.permissions'],
                        searchKey: 'permissions',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.AUTHORIZATION_PERMISSIONS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.AUTHORIZATION_ROLES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/authorization/roles',
                        tooltip:
                          translations['pages.home.roles-module-tooltip'],
                        image: 'assets/modules/svg/Roles.svg',
                        alt: 'roles',
                        title: translations['pages.home.roles'],
                        searchKey: 'roles',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.AUTHORIZATION_ROLES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.IDENTITIES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/identities',
                        tooltip:
                          translations['pages.home.identities-module-tooltip'],
                        image: 'assets/modules/svg/Identity.svg',
                        alt: 'identities',
                        title: translations['pages.home.identities'],
                        searchKey: 'identities',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.IDENTITIES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.CONNECTIONS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/connections',
                        tooltip:
                          translations['pages.home.connections-module-tooltip'],
                        image: 'assets/modules/svg/Connections.svg',
                        alt: 'connections',
                        title: translations['pages.home.connections'],
                        searchKey: 'connections',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.CONNECTIONS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.MESSAGES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/messages',
                        tooltip:
                          translations['pages.home.messages-module-tooltip'],
                        image: 'assets/modules/svg/Messages.svg',
                        alt: 'messages',
                        title: translations['pages.home.messages'],
                        searchKey: 'messages',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.MESSAGES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.STORAGE_DRIVE:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/storage/drives',
                        tooltip:
                          translations['pages.home.drives-module-tooltip'],
                        image: 'assets/modules/svg/Drive.svg',
                        alt: 'drives',
                        title: translations['pages.home.drives'],
                        searchKey: 'drives',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.STORAGE_DRIVE,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.STORAGE_RECYCLE_BIN:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/storage/trash',
                        tooltip:
                          translations['pages.home.recycleBin-module-tooltip'],
                        image: 'assets/modules/svg/Recycle Bin.svg',
                        alt: 'recycle bin',
                        title: translations['pages.home.recycleBin'],
                        searchKey: 'trash',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.STORAGE_RECYCLE_BIN,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.NOTICES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/notices',
                        tooltip:
                          translations['pages.home.notices-module-tooltip'],
                        image: 'assets/modules/svg/News.svg',
                        alt: 'notices',
                        title: translations['pages.home.notices'],
                        searchKey: 'notices',
                      },
                      allowedPermissions: getModulePermission(MODULES.NOTICES, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.KNOWLEDGEBASE:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/knowledgebase',
                        tooltip:
                          translations[
                            'pages.home.knowledgebase-module-tooltip'
                          ],
                        image: 'assets/modules/svg/Knowledgebase.svg',
                        alt: 'knowledgebase',
                        title: translations['pages.home.knowledgebase'],
                        searchKey: 'knowledgebase articles',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.KNOWLEDGEBASE,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.LOGS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/logs',
                        tooltip: translations['pages.home.logs-module-tooltip'],
                        image: 'assets/modules/svg/Logs.svg',
                        alt: 'logs',
                        title: translations['pages.home.logs'],
                        searchKey: 'logs',
                      },
                      allowedPermissions: getModulePermission(MODULES.LOGS, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.SETUP:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/setup/general',
                        tooltip:
                          translations['pages.home.setup-module-tooltip'],
                        image: 'assets/modules/svg/Setup.svg',
                        alt: 'setup',
                        title: translations['pages.home.setup'],
                        searchKey: 'setup',
                      },
                      allowedPermissions: getModulePermission(MODULES.SETUP, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  // BOMS
                  case MODULES.REINSURERS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'reinsurers',
                        title: translations['pages.home.reinsurers-face'],
                        routerLink: '/app/reinsurers',
                        tooltip:
                          translations['pages.home.reinsurers-module-tooltip'],
                        image: 'assets/modules/svgs/Reinsurers.svg',
                        alt: 'reinsurers',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.REINSURERS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.PARTNERS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'partners',
                        title: translations['pages.home.partners-face'],
                        routerLink: '/app/partners',
                        tooltip:
                          translations['pages.home.partners-module-tooltip'],
                        image: 'assets/modules/svgs/Partners.svg',
                        alt: 'partners',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.PARTNERS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.AGREEMENTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'agreements',
                        title: translations['pages.home.agreements-face'],
                        image: 'assets/modules/svgs/Agreements.svg',
                        alt: 'agreements',
                        tooltip:
                          translations['pages.home.agreements-module-tooltip'],
                        routerLink: '/app/agreements',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.AGREEMENTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.INCIDENTS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/incidents',
                        tooltip:
                          translations['pages.home.incidents-module-tooltip'],
                        image: 'assets/modules/svgs/Incidents.svg',
                        alt: 'incidents',
                        title: translations['pages.home.incidents-face'],
                        searchKey: 'incidents',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.INCIDENTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.MAPS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'maps',
                        title: translations['pages.home.maps-face'],
                        tooltip: translations['pages.home.maps-module-tooltip'],
                        routerLink: '/app/maps',
                        image: 'assets/modules/svgs/Maps.svg',
                        alt: 'maps',
                      },
                      allowedPermissions: getModulePermission(MODULES.MAPS, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.BRANCHES:
                    allModuleCards.push({
                      data: {
                        searchKey: 'branches',
                        title: translations['pages.home.branches-face'],
                        routerLink: '/app/branches',
                        tooltip:
                          translations['pages.home.branches-module-tooltip'],
                        image: 'assets/modules/svgs/Branches.svg',
                        alt: 'branches',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.BRANCHES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.CONTROLS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'controls',
                        title: translations['pages.home.controls-face'],
                        routerLink: '/app/controls',
                        tooltip:
                          translations['pages.home.controls-module-tooltip'],
                        image: 'assets/modules/svgs/Controls.svg',
                        alt: 'controls',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.CONTROLS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.WORKFLOWS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'workflows',
                        title: translations['pages.home.workflows-face'],
                        tooltip:
                          translations['pages.home.workflows-module-tooltip'],
                        routerLink: '/app/workflows',
                        image: 'assets/modules/svgs/Workflow.svg',
                        alt: 'workflows',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.WORKFLOWS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.PRODUCTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'products',
                        title: translations['pages.home.products-face'],
                        tooltip:
                          translations['pages.home.products-module-tooltip'],
                        routerLink: '/app/products',
                        image: 'assets/modules/svgs/Products.svg',
                        alt: 'products',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.PRODUCTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.PACKAGES:
                    allModuleCards.push({
                      data: {
                        searchKey: 'packages',
                        title: translations['pages.home.packages-face'],
                        tooltip:
                          translations['pages.home.packages-module-tooltip'],
                        routerLink: '/app/packages',
                        image: 'assets/modules/svgs/Products.svg',
                        alt: 'packages',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.PACKAGES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  //--------Business--------
                  case MODULES.CUSTOMERS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/customers',
                        tooltip:
                          translations['pages.home.customers-module-tooltip'],
                        image: 'assets/modules/svgs/Customers.svg',
                        alt: 'leads',
                        title: translations['pages.home.customers-face'],
                        searchKey: 'leads',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.CUSTOMERS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.POLICIES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/policies',
                        tooltip:
                          translations['pages.home.policies-module-tooltip'],
                        image: 'assets/modules/svgs/Policy.svg',
                        alt: 'leads',
                        title: translations['pages.home.policies-face'],
                        searchKey: 'leads',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.POLICIES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.CLAIMS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/claims',
                        tooltip:
                          translations['pages.home.claims-module-tooltip'],
                        image: 'assets/modules/svgs/Claims.svg',
                        alt: 'claims',
                        title: translations['pages.home.claims-face'],
                        searchKey: 'claims',
                      },
                      allowedPermissions: getModulePermission(MODULES.CLAIMS, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.COVER_NOTES:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/cover-notes',
                        tooltip:
                          translations['pages.home.cover-notes-module-tooltip'],
                        image: 'assets/modules/svgs/CoverNote.svg',
                        alt: 'cover-notes',
                        title: translations['pages.home.cover-notes-face'],
                        searchKey: 'cover-notes',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.COVER_NOTES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.ENDORSEMENTS:
                    allModuleCards.push({
                      data: {
                        routerLink: '/app/endorsements',
                        tooltip:
                          translations[
                            'pages.home.endorsements-module-tooltip'
                          ],
                        image: 'assets/modules/svgs/Endorsements.svg',
                        alt: 'endorsements',
                        title: translations['pages.home.endorsements-face'],
                        searchKey: 'endorsements',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.ENDORSEMENTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.ACCOUNTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'accounts',
                        title: translations['pages.home.accounts-face'],
                        routerLink: '/app/accounts',
                        tooltip:
                          translations['pages.home.accounts-module-tooltip'],
                        image: 'assets/modules/svgs/Accounts.svg',
                        alt: 'accounts',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.ACCOUNTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.EXCHANGE_RATES:
                    allModuleCards.push({
                      data: {
                        searchKey: 'exchange-rates',
                        title: translations['pages.home.exchange-rates-face'],
                        routerLink: '/app/exchange-rates',
                        tooltip:
                          translations[
                            'pages.home.exchange-rates-module-tooltip'
                          ],
                        image: 'assets/modules/svgs/currency_exchange.svg',
                        alt: 'exchange-rates',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.EXCHANGE_RATES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.GATEWAYS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'gateways',
                        title: translations['pages.home.gateways-face'],
                        routerLink: '/app/gateways',
                        tooltip:
                          translations['pages.home.gateways-module-tooltip'],
                        image: 'assets/modules/svgs/Gateways.svg',
                        alt: 'gateways',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.GATEWAYS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.TRANSACTIONS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'transactions',
                        title: translations['pages.home.transactions-face'],
                        routerLink: '/app/transactions',
                        tooltip:
                          translations[
                            'pages.home.transactions-module-tooltip'
                          ],
                        image: 'assets/modules/svgs/Transactions.svg',
                        alt: 'transactions',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.TRANSACTIONS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.CHART_OF_ACCOUNTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'chart-of-accounts',
                        title:
                          translations['pages.home.chart-of-accounts-face'],
                        routerLink: '/app/chart-of-accounts',
                        tooltip:
                          translations[
                            'pages.home.chart-of-accounts-module-tooltip'
                          ],
                        image: 'assets/modules/svgs/Chart-of-Accounts.svg',
                        alt: 'chart-of-accounts',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.CHART_OF_ACCOUNTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.ENTRIES:
                    allModuleCards.push({
                      data: {
                        searchKey: 'entries',
                        title: translations['pages.home.entries-face'],
                        routerLink: '/app/entries',
                        tooltip:
                          translations['pages.home.entries-module-tooltip'],
                        image: 'assets/modules/svgs/Entries.svg',
                        alt: 'entries',
                      },
                      allowedPermissions: getModulePermission(MODULES.ENTRIES, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.INVOICES:
                    allModuleCards.push({
                      data: {
                        searchKey: 'invoices',
                        title: translations['pages.home.invoices-face'],
                        routerLink: '/app/invoices',
                        tooltip:
                          translations['pages.home.invoices-module-tooltip'],
                        image: 'assets/modules/svgs/Invoices.svg',
                        alt: ' local_atm',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.INVOICES,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.RECEIPTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'receipts',
                        title: translations['pages.home.receipts-face'],
                        routerLink: '/app/receipts',
                        tooltip:
                          translations['pages.home.receipts-module-tooltip'],
                        image: 'assets/modules/svgs/Receipt.svg',
                        alt: 'receipts',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.RECEIPTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.ASSESSORS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'assessors',
                        title: translations['pages.home.assessors-face'],
                        routerLink: '/app/assessors',
                        tooltip:
                          translations['pages.home.assessors-module-tooltip'],
                        image: 'assets/modules/svgs/Assessor.svg',
                        alt: 'assessors',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.ASSESSORS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.ASSESSMENTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'assessments',
                        title: translations['pages.home.assessments-face'],
                        routerLink: '/app/assessments',
                        tooltip:
                          translations['pages.home.assessments-module-tooltip'],
                        image: 'assets/modules/svgs/Assessment.svg',
                        alt: 'assessments',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.ASSESSMENTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.PAYMENTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'payments',
                        title: translations['pages.home.payments-face'],
                        routerLink: '/app/payments',
                        tooltip:
                          translations['pages.home.payments-module-tooltip'],
                        image: 'assets/modules/svgs/Payments.svg',
                        alt: 'payments',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.PAYMENTS,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;

                  case MODULES.REPORTS:
                    allModuleCards.push({
                      data: {
                        searchKey: 'reports',
                        title: translations['pages.home.reports-face'],
                        routerLink: '/app/reports',
                        tooltip:
                          translations['pages.home.reports-module-tooltip'],
                        image: 'assets/modules/svgs/Reports.svg',
                        alt: 'Reports',
                      },
                      allowedPermissions: getModulePermission(MODULES.REPORTS, [
                        PERMISSION_ACTIONS.READ,
                      ]),
                    });
                    break;

                  case MODULES.BLACKLIST:
                    allModuleCards.push({
                      data: {
                        searchKey: 'blacklist',
                        title: translations['pages.home.blacklist-face'],
                        routerLink: '/app/blacklist',
                        tooltip:
                          translations['pages.home.blacklist-module-tooltip'],
                        image: 'assets/modules/svgs/Blacklist.svg',
                        alt: 'blacklist',
                      },
                      allowedPermissions: getModulePermission(
                        MODULES.BLACKLIST,
                        [PERMISSION_ACTIONS.READ]
                      ),
                    });
                    break;
                }
              });
          }

          let filteredModules = allModuleCards;

          if (!this.isInternalIAM) {
            filteredModules = filteredModules.filter((module) => {
              return !(
                module.data.routerLink.includes('/app/identities') ||
                module.data.routerLink.includes('/app/connections') ||
                module.data.routerLink.includes('/app/apps')
              );
            });
          }

          if (!this.isInternalStorage) {
            filteredModules = filteredModules.filter(
              (module) => !module.data.routerLink.includes('/app/storage/')
            );
          }

          if (this.loggedUserInfo) {
            this.filteredModuleCards =
              this.permissionFiltrationService.filterModules(filteredModules);
          } else {
            this.filteredModuleCards = [];
          }
        });
      });
  }

  filter(str: string) {
    return (
      str.toLowerCase().search(this.moduleFilterValue.trim().toLowerCase()) > -1
    );
  }

  getNoticeCount(count) {
    this.noticeCount = count;
  }

  checkFilterEmpty() {
    this.isEmptyModules =
      this.filteredModuleCards.find((value) => this.filter(value.searchKey)) ==
      undefined;
  }
}
