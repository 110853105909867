import { Pipe, PipeTransform } from '@angular/core';

import { CLAIMS_STATUS } from '@shared/constants';

@Pipe({
  name: 'statusChipColor',
})
export class StatusChipColorPipe implements PipeTransform {
  transform(status: CLAIMS_STATUS): string {
    switch (status) {
      case CLAIMS_STATUS.INTIMATED:
        return '#3474E0';
      case CLAIMS_STATUS.DRAFT:
        return '#808080';
      case CLAIMS_STATUS.PENDING_REVIEW:
        return '#E6A03A';
      case CLAIMS_STATUS.PENDING_ASSESSMENT:
        return '#3474E0';
      case CLAIMS_STATUS.PENDING_ONSITE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.PENDING_ENGINEER_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.PENDING_GARAGE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.PENDING_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.PENDING_AUTHORIZATION:
        return '#E6A03A';
      case CLAIMS_STATUS.COMPLETED:
        return '#459B64';
      case CLAIMS_STATUS.REJECTED:
        return '#B43236';
      case CLAIMS_STATUS.ON_HOLD:
        return '#B43236';
      case CLAIMS_STATUS.SENT_TO_NITF:
        return '#8A8A8A';
      case CLAIMS_STATUS.SENT_TO_REINSURER:
        return '#8A8A8A';
      case CLAIMS_STATUS.SENT_TO_LITIGATION:
        return '#8A8A8A';
      case CLAIMS_STATUS.APPROVED:
        return '#459B64';
      case CLAIMS_STATUS.PENDING_RE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.ACCEPTED_ONSITE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.ACCEPTED_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.ACCEPTED_ENGINEER_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.ACCEPTED_GARAGE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.REJECTED_ONSITE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.REJECTED_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.REJECTED_ENGINEER_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.REJECTED_GARAGE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.COMPLETED_ONSITE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.COMPLETED_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.COMPLETED_GARAGE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.COMPLETED_ENGINEER_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.ACTIVE_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.EXPIRED_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.CANCELLED_ONSITE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.CANCELLED_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.CANCELLED_ENGINEER_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.CANCELLED_GARAGE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.DRAFT_ONSITE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.DRAFT_VIRTUAL_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.DRAFT_ENGINEER_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.DRAFT_GARAGE_INSPECTION:
        return '#E6A03A';
      case CLAIMS_STATUS.REDO:
        return '#E6A03A';
      case CLAIMS_STATUS.REFER:
        return '#E6A03A';
      default:
        return '#3474E0';
    }
  }
}
