/**
 *
 * @param content The data that needs to be formatted
 * @param fields An array containing the fields that match with the dynamic data
 * passed in content
 * @returns The variables used in content as an array and the processed content as a string.
 */
export const getViewBuilderVariableValuesPopulatedContent = (
  content: string,
  fields: { name: string; value: any; isHidden?: boolean }[]
): { variables: string[]; processedContent: string } => {
  const variables = [];
  const finalContentArray = [];
  let contentParts = content;
  let openIndex = contentParts?.indexOf('{{');
  let closeIndex = contentParts?.indexOf('}}');

  if (contentParts?.includes('<table class="e-rte-table" style="')) {
    contentParts = contentParts?.replaceAll(' class=""', ''); // Remove empty classes

    contentParts = contentParts?.replaceAll(
      // add border to tables
      '<table class="e-rte-table" style="',
      '<table style="border: 1px solid;border-collapse: collapse; padding: 10px;'
    );

    if (contentParts?.includes('<th style="')) {
      // add border to th
      contentParts = contentParts?.replaceAll(
        '<th style="',
        '<th style="border: 1px solid;border-collapse: collapse; padding: 10px;'
      );
    }
    if (contentParts?.includes('<td style="')) {
      // add border to td
      contentParts = contentParts?.replaceAll(
        '<td style="',
        '<td style="border: 1px solid;border-collapse: collapse; padding: 10px;'
      );
    }
  }

  while (contentParts?.length > 0 && openIndex > -1) {
    openIndex = contentParts?.indexOf('{{');
    closeIndex = contentParts?.indexOf('}}');

    if (closeIndex > openIndex) {
      const singleVariable = contentParts?.substring(openIndex + 2, closeIndex);
      const variableStartingIndex = singleVariable?.indexOf('@input_');
      const formattedVariable = singleVariable
        ?.substring(variableStartingIndex + 7)
        ?.split('_')
        ?.join(' ');

      const foundField = fields?.find((field) => {
        // returns the field that is used as the input variable
        return (
          field?.name?.replace(/\s/g, ' ')?.toUpperCase() ===
          formattedVariable?.toUpperCase()
        );
      });

      // replace the input variable with the field name and pass it to finalContentArray
      finalContentArray.push(
        contentParts?.substring(0, openIndex) +
          (foundField?.value
            ? foundField?.isHidden
              ? 'N/A'
              : foundField?.value
            : '<b>-</b>')
      );

      variables.push(contentParts?.substring(openIndex + 2, closeIndex));

      // remove the string added to finalContentArray from contentParts
      contentParts = contentParts?.substring(closeIndex + 2);
    }
  }
  finalContentArray.push(contentParts);

  return { variables, processedContent: finalContentArray.join('') };
};
