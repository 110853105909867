import { ElementRef, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrollBehaviorService {
  private dashboardContainer: ElementRef | null = null;

  public setDashboardContainer(element: ElementRef) {
    if (element) {
      this.dashboardContainer = element;
    }
  }

  public getDashboardContainer(): ElementRef {
    return this.dashboardContainer;
  }

  public scrollTop() {
    if (this.dashboardContainer) {
      this.dashboardContainer?.nativeElement?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public scrollTo(
    top: number,
    left: number,
    behavior: 'smooth' | 'auto' = 'auto'
  ) {
    if (this.dashboardContainer) {
      this.dashboardContainer?.nativeElement?.scrollTo({
        top: top ?? 0,
        left: left ?? 0,
        behavior,
      });
    }
  }

  public getDashBoardContainElement() {
    return this.dashboardContainer;
  }
}
