export enum INSTRUMENT_TYPES_FIELD {
  TYPE = 'TYPE',
}

export enum COLLECTION_TYPES_FIELD {
  TYPE = 'TYPE',
  CODE = 'CODE',
  IS_ENABLE = 'ISENABLE',
}

export enum PAYMENT_TYPES_FIELD {
  TYPE = 'TYPE',
  CODE = 'CODE',
  IS_ENABLE = 'ISENABLE',
}
