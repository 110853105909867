export enum SECTION_WIDTH {
  FULL_WIDTH = 'FULL_WIDTH',
  HALF_WIDTH = 'HALF_WIDTH',
  ONE_THIRD_WIDTH = 'ONE_THIRD_WIDTH',
}

export enum VIEW_BUILDER_TYPES {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  BOTH = 'BOTH',
}
