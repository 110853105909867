export enum AGREEMENT_TYPES {
  PROPORTIONAL = 'PROPORTIONAL',
  XOL = 'XOL', //EXCESS OF LOSS
}

export enum AGREEMENT_DOCUMENT_TYPES {
  COVER_NOTE = 'COVER_NOTE',
  DEBIT_NOTE = 'DEBIT_NOTE',
  ENDORSEMENT = 'ENDORSEMENT',
  ADDITIONAL = 'ADDITIONAL',
}

export enum AGREEMENT_ATTACHMENT_TYPE {
  RISK = 'RISK',
  LOSS = 'LOSS',
}

export enum TRIGGER_TYPES {
  CASH_CALL = 'Cash Call Limit',
  EVENT_LIMIT = 'Event Limit',
}

export enum AGREEMENT_REFERENCE_CATEGORIES {
  COUNTRIES = 'COUNTRIES',
  CLASSES = 'CLASSES',
  COVERAGES = 'COVERAGES',
  SUB_CLASSES = 'SUB-CLASSES',
  COVERS_MTR = 'COVERS (MTR)',
  COVERS_MED = 'COVERS (MED)',
  COVERS_TRV = 'COVERS (TRV)',
}
