import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EXTERNAL_SHARE_SECURITY_TYPES, URLS } from '@shared/constants';
import {
  PhoneNumberResponseDTO,
  StorageEntityPublicShareResponseDTO,
} from '@shared/dtos';
import {
  CommonResponseDTO,
  IBaseEntity,
  IGetEntityQuery,
  IIdentityResponse,
  IStorageEntity,
} from '@shared/interfaces';

export interface StorageContentResponse {
  entity: IStorageEntity[];
  shared?: IStorageEntity[];
}

export interface ShareWithExternally extends IBaseEntity {
  email?: string;
  phone_number?: PhoneNumberResponseDTO;
  type: string;
  security_type?: EXTERNAL_SHARE_SECURITY_TYPES;
  security_type_text?: string;
  password_string?: string;
}
export interface IShareWithExternally extends ShareWithExternally {
  entity?: string;
}

export interface ShareWithInternally extends IBaseEntity {
  identity?: IIdentityResponse;
  identity_id?: string;
}

export interface IShareWithInternally extends ShareWithInternally {
  entity?: string;
}

export interface IShareDMSEntity {
  sharedWithExternally: IShareWithExternally[];
  sharedWithInternally: IShareWithInternally[];
}

interface FolderHierarchyDto extends IStorageEntity {
  depth: number;
}

export interface StorageEntityShareURLResponseDto {
  shareLink: string;
}

export interface StorageEntityDetailsResponseDto extends IStorageEntity {
  // aggregated
  folderHierarchy?: FolderHierarchyDto[];
}

export interface CreateEntityRequestDTO {
  parent_id?: string;
  storage_id: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class StorageEntityService {
  constructor(private http: HttpClient) {}

  getFolderContent(
    storageId: string,
    entityId: string,
    queryParams: IGetEntityQuery
  ): Observable<CommonResponseDTO<IStorageEntity[]>> {
    let params = new HttpParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      params = params.append(key, value as string);
    });
    return this.http.get<CommonResponseDTO<IStorageEntity[]>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/content`,
      {
        params,
      }
    );
  }

  getFolderDetails(
    storageId: string,
    entityId: string,
    params: HttpParams
  ): Observable<CommonResponseDTO<StorageEntityDetailsResponseDto>> {
    return this.http.get<CommonResponseDTO<StorageEntityDetailsResponseDto>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/details`,
      {
        params,
      }
    );
  }

  createNewFolder(
    requestBody: CreateEntityRequestDTO,
    storageId: string,
    params: HttpParams
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.post<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE}/${storageId}/create-folder`,
      requestBody,
      {
        params,
      }
    );
  }

  downloadEntity(
    storageId: string,
    entityId: string
  ): Observable<HttpEvent<any>> {
    return this.http.get<HttpEvent<any>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/download`,
      {
        reportProgress: true,
        responseType: 'blob' as 'json',
        observe: 'events',
      }
    );
  }

  uploadFileWithProgress(
    storageId: string,
    formData: FormData
  ): Observable<HttpEvent<any>> {
    return this.http.post<HttpEvent<any>>(
      `${URLS.STORAGE}/${storageId}/upload`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  renameEntity(
    data: { storageId: string; entityId: string; newName: string },
    params: HttpParams
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.patch<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE}/${data.storageId}/entity/${data.entityId}/rename`,
      { new_name: data.newName },
      { params }
    );
  }

  deleteEntity(
    storageId: string,
    entityId: string
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.delete<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}`
    );
  }

  updateEntityTags(
    storageId: string,
    entityId: string,
    tags: string[],
    isDelete: boolean = false
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.patch<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/tags/update`,
      {
        tags,
        is_delete: isDelete ? true : undefined,
      }
    );
  }

  getRecycleBinContent(
    params?: any
  ): Observable<CommonResponseDTO<IStorageEntity[]>> {
    return this.http.get<CommonResponseDTO<IStorageEntity[]>>(
      URLS.STORAGE_ENTITY_RECYCLE_BIN,
      {
        params,
      }
    );
  }

  getRecycleBinContentForQuota(
    params?: any
  ): Observable<CommonResponseDTO<IStorageEntity[]>> {
    return this.http.get<CommonResponseDTO<IStorageEntity[]>>(
      `${URLS.STORAGE_ENTITY_RECYCLE_BIN}/quota`,
      {
        params,
      }
    );
  }

  restoreEntity(
    entityId: string
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.put<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE_ENTITY_RECYCLE_BIN}/${entityId}/restore`,
      {}
    );
  }

  removeDocument(
    entityId: string
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.delete<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE_ENTITY_RECYCLE_BIN}/${entityId}/delete`,
      {}
    );
  }

  deleteAll(): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.delete<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE_ENTITY_RECYCLE_BIN}/delete-all`
    );
  }

  shareEntityInternal(
    storageId: string,
    entityId: string,
    body: IShareWithInternally
  ): Observable<CommonResponseDTO<IShareWithInternally>> {
    return this.http.post<CommonResponseDTO<IShareWithInternally>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/share/internal`,
      {
        sharedWithInternally: body,
      }
    );
  }

  shareEntityExternal(
    storageId: string,
    entityId: string,
    body: IShareWithExternally
  ): Observable<CommonResponseDTO<IShareDMSEntity>> {
    return this.http.post<CommonResponseDTO<IShareDMSEntity>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/share/external`,
      {
        sharedWithExternally: body,
      }
    );
  }

  shareEntityPublic(
    storageId: string,
    entityId: string
  ): Observable<CommonResponseDTO<StorageEntityPublicShareResponseDTO>> {
    return this.http.get<
      CommonResponseDTO<StorageEntityPublicShareResponseDTO>
    >(`${URLS.STORAGE}/${storageId}/entity/${entityId}/share/public/generate`);
  }

  generateEntityUrl(
    storageId: string,
    entityId: string
  ): Observable<CommonResponseDTO<StorageEntityPublicShareResponseDTO>> {
    return this.http.get<
      CommonResponseDTO<StorageEntityPublicShareResponseDTO>
    >(
      `${URLS.INTERNAL_STORAGE_V2}/entity/${entityId}/share/public/generate-url/${storageId}`
    );
  }

  getEntityPublicSharedUrl(
    storageId: string,
    entityId: string
  ): Observable<CommonResponseDTO<StorageEntityPublicShareResponseDTO>> {
    return this.http.get<
      CommonResponseDTO<StorageEntityPublicShareResponseDTO>
    >(`${URLS.STORAGE}/${storageId}/entity/${entityId}/share/public`);
  }

  entityPublicShareDisable(
    storageId: string,
    entityId: string
  ): Observable<CommonResponseDTO<StorageEntityPublicShareResponseDTO>> {
    return this.http.get<
      CommonResponseDTO<StorageEntityPublicShareResponseDTO>
    >(`${URLS.STORAGE}/${storageId}/entity/${entityId}/share/public/disable`);
  }

  entityPublicShareEnable(
    storageId: string,
    entityId: string
  ): Observable<CommonResponseDTO<void>> {
    return this.http.get<CommonResponseDTO<void>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/share/public/enable`
    );
  }

  shareEntityInternalRemove(
    storageId: string,
    entityId: string,
    id: string
  ): Observable<CommonResponseDTO<IShareWithInternally[]>> {
    return this.http.delete<CommonResponseDTO<IShareWithInternally[]>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/share/${id}/delete/internal`
    );
  }

  shareEntityExternalRemove(
    storageId: string,
    entityId: string,
    id: string
  ): Observable<CommonResponseDTO<IShareWithExternally[]>> {
    return this.http.delete<CommonResponseDTO<IShareWithExternally[]>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/share/${id}/delete/external`
    );
  }

  getConnectedIdentities(
    storageId: string,
    entityId: string,
    value: string
  ): Observable<CommonResponseDTO<IIdentityResponse[]>> {
    return this.http.post<CommonResponseDTO<IIdentityResponse[]>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/connected-identities`,
      { name_filter: value }
    );
  }

  getEntityInternalShares(
    storageId: string,
    entityId: string,
    params
  ): Observable<CommonResponseDTO<IShareWithInternally[]>> {
    return this.http.get<CommonResponseDTO<IShareWithInternally[]>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/shares/internal`,
      { params }
    );
  }

  getEntityExternalShares(
    storageId: string,
    entityId: string,
    params
  ): Observable<CommonResponseDTO<IShareWithExternally[]>> {
    return this.http.get<CommonResponseDTO<IShareWithExternally[]>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/shares/external`,
      { params }
    );
  }

  copyShareEntityExternalLink(
    storageId: string,
    entityId: string
  ): Observable<CommonResponseDTO<StorageEntityShareURLResponseDto>> {
    return this.http.get<CommonResponseDTO<StorageEntityShareURLResponseDto>>(
      `${URLS.STORAGE}/${storageId}/entity/${entityId}/generate-link`
    );
  }

  moveEntity(
    storageId: string,
    destination_id: string,
    entities: string[]
  ): Observable<CommonResponseDTO<IStorageEntity>> {
    return this.http.put<CommonResponseDTO<IStorageEntity>>(
      `${URLS.STORAGE}/${storageId}/entity/move`,
      {
        destination_id,
        entities,
      }
    );
  }
}
