import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[commaSeparatedWithTwoDecimals]',
  providers: [DecimalPipe],
})
export class CommaSeparatedWithTwoDecimalDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput() {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let value = inputElement.value;

    // Remove any non-numeric characters except decimal point
    value = value.replace(/[^\d.]/g, '');

    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = value.split('.');

    // Apply thousand separator to the integer part
    const formattedIntegerPart = integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    );

    // Limit decimalPart to two digits and combine with the integer part
    if (decimalPart !== undefined) {
      const formattedDecimalPart = decimalPart.slice(0, 2);
      value = `${formattedIntegerPart}.${formattedDecimalPart}`;
    } else {
      value = formattedIntegerPart;
    }

    // Update the input element with the formatted value
    inputElement.value = value;
  }
}
