export enum PRODUCT_REFERENCE_CATEGORIES {
  TYPES = 'TYPES',
  NATURE = 'NATURES',
  CLASSES = 'CLASSES',
  SUB_CLASSES = 'SUB-CLASSES',
  USAGES = 'USAGES',
  COVERS_MTR = 'COVERS (MTR)',
  COVERS_MED = 'COVERS (MED)',
  COVERS_TRV = 'COVERS (TRV)',
  COVERS_OTR = 'COVERS (OTR)',
  REFUNDABLE_METHODS = 'REFUNDABLE METHODS',
  CLAUSES_MTR = 'CLAUSES (MTR)',
  CLAUSES_MED = 'CLAUSES (MED)',
  CLAUSES_TRV = 'CLAUSES (TRV)',
  CLAUSES_OTR = 'CLAUSES (OTR)',
  COMMISSIONS = 'COMMISSIONS',
  FEE_AND_CHARGES = 'FEES AND CHARGES',
  SUBLIMITS_MTR = 'SUBLIMITS (MTR)',
  SUBLIMITS_MED = 'SUBLIMITS (MED)',
  SUBLIMITS_TRV = 'SUBLIMITS (TRV)',
  SUBLIMITS_OTR = 'SUBLIMITS (OTR)',
  DISCOUNTS = 'DISCOUNTS',
  BONUSES = 'BONUSES',
  COUNTRIES = 'COUNTRIES',
  COUNTRIES_TRV = 'COUNTRIES(TRV)',
  LOCATIONS_TRV = 'LOCATION(TRV)',
  CITIES = 'CITIES',
  PROVINCES = 'PROVINCES',
  DISTRICTS = 'DISTRICTS',
  FORMULAE_MTR = 'FORMULAE (MTR)',
  FORMULAE_MED = 'FORMULAE (MED)',
  FORMULAE_OTR = 'FORMULAE (OTR)',
  DOCUMENT_TYPES_MTR = 'DOCUMENT TYPES (MTR)',
  DOCUMENT_TYPES_MED = 'DOCUMENT TYPES (MED)',
  DOCUMENT_TYPES_TRV = 'DOCUMENT TYPES (TRV)',
  FORM_TYPES = 'FORM TYPES',
  TRIGGERS_MTR = 'TRIGGERS (MTR)',
  TRIGGERS_MED = 'TRIGGERS (MED)',
  VEHICLE_MAKES = 'VEHICLE MAKES',
  VEHICLE_MODELS = 'VEHICLE MODELS',
  VEHICLE_TYPES = 'VEHICLE TYPES',
  FUEL_TYPES = 'FUEL TYPES',
  COVERAGE_TYPES = 'COVERAGE TYPES',
  COVERAGES = 'COVERAGES',
  MODIFICATION_NOTES = 'MODIFICATION NOTES',
  OCCUPATIONS = 'OCCUPATIONS',
  PLANS = 'PLANS',
  SECTIONS = 'SECTIONS',
  COMPANY_NAMES = 'COMPANY NAMES',
}

export enum PRODUCT_CATEGORIES {
  INDIVIDUAL = 'Individual',
  GROUP = 'Group',
}

export enum PRODUCT_COMMENCEMENT {
  IMMEDIATE = 'Immediate',
  NEXT_DAY = 'Next day',
}

export enum WORKFLOW_DATA_GET_TYPES {
  INTERNAL = 'INTERNAL',
  EXTERNAL_REQUEST = 'EXTERNAL (REQUEST)',
  EXTERNAL_UW = 'EXTERNAL (UNDER-WRITER)',
}

export enum PRODUCT_EXPIRY {
  ONE_DAY_SHORT = 'One day short',
  ONE_DAY = 'On day',
}

export enum PRODUCT_APPLY {
  ONCE = 'Once',
  REPEAT = 'Repeat',
}

export enum RISK_RULES_TYPES {
  CONDITIONS = 'CONDITIONS',
  DATABASE = 'DATABASE',
  BOOLEAN = 'BOOLEAN',
  LOCATION = 'LOCATION',
  SUM_ASSURED = 'SUM ASSURED',
  PREMIUM = 'PREMIUM',
}

export enum RISK_RULES_RESPONSES {
  // FOR CONDITIONS
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  // FOR DATABASES
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  // FOR BOOLEAN
  YES = 'YES',
  NO = 'NO',
}

export enum RISK_DIRECT_TO_TYPES {
  STANDARD = 'STANDARD',
  NON_STANDARD = 'NON-STANDARD',
}

export enum POLICY_TEMPLATES {
  QUICK_QUOTE = 'QUICK_QUOTE',
  QUOTATION = 'QUOTATION',
  PROPOSAL = 'PROPOSAL',
}

export enum TEMPLATES_DEFAULT_INPUT_VALUES { // when editing make sure to enter same entries on FOMS Side As well
  QUOTATION_ISSUED_DATE_AND_TIME = 'Quotation Issued Date and Time',
  QUOTATION_ISSUED_BY_USER = 'Quotation Issued By User',
  BROKER = 'Broker',
  PREMIUMS_TABLE = 'Premiums Table',
  QUOTATION_NUMBER = 'Quotation Number',
  NAME_OF_PROPOSER = 'Name of Proposer',
  CURRENCY_CODE = 'Currency Code',
  CLAUSES = 'Clauses',
  FINANCIAL_INSTITUTION = 'Financial Institution',
  PERIOD_OF_COVER = 'Period Of Cover',
  SUM_INSURED = 'Sum Insured',
  CUSTOMER_NAME = 'Customer Name',
  BRANCH = 'Branch',
  AGENT_CONTACT_NUMBER = 'Agent Contact Number',
  CUSTOMER_ADDRESS = 'Customer Address',
  QUICK_QUOTE_NUMBER = 'Quick Quote Number',
  ISSUED_DATE = 'Issued Date',
  CATEGORY = 'Category',
  PRODUCT_TYPE = 'Product Type',

  // For Travel
  TRAVELER_NAME = 'Name of Traveler',
  DATE_OF_BIRTH = 'Date of Birth',
  TRAVEL_TYPE = 'Travel Type',
  NATURE_OF_TRIP = 'Nature of Trip',
  TRAVEL_AREA = 'Area of Travel',
  TRAVEL_PERIOD = 'Period of Travel',
  PRODUCT = 'Product',
  PLAN = 'Plan',
  TOTAL_PREMIUM = 'Total Premium',
  GROUP_TRAVELERS_DETAILS = 'Group Travelers Details',
  SCHEDULE_CURRENCY = 'Schedule Currency',
}

export enum TEMPLATES_DEFAULT_OUTPUT_VALUES { // when editing make sure to enter same entries on FOMS Side As well
  CUSTOMER_NAME = 'Customer Name',
  AGE = 'Age',
  PHONE_NUMBER = 'Phone Number',
}

export enum PRODUCTS_ACCOUNTS_ENTRY_TYPES {
  COVER = 'COVER',
  FEES = 'FEES',
  PREMIUM = 'PREMIUM',
  BANK = 'BANK',
  DEBTOR_CONTROL = 'DEBTOR CONTROL',
  PROFIT_AND_LOSS = 'PROFIT AND LOSS',
  CLAIM_PAID = 'CLAIM PAID',
  CLAIM_RESERVED = 'CLAIM RESERVED',
  BANK_ACCOUNT = 'BANK ACCOUNT',
}

// Not in the product setup ==> this is for the premium calculation part and ledger handling

export enum FEE_TYPES {
  STAMP = 'Stamp Duty',
  ADMIN = 'Administrative Fees',
  SSCL = 'SSCL',
  VAT = 'VAT',
}

export enum COVERAGE_TYPE {
  FAMILY_BASIS = 'Family Basis',
  PER_PERSON_BASIS = 'Per Person Basis',
}

export enum MEDICAL_SUBLIMIT_TYPE {
  WITHIN_COVER = 'within_cover',
  SEPARATE_LIMIT = 'separate_limit',
}

export enum OTHER_CLASS_SUBLIMIT_TYPE {
  EXTERNAL_REQUEST = 'External (Request)',
  EXTERNAL_UW = 'External (UW)',
  DIRECT = 'Direct',
}

export enum TRAVEL_VALUE_TYPE {
  SUM_ASSURED = 'Sum Assured',
  PER_SIX_HOURS = 'Per 6 Hours',
  PER_ONE_HOURS = 'Per 1 Hours',
  PER_TWELVE_HOURS = 'Per 12 Hours',
  PER_COVERAGE = 'Per Coverage',
}

export enum TRAVEL_COVERAGE {
  ADULT = 'Adult',
  SENIOR_CITIZEN = 'Senior Citizen',
  CHILD = 'Child',
}

export enum PRODUCT_TRIGGERS_LIST {
  PROPOSAL_SUBMISSION = 'Proposal Submission',
  POLICY_ISSUE = 'Policy Issue',
  PAYMENT_REMINDER = 'Payment Reminder',
  PAYMENT_RECEIVED = 'Payment Received',
  CLAIM_INTIMATED = 'Claim Intimated',
  CLAIM_PAID = 'Claim Paid',
  ENDORSEMENT_REQUESTED = 'Endorsement Requested',
  ENDORSEMENT_COMPLETED = 'Endorsement Completed',
}
