import { Injectable } from '@angular/core';

import { CONFIGURABLE_FIELD_DATA_TYPES } from '@shared/constants';
import {
  IConfigurableFieldValueResponse,
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IName,
} from '@shared/interfaces';
import { extractDefaultValue } from '@shared/utils';

import { DateTimeFormatService } from '../../../services/date-time-format.service';
import { ReferenceService } from '../../references/services/reference.service';
import { TemplateService } from '../../references/services/template.service';

import { AddressService } from './address.service';

@Injectable({ providedIn: 'root' })
export class ConfigurableFieldValueService {
  constructor(
    private dateTimeFormatService: DateTimeFormatService,
    private templateService: TemplateService,
    private referenceService: ReferenceService,
    private addressService: AddressService
  ) {}

  async fieldValueToString({
    valueResponse,
    app_id,
    categoryId,
  }: {
    valueResponse: IConfigurableFieldValueResponse;
    app_id?: string;
    categoryId?: string | undefined;
  }): Promise<string> {
    if (
      !valueResponse?.field ||
      (!valueResponse?.value && valueResponse?.value !== 0)
    ) {
      return '-';
    }

    const { field, value } = valueResponse;

    switch (field.type) {
      case CONFIGURABLE_FIELD_DATA_TYPES.TEXT:
      case CONFIGURABLE_FIELD_DATA_TYPES.NUMBER:
      case CONFIGURABLE_FIELD_DATA_TYPES.CURRENCY:
      case CONFIGURABLE_FIELD_DATA_TYPES.FILE: {
        return value.toString();
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.BOOLEAN:
        if (value?.toString() === 'true') {
          return 'Yes';
        } else {
          return 'No';
        }
      case CONFIGURABLE_FIELD_DATA_TYPES.DATE: {
        return this.dateTimeFormatService.formatDateAsString(value.toString());
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.DATE_TIME: {
        return this.dateTimeFormatService.formatDateTime(value.toString());
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.TIME: {
        return this.dateTimeFormatService.formatTime(value.toString());
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.FORMULA: {
        return field.type.toUpperCase();
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.RICH_TEXT: {
        return value.toString();
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.VIEW: {
        return (await this.templateService.getTemplateById(value.toString()))
          .name;
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.REFERENCES: {
        const fieldRefValue =
          await this.referenceService.getFieldValueFromBackend({
            referenceId: value.toString(),
            fieldId: field.reference_type_field_config.field_id.toString(),
            app_id,
            external_category: categoryId ?? undefined,
          });

        if (!fieldRefValue) return '-';

        return await this.fieldValueToString({
          valueResponse: fieldRefValue as IConfigurableFieldValueResponse,
          app_id,
        });
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.JSON: {
        if (value && value.toString().trim() !== '') {
          return value.toString();
        } else {
          return '-';
        }
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.RADIO_BUTTON:
      case CONFIGURABLE_FIELD_DATA_TYPES.CHECKBOX: {
        return value.toString();
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.ADDRESS: {
        const promises = (value as IContactAddress[]).map(
          async (item, index) => {
            value[index] = await this.addressService.bindAddressData(item);
          }
        );

        await Promise.all(promises);
        return extractDefaultValue({
          type: 'ADDRESS',
          value: value as IContactAddress[],
          fieldConfig: field,
        });
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.NAME: {
        return extractDefaultValue({
          type: 'NAME',
          value: value as IName,
          fieldConfig: field,
        });
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.CONTACT_NUMBER: {
        return extractDefaultValue({
          type: 'PHONE',
          value: value as IContactNumber[],
          fieldConfig: field,
        });
      }
      case CONFIGURABLE_FIELD_DATA_TYPES.EMAIL: {
        return extractDefaultValue({
          type: 'EMAIL',
          value: value as IContactEmail[],
          fieldConfig: field,
        });
      }
    }

    return '-';
  }
}
