import { ELAutocompleteElement } from '@el-autocomplete';

import { IConfigurableFieldConfig } from '@shared/interfaces';

export function referenceCategoryFieldsToElAutocomplete(
  fields: IConfigurableFieldConfig[]
): ELAutocompleteElement<IConfigurableFieldConfig>[] {
  return fields.map((category) => ({
    value: (category._id ?? category.name)?.toString(),
    displayValue: category.name,
    originalData: category,
  }));
}
