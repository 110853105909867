//TODO:@ranul ADD COMMENTS
// add the angular component selectors as the type
export enum WIDGET_TYPES {
  COUNT = 'app-count-widget',
  LINE = 'app-line-chart',
  BAR = 'app-bar-chart',
  DONUT = 'app-donut-widget',
  NUMBER_CARD = 'app-number-card-widget',
  GROUP_BAR = 'app-group-bar-chart',
}

export enum WIDGET_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum WIDGET_CATEGORIES {
  LOGS = 'LOGS',
  DRIVES = 'DRIVES',
  MODULES = 'MODULES',
  CONNECTED_APPS = 'CONNECTED_APPS',
  CONNECTIONS = 'CONNECTIONS',
  IDENTITIES = 'IDENTITIES',
  MESSAGES = 'MESSAGES',
  NOTICES = 'NOTICES',
  PERMISSIONS = 'PERMISSIONS',
  ROLES = 'ROLES',
  PRIVILEGES = 'PRIVILEGES',
  REFERENCES = 'REFERENCES',
  KNOWLEDGEBASE = 'KNOWLEDGEBASE',
  INVOICES = 'INVOICES',
  REINSURERS = 'REINSURERS',
  PARTNERS = 'PARTNERS',
  AGREEMENTS = 'AGREEMENTS',
  MAPS = 'MAPS',
  BRANCHES = 'BRANCHES',
  CONTROLS = 'CONTROLS',
  CUSTOMERS = 'CUSTOMERS',
  PRODUCTS = 'PRODUCTS',
  POLICIES = 'POLICIES',
  COVER_NOTES = 'COVER_NOTES',
  ENDORSEMENTS = 'ENDORSEMENTS',
  CLAIMS = 'CLAIMS',
  ASSESSORS = 'ASSESSORS',
  ASSESSMENTS = 'ASSESSMENTS',
  INCIDENTS = 'INCIDENTS',
  ACCOUNTS = 'ACCOUNTS',
  GATEWAYS = 'GATEWAYS',
  TRANSACTIONS = 'TRANSACTIONS',
  CHART_OF_ACCOUNTS = 'CHART_OF_ACCOUNTS',
  PAYMENTS = 'PAYMENTS',
  BLACKLIST = 'BLACKLIST',
  ENDPOINTS = 'ENDPOINTS',
  MAPPINGS = 'MAPPINGS',
  STORAGE = 'STORAGE',
  TEST = 'TEST',
  ACTIVITIES = 'ACTIVITIES',
  AGENTS = 'AGENTS',
  LEADS = 'LEADS',
}

export enum WIDGET_KEYS {
  CONNECTED_APPS_COUNT = 'CONNECTED_APPS_COUNT',
  NOTICES_TYPE_COUNT = 'NOTICES_TYPE_COUNT',
  KNOWLEDGEBASE_TYPE_COUNT = 'KNOWLEDGEBASE_TYPE_COUNT',
  ROLES_TYPE_COUNT = 'ROLES_TYPE_COUNT',
  REFERENCES_COUNT = 'REFERENCES_COUNT',
  PRIVILEGES_COUNT = 'PRIVILEGES_COUNT',
  MESSAGES_TYPE_COUNT = 'MESSAGES_TYPE_COUNT',
  IDENTITIES_TYPE_COUNT = 'IDENTITIES_TYPE_COUNT',
  NOTICES_COUNT = 'NOTICES_COUNT',
  DRIVES_COUNT = 'DRIVES_COUNT',
  MESSAGES_COUNT = 'MESSAGES_COUNT',
  ROLES_COUNT = 'ROLES_COUNT',
  PERMISSION_COUNT = 'PERMISSION_COUNT',
  IDENTITIES_COUNT = 'IDENTITIES_COUNT',
  MODULES_COUNT = 'MODULES_COUNT',
  TEST_APPS_COUNT = 'TEST_APPS_COUNT',
  IDENTITIES_UNVERIFIED_COUNT = 'IDENTITIES_UNVERIFIED_COUNT',
  IDENTITIES_VERIFIED_COUNT = 'IDENTITIES_VERIFIED_COUNT',
  LOGS_COUNT = 'LOGS_COUNT',
  WIDGET_AGREEMENTS_COUNT_OVERALL = 'WIDGET_AGREEMENTS_COUNT_OVERALL',
  WIDGET_MAPS_COUNT_OVERALL = 'WIDGET_MAPS_COUNT_OVERALL',
  WIDGET_BRANCHES_COUNT_OVERALL = 'WIDGET_BRANCHES_COUNT_OVERALL',
  WIDGET_CUSTOMER_OVERALL = 'WIDGET_CUSTOMER_OVERALL',
  WIDGET_PRODUCTS_COUNT_OVERALL = 'WIDGET_PRODUCTS_COUNT_OVERALL',
  WIDGET_POLICIES_STATUS_OVERALL = 'WIDGET_POLICIES_STATUS_OVERALL',
  WIDGET_COVERNT_MONTHLY_COUNT_OVERALL = 'WIDGET_COVERNT_MONTHLY_COUNT_OVERALL',
  WIDGET_ENDORSEMENT_MONTHLY_COUNT_OVERALL = 'WIDGET_ENDORSEMENT_MONTHLY_COUNT_OVERALL',
  WIDGET_CLAIMS_MONTHLY_COUNT_OVERALL = 'WIDGET_CLAIMS_MONTHLY_COUNT_OVERALL',
  COMPLETED_CLAIMS_MONTHLY_COUNT_OVERALL = 'COMPLETED_CLAIMS_MONTHLY_COUNT_OVERALL',
  COMPLETED_CLASS_WISE_CLAIMS_MONTHLY_COUNT_OVERALL = 'COMPLETED_CLASS_WISE_CLAIMS_MONTHLY_COUNT_OVERALL',
  WIDGET_ASSESSORS_COUNT_OVERALL = 'WIDGET_ASSESSORS_COUNT_OVERALL',
  ASSESSMENTS_COUNT = 'ASSESSMENTS_COUNT',
  INCIDENTS_COUNT = 'INCIDENTS_COUNT',
  ACCOUNTS_COUNT = 'ACCOUNTS_COUNT',
  GATEWAYS_COUNT = 'GATEWAYS_COUNT',
  TRANSACTIONS_COUNT = 'TRANSACTIONS_COUNT',
  CHART_OF_ACCOUNTS_COUNT = 'CHART_OF_ACCOUNTS_COUNT',
  PAYMENTS_COUNT = 'PAYMENTS_COUNT',
  BLACKLIST_COUNT = 'BLACKLIST_COUNT',
  NOTICES_REPORT = 'NOTICES_REPORT',
  WIDGET_AGREEMENTS_TYPE_OVERALL = 'WIDGET_AGREEMENTS_TYPE_OVERALL',
  WIDGET_MAPS_CLASS_OVERALL = 'WIDGET_MAPS_CLASS_OVERALL',
  INVOICES_TYPE_COUNT = 'INVOICES_TYPE_COUNT',
  WIDGET_INVOICES_PAYMENT_COUNT_OVERALL = 'WIDGET_INVOICES_PAYMENT_COUNT_OVERALL',
  TRANSACTION_TYPE_COUNT = 'TRANSACTION_TYPE_COUNT',
  COVER_NOTES_TYPE_COUNT = 'COVER_NOTES_TYPE_COUNT',
  COVER_NOTES_MONTHLY_SELF_COUNT = 'COVER_NOTES_MONTHLY_SELF_COUNT',
  WIDGET_CLAIMS_MONTHLY_STATUS_OVERALL = 'WIDGET_CLAIMS_MONTHLY_STATUS_OVERALL',
  ENDORSEMENTS_TYPE_COUNT = 'ENDORSEMENTS_TYPE_COUNT',
  ASSESSMENTS_TYPE_COUNT = 'ASSESSMENTS_TYPE_COUNT',
  POLICIES_TYPE_COUNT = 'POLICIES_TYPE_COUNT',
  POLICIES_MONTHLY_OVERALL_COUNT = 'POLICIES_MONTHLY_OVERALL_COUNT',
  POLICIES_CLASS_OVERALL_BAR = 'POLICIES_CLASS_OVERALL_BAR',
  POLICIES_CLASS_MONTHLY_OVERALL_BAR = 'POLICIES_CLASS_MONTHLY_OVERALL_BAR',
  WIDGET_REINSURERS_COUNT_OVERALL = 'WIDGET_REINSURERS_COUNT_OVERALL',
  WIDGET_PARTNERS_COUNT_OVERALL = 'WIDGET_PARTNERS_COUNT_OVERALL',
  WIDGET_PARTNERS_TYPE_OVERALL = 'WIDGET_PARTNERS_TYPE_OVERALL',
  WIDGET_BRANCHES_ZONE_OVERALL = 'WIDGET_BRANCHES_ZONE_OVERALL',
  WIDGET_CONTROLS_COUNT_OVERALL = 'WIDGET_CONTROLS_COUNT_OVERALL',
  ENDPOINTS_COUNTS = 'ENDPOINTS_COUNTS',
  MAPPINGS_COUNTS = 'MAPPINGS_COUNTS',
  QUOTA_SYSTEM = 'QUOTA_SYSTEM',
  QUOTA_CONNECTED_APPS = 'QUOTA_CONNECTED_APPS',
  USER_QUOTA = 'USER_QUOTA',
  TEST_GROUP_BAR = 'TEST_GROUP_BAR', // This is for group bar chart test
}
